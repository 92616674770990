import { useState } from "react";
import DataTable from "react-data-table-component";
import NoDataFoundImg from "../nodatafound/NoDataFoundImg";

export default function DataTableComponents({ fetchData, columns, userdata, TotalRows, loading }) {
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [currentPage, setCurrentPage] = useState(1);

    const handlePageChange = (page) => {
        fetchData(page, rowsPerPage);
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = async (newRowsPerPage) => {
        if (!userdata.length) return;
        fetchData(1, newRowsPerPage);
        setRowsPerPage(newRowsPerPage);
        setCurrentPage(1);
    };

    return (
        <>
            {/* {userdata.length > 0 ? ( */}
            <DataTable
                pagination
                paginationServer
                columns={columns}
                data={userdata}
                progressPending={loading}
                paginationTotalRows={TotalRows}
                paginationDefaultPage={currentPage}
                paginationRowsPerPageOptions={[25, 50, 75, 100]}
                paginationPerPage={rowsPerPage}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleRowsPerPageChange}
                sortIcon={
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-up-short" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5" />
                    </svg>
                }
            />
            {/* ) : (
                <>
                    <NoDataFoundImg />
                </>
            )} */}
        </>
    );
}
