import React from 'react';
import styles from '../styles/kyc_aml_policy.module.css';
import { companyName, supportUrl } from '../const';
import Layout from '../components/Layout/Layout';
import { Link } from 'react-router-dom';

export default function KycAmlPolicy() {
    return (
        <Layout>
            <section>
                <div className='container'>
                    <div className='title_heading d-flex'>
                        {/* <p>Explore /</p> */}
                        <h4 className='ps-0'>KYC & AML Policy</h4>
                    </div>
                    <div className={styles.head_pare}>
                        <h3>KYC is an acronym for "Know Your Customer" whereas AML stands for "Anti-Money Laundering".</h3>
                    </div>
                    <div className={styles.body}>
                        <h3>INTRODUCTION</h3>
                        <p>We are dedicated to protecting our users and customers from fraudulent activities which might happen in the cryptocurrency and digital asset markets. Fraudulent methods are sometimes used even in crypto markets for fraud, scam or other illegal activities. To avoid this risk, it is advised to undergo KYC verification to ensure the security of all financial transactions. The KYC and AML process identifies and ascertains that our user is a law abiding citizen or organization.</p>
                        <p>The {companyName.toLowerCase()} service features a risk classification and detection system which can detect suspicious transactions and user activities. If any suspicious activity is detected in a transaction by our system, It will be referred for AML and KYC control.For security reasons, we shall never disclose the user and transaction specific criteria and various risk categorization parameters used by our system.. However, our risk classification and detection systems have proved very effective against various illegal activities including money laundering and scams.</p>
                        <p>The {companyName.toLowerCase()} service reserves the right to apply enhanced AML / KYC procedure for specific users, cryptocurrency transactions and wallet address holders. This procedure will be applied on the basis of {companyName.toLowerCase()} service’s internal policies. This is necessary to prevent the usage of our platform and services for unlawful activities including money-laundering.</p>
                        <p>It's important to understand that {companyName.toLowerCase()} service also reserves the right to allot the task of authentication of user to third-party service providers on behalf of the {companyName.toLowerCase()} service. During the processing of your information, the service provider shall be bound to fully respect our Privacy Policy statement concerning protection of sensitive personal information.</p>
                        <p>The {companyName.toLowerCase()} service shall never engage in any commercial contract with any person or organization suspected of being in any way involved in any illegal activity or where funds are sourced from suspected illegal activities (as defined by law).</p>

                        <h3>KYC AND AML PROCEDURE</h3>
                        <p>The enhanced due diligence and KYC criteria shall be applied to those who are depositing specific crypto assets that are considered dubious by our risk classification systems.</p>
                        <p>The user has to submit the proof of their identity to complete the KYC and AML procedure. The process involves :</p>
                        <p>Submission of a official valid government approved identity document such as National Identity Card, Passport, Driving License etc.</p>
                        <p>Supporting document as proof of "Source of Funds".</p>
                        <p>User selfie with the submitted identity document.</p>
                        <p>The transaction will be processed only after submission of required documents by user and successful completion of KYC and due diligences process at {companyName.toLowerCase()}. If the user refuses to undergo our AML / KYC procedure, the transaction shall be rejected and the crypto assets shall be returned to the original source subjected to the recovery of applicable fees. Furthermore, our service shall block the user account and blacklist the wallet address. The blocked user shall no longer be eligible to use any of the {companyName.toLowerCase()} services.</p>
                        <p>If the {companyName.toLowerCase()} service receives any false or forged document, misleading or incorrect contact or personal information from the user during the KYC process or otherwise, then the customer shall be considered in violation of our Terms of Use and the user access to the account shall be blocked. Our service has permission to process and transmit the data collected from the users to law enforcement authorities on any legitimate request.</p>

                        <h3>DISCLAIMER</h3>
                        <p>KYC verification and AML checks related third party services used by {companyName.toLowerCase()} are in compliance with regulatory guidelines such as GDPR, CCPA and SOC 2 type II.</p>

                        <h3>CONTACT</h3>
                        <p>Do you have any query regarding our KYC and AML procedures ? Feel free to contact us via <Link to={`${supportUrl}/`} target='_blank'>Support Center.</Link></p>
                    </div>
                </div>
            </section>
        </Layout>
    );
}
