import React from "react";
import Layout from "../components/Layout/Layout";
import { useLocation } from "react-router";
import style from "../styles/blog.module.css";
import { Link } from "react-router-dom";

export default function BlogDetails() {
    const location = useLocation();
    const { item } = location.state;
    return (
        <Layout>
            <section>
                <div className="container">
                    <div className="title_heading">
                        <p>
                            <Link to="/blog" className={style.blog_title}>
                                Blog /
                            </Link>
                        </p>
                        <h4>{item.NAME}</h4>
                    </div>
                    <div className="blog-inner">
                        <div className={style.inner_page}>
                            <img src={`https://exchange-admin.funexcoin.com/site/images/blogs/${item.BLOG_FILE}`} alt="" className="mb-3" />
                            <p>
                                <b>{item.SHORT_DESCRIPTION}</b>
                            </p>
                            <div dangerouslySetInnerHTML={{ __html: item.DESCRIPTION }}></div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}