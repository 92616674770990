import React from 'react';
import LogoImg from '../../assets/images/logo.svg';

export default function Logo() {
    return (
        <a href='/' className='logo'>
            <img src={LogoImg} alt='funex exchange' className='img-fluid' />
        </a>
    );
}
