import React, { useEffect, useState } from "react";
import styles from "../styles/profile.module.css";
import profileImg from "../assets/images/profile.svg";

import profileManuImg from "../assets/images/profile/profile.svg";
import kycImg from "../assets/images/profile/kyc.svg";
import paymentoptionImg from "../assets/images/profile/payment-option.svg";
import loginpasswordImg from "../assets/images/profile/login-password.svg";
import securityImg from "../assets/images/profile/security.svg";
import safetyImg from "../assets/images/profile/safety.svg";
import apimanagementImg from "../assets/images/profile/api_management.svg";
import loginhistoryImg from "../assets/images/profile/login_history.svg";
import useractivityImg from "../assets/images/profile/user-activity.svg";
import { Link, useParams } from "react-router-dom";

import PersonalInfo from "../components/profileInfo/PersonalInfo";
import KYCInfo from "../components/profileInfo/KYCInfo";
import BankAccountVerification from "../components/profileInfo/BankAccountVerification";
import LoginPassword from "../components/profileInfo/LoginPassword";
import Security from "../components/profileInfo/Security";
import SafetyPhrase from "../components/profileInfo/SafetyPhrase";
import APIManagement from "../components/profileInfo/APIManagement";
import LoginHistory from "../components/profileInfo/LoginHistory";
import Layout from "../components/Layout/Layout";
import UserActivity from "../components/profileInfo/UserActivity";
import { API_Path, supportUrl } from "../const";
import { GetTokenApi } from "../api/api-service";
import { toast } from "react-toastify";
import Loading from "../components/loader/Loading";
import NoDataFoundImg from "../components/nodatafound/NoDataFoundImg";
import NotFound from "./NotFound";

export default function Profile() {
    const { subPage } = useParams();
    const [useDetailsData, setUserDetailsData] = useState();
    const [loading, setLoading] = useState(false);
    const [apiCalled, setApiCalled] = useState(false);
    const [isOpen, setIsopen] = useState(false);
    useEffect(() => {
        getUserDetails();
    }, []);
    const getUserDetails = () => {
        const getCryptoCurrency = new Promise((resolve) => {
            resolve(GetTokenApi(API_Path.GetCurrentUserDetail));
        });
        getCryptoCurrency.then((res) => {
            if (res) {
                if (res.status === 200) {
                    setLoading(false);
                    setUserDetailsData(res.data);
                    setApiCalled(true);
                } else {
                    setLoading(false);
                    toast.error(res.statusText);
                }
            } else {
                toast.error("Something went wrong!");
            }
        });
    };

    const ToggleSidebar = () => {
        isOpen === true ? setIsopen(false) : setIsopen(true);
    };

    return (
        <>
            {subPage === "user-kyc-info" ||
            subPage === "user-kyc" ||
            subPage === "user-beneficiary" ||
            subPage === "change-password" ||
            subPage === "security" ||
            subPage === "safety-phrase" ||
            subPage === "api-management" ||
            subPage === "login-history" ||
            subPage === "user-activity" ? (
                <Layout>
                    {loading && <Loading />}
                    <section>
                        <div className={`${styles.profile_head} profile-head`}>
                            <h3>Profile</h3>
                            <div className="sidebar_icon">
                                <button className={`${styles.navbar_toggle} navbar-toggler`} type="button" onClick={ToggleSidebar}>
                                    <span className="toggler-icon">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className={`sidebar-overlay ${isOpen == true ? "active" : ""}`} onClick={ToggleSidebar}></div>

                        <div className="d-flex">
                            <div className={`left-side ${isOpen === true ? "active" : ""}`}>
                                <div className={styles.sidebar_bg}>
                                    <div className="close-sidebar btn btn-primary" onClick={ToggleSidebar}>
                                        X
                                    </div>

                                    <div className={styles.sidebar_details}>
                                        <div className={styles.user_body}>
                                            <div className={styles.sidebar_user_img}>
                                                <img src={profileImg} alt="" className="img-fluid" />
                                            </div>
                                            <div className={styles.sidebar_user_details}>
                                                <h4>{useDetailsData?.EMAIL_ID ?? ""}</h4>
                                                <span>
                                                    last login time : <bdi>{useDetailsData?.LAST_LOGIN} (UTC)</bdi>
                                                </span>
                                                <span>
                                                    IP address : <bdi>{useDetailsData?.LAST_LOGIN_IP}</bdi>
                                                </span>
                                                <button
                                                    className={`${
                                                        useDetailsData?.DocsStatus == 0 || useDetailsData?.DocsStatus === 1
                                                            ? styles.btn_warning
                                                            : useDetailsData?.DocsStatus == 2
                                                            ? styles.btn_success
                                                            : useDetailsData?.DocsStatus == 3
                                                            ? styles.btn_danger
                                                            : ""
                                                    }`}
                                                >
                                                    {useDetailsData?.DocsStatusName}
                                                </button>
                                            </div>
                                        </div>
                                        <div className={`${styles.account_settings} pt-4`} onClick={ToggleSidebar}>
                                            <div className={styles.nav}>
                                                <Link className={`${styles.navlink} ${subPage === "user-kyc-info" ? styles.active : ""}`} to={"/useraccount/profile/user-kyc-info"}>
                                                    <img src={profileManuImg} alt="" className="img-fluid" />
                                                    <span>Profile</span>
                                                </Link>
                                            </div>
                                            <div className={styles.nav}>
                                                <Link to="/useraccount/profile/user-kyc" className={`${styles.navlink} ${subPage === "user-kyc" ? styles.active : ""}`}>
                                                    <img src={kycImg} alt="" className="img-fluid" />
                                                    <span>KYC</span>
                                                </Link>
                                            </div>
                                            <div className={styles.nav}>
                                                <Link to="/useraccount/profile/user-beneficiary" className={`${styles.navlink} ${subPage === "user-beneficiary" ? styles.active : ""}`}>
                                                    <img src={paymentoptionImg} alt="" className="img-fluid" />
                                                    <span>Payment Options</span>
                                                </Link>
                                            </div>
                                            <div className={styles.nav}>
                                                <Link to="/useraccount/profile/change-password" className={`${styles.navlink} ${subPage === "change-password" ? styles.active : ""}`}>
                                                    <img src={loginpasswordImg} alt="" className="img-fluid" />
                                                    <span>Login Password</span>
                                                </Link>
                                            </div>
                                            <div className={styles.nav}>
                                                <Link to="/useraccount/profile/security" className={`${styles.navlink} ${subPage === "security" ? styles.active : ""}`}>
                                                    <img src={securityImg} alt="" className="img-fluid" />
                                                    <span>Security</span>
                                                </Link>
                                            </div>
                                            <div className={styles.nav}>
                                                <Link to="/useraccount/profile/safety-phrase" className={`${styles.navlink} ${subPage === "safety-phrase" ? styles.active : ""}`}>
                                                    <img src={safetyImg} alt="" className="img-fluid" />
                                                    <span>Safety Phrase</span>
                                                </Link>
                                            </div>
                                            <div className={styles.nav}>
                                                <Link to="/useraccount/profile/api-management" className={`${styles.navlink} ${subPage === "api-management" ? styles.active : ""}`}>
                                                    <img src={apimanagementImg} alt="" className="img-fluid" />
                                                    <span>API Management</span>
                                                </Link>
                                            </div>
                                            <div className={styles.nav}>
                                                <Link to="/useraccount/profile/login-history" className={`${styles.navlink} ${subPage === "login-history" ? styles.active : ""}`}>
                                                    <img src={loginhistoryImg} alt="" className="img-fluid" />
                                                    <span>Login History</span>
                                                </Link>
                                            </div>
                                            <div className={styles.nav}>
                                                <Link to="/useraccount/profile/user-activity" className={`${styles.navlink} ${subPage === "user-activity" ? styles.active : ""}`}>
                                                    <img src={useractivityImg} alt="" className="img-fluid" />
                                                    <span>User Activity</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="right-side">
                                <div className={`${styles.white_box} `}>
                                    <h5>Withdrawal Limit</h5>
                                    <ul className={styles.acc_level}>
                                        <li>
                                            <span className={`${styles.badge} ${useDetailsData?.DocsStatus !== 1 ? styles.active : ""}`}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                                    <path d="M20.5 6L9.5 17L4.5 12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </span>
                                            <h6>Tier-1</h6>
                                            <span>
                                                24h Withdrawal Limit: <b>0.05 BTC</b>
                                            </span>
                                        </li>
                                        <li>
                                            <span className={`${styles.badge} ${useDetailsData?.DocsStatus == 1 ? styles.active : ""}`}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                                    <path d="M20.5 6L9.5 17L4.5 12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </span>
                                            <h6>Tier-2</h6>
                                            <span>
                                                24h Withdrawal Limit: <b>3 BTC</b>{" "}
                                            </span>
                                        </li>
                                        <li>
                                            <span className={`${styles.badge} ${useDetailsData?.DocsStatus !== 1 && useDetailsData?.DocsStatus == 1 ? styles.active : ""}`}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                                    <path d="M20.5 6L9.5 17L4.5 12" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </span>
                                            <h6>Tier-3</h6>
                                            <span>
                                                24h Withdrawal Limit:{" "}
                                                <Link to={`${supportUrl}/`} target="_blank">
                                                    <b>Contact us</b>
                                                </Link>
                                            </span>
                                        </li>
                                    </ul>
                                    <div className="limit-level">
                                        <div className={styles.badge_dots}>
                                            <span className={useDetailsData?.DocsStatus !== 1 ? styles.active_dots : ""}></span>
                                            <span className={useDetailsData?.DocsStatus == 1 ? styles.active_dots : ""}></span>
                                            <span className={useDetailsData?.DocsStatus !== 1 && useDetailsData?.DocsStatus == 1 ? styles.active_dots : ""}></span>
                                        </div>
                                        <div className={`${styles.badge_progress} progress`}>
                                            <div
                                                className={`${styles.badge_progress_bar} progress-bar`}
                                                style={{
                                                    width:
                                                        useDetailsData?.DocsStatus != 1
                                                            ? "33.33%"
                                                            : useDetailsData?.DocsStatus == 1
                                                            ? "66.66%"
                                                            : useDetailsData?.DocsStatus != 1 && useDetailsData?.DocsStatus == 1 && "100%",
                                                }}
                                                role="progressbar"
                                                aria-valuenow="0"
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {subPage === "user-kyc-info" && <PersonalInfo apiCalled={apiCalled} />}
                                    {subPage === "user-kyc" && <KYCInfo apiCalled={apiCalled} />}
                                    {subPage === "user-beneficiary" && <BankAccountVerification />}
                                    {subPage === "change-password" && <LoginPassword />}
                                    {subPage === "security" && <Security data={useDetailsData} />}
                                    {subPage === "safety-phrase" && (
                                        <SafetyPhrase EmailSafetyCode={useDetailsData?.EmailSafetyCode} LoginSafetyCode={useDetailsData?.LoginSafetyCode} getProfileApi={getUserDetails} />
                                    )}
                                    {subPage === "api-management" && <APIManagement apiCalled={apiCalled} />}
                                    {subPage === "login-history" && <LoginHistory />}
                                    {subPage === "user-activity" && <UserActivity />}
                                </div>
                            </div>
                        </div>
                    </section>
                </Layout>
            ) : (
                <NotFound />
            )}
        </>
    );
}
