import React, { useEffect, useRef, useState } from "react";
import styles from "../../styles/profile.module.css";
import { API_Path, apiBaseUrlImg, baseUrl, validNumberRegex } from "../../const";
import { GetTokenApi, PostTokenApi } from "../../api/api-service";
import { toast } from "react-toastify";
import Loading from "../loader/Loading";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { allCountrys, errorContainer, formAttr } from "../common/CommonFun";
import "../../styles/custom_select.css";

const Security = (props) => {
    const selectRef = useRef(null);
    const [userAuthData, setUserAuthCode] = useState();
    const [loading, setLoading] = useState(false);
    const [selectedOption, setSelectedOption] = useState("Select country");
    const [isActive, setIsActive] = useState(false);
    const [showOTP, setShowOTP] = useState(false);
    const [googleAuthShow, setGoogleAuthShow] = useState(false);
    const [mobileAuthSHow, setMobileAuthShow] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [authErrorMsg, setAuthErrorMsg] = useState([]);
    const [value, setValue] = useState("");
    const [OTPValue, setOTPValue] = useState("");

    const [errorMsg, setErrorMsg] = useState({
        googleAuthErrorMsg: [],
        numberAuthErrorMsg: [],
        otpAuthErrorMsg: [],
    });

    const handleGoogleAuthShow = () => setGoogleAuthShow(!googleAuthShow);
    const handleMobileAuthShow = () => setMobileAuthShow(!mobileAuthSHow);

    useEffect(() => {
        setLoading(true);
        getUserAuthenticatorCode();
    }, []);

    useEffect(() => {
        document.addEventListener("click", handleDocumentClick);
        return () => {
            document.removeEventListener("click", handleDocumentClick);
        };
    }, []);

    const filteredCoinData = allCountrys.filter((item) => item.name.toLowerCase().includes(searchQuery.toLowerCase()) || item.number.toLowerCase().includes(searchQuery.toLowerCase()));

    const handleOptionClick = (value) => {
        setSelectedOption(
            <>
                <img src={apiBaseUrlImg + value.image} alt="" className="img_flag" /> +{value.number} | {value.name}
            </>
        );
        setIsActive(false);
    };

    const handleSelectClick = () => {
        setIsActive(!isActive);
    };

    const handleDocumentClick = (e) => {
        if (selectRef.current && !selectRef.current.contains(e.target)) setIsActive(false);
    };

    const getUserAuthenticatorCode = () => {
        const UserAuthCode = new Promise((resolve) => {
            resolve(GetTokenApi(API_Path.userAuthenticatorCode));
        });
        UserAuthCode.then((res) => {
            if (res) {
                setLoading(false);
                setUserAuthCode(res.data);
            } else {
                toast.error("Something went wrong!");
            }
        });
    };

    const setSubmitGoogleData = (formData, resetForm) => {
        setLoading(true);
        let data = {
            ...formData,
            ManualEntryKey: userAuthData?.ManualEntryKey,
            AuthenticatorKey: userAuthData?.AuthenticatorKey,
        };
        const UserAuthCode = new Promise((resolve) => {
            resolve(PostTokenApi(API_Path.userGoogleAuthCode, data));
        });
        UserAuthCode.then((res) => {
            if (res) {
                setLoading(false);
                if (res.data.Isvalid === true) {
                    toast.success(res.data.Successes[0]);
                    getUserAuthenticatorCode();
                    resetForm();
                } else {
                    setErrorMsg({ ...errorMsg, googleAuthErrorMsg: res.data.Errors });
                    toast.error(res.data.Errors[0]);
                }
            } else {
                toast.error("Something went wrong!");
            }
        });
    };

    const setSubmitMobileData = (formData, resetForm) => {
        let data = {
            MobileNo: "+" + formData.name + formData.MobileNo,
        };
        const UserAuthCode = new Promise((resolve) => {
            resolve(PostTokenApi(API_Path.userMobileAuthCode, data));
        });
        UserAuthCode.then((res) => {
            if (res) {
                setLoading(false);
                if (res.data.Isvalid === true) {
                    toast.success(res.data.Successes[0]);
                    setShowOTP(true);
                    resetForm();
                } else {
                    setErrorMsg({ ...errorMsg, numberAuthErrorMsg: res.data.Errors });
                    toast.error(res.data.Errors[0]);
                }
            }
        });
    };

    const setSubmitSendOTPData = (formData, resetForm) => {
        setLoading(true);
        let data = {
            MobileNo: "+" + formData.name + formData.MobileNo,
            OtpCode: formData.OtpCode,
        };
        const UserAuthCode = new Promise((resolve) => {
            resolve(PostTokenApi(API_Path.userMobileAuthOTPCode, data));
        });
        UserAuthCode.then((res) => {
            if (res) {
                if (res.data.Isvalid === true) {
                    toast.success(res.data.Successes[0]);
                    resetForm();
                } else {
                    setErrorMsg({ ...errorMsg, otpAuthErrorMsg: res.data.Errors });
                    toast.error(res.data.Errors[0]);
                }
                handleMobileAuthShow();
                setLoading(false);
            }
        });
    };

    const handleOnChange = (e) => {
        const newValue = e.target.value;
        if (newValue === "" || validNumberRegex.test(newValue)) setValue(newValue);
    };
    const handleOTPChnage = (e) => {
        const newValue = e.target.value;
        if (newValue === "" || validNumberRegex.test(newValue)) setOTPValue(newValue);
    };

    return (
        <React.Fragment>
            {loading && <Loading />}
            <div className={`${styles.personal_info_body}`}>
                <div className={`${styles.title} d-flex align-items-center mb-2`}>
                    <h6 className="mb-0 me-3">Google Authenticator</h6>
                    <span className={!userAuthData?.IsAuthenticator ? "red_color" : "green_color"}>{!userAuthData?.IsAuthenticator ? "OFF" : "ON"}</span>
                </div>
                <p>Want to set highest security for your account ? Please enable google authenticator.</p>
                {!googleAuthShow ? (
                    <div className={`${styles.personal_info_btn} p-0 mb-5`}>
                        <button type="submit" className={`${styles.button} primary_button`} onClick={handleGoogleAuthShow}>
                            {userAuthData?.IsAuthenticator ? "Change" : "Add"}
                        </button>
                    </div>
                ) : (
                    <div className={styles.auth_body}>
                        <h3>Verify Security Changes</h3>
                        <img src={userAuthData?.QrCodeSetupImageUrl ?? `${baseUrl} /skins/customcommon/images/qrcodenotfound.png`} alt="" className="img-fluid" />
                        <p>To secure your account, please complete the following verification.</p>
                        <p>
                            Disabling 2-FA shall put your account at greater risk of unauthorized access. You shall not be able to request withdrawal from your account for 24 hours, after 2-FA is
                            disabled.
                        </p>
                        <div>
                            <Formik
                                initialValues={{ Password: "", AuthenticatorCode: "" }}
                                validationSchema={Yup.object().shape({
                                    Password: Yup.string().required("Login password is required."),
                                    AuthenticatorCode: Yup.string().required("Authenticator code is required."),
                                })}
                                onSubmit={(values, { resetForm }) => {
                                    setSubmitGoogleData(values, resetForm);
                                }}
                            >
                                {(runform) => {
                                    const { touched, errors } = runform;
                                    return (
                                        <Form onSubmit={runform.handleSubmit}>
                                            <div className={`${styles.personalinfo_width} d-block`}>
                                                <div className={styles.personalinfocontent}>
                                                    <label>
                                                        Login password
                                                        <span className={styles.asterisk}>*</span>
                                                    </label>
                                                    <input
                                                        type="password"
                                                        placeholder="Login password"
                                                        name="Password"
                                                        className={`${styles.form_input} ${errors.Password && touched.Password ? "input-error" : ""} `}
                                                        {...formAttr(runform, "Password")}
                                                    />
                                                    {errorContainer(runform, "Password")}
                                                </div>
                                                <div className={styles.personalinfocontent}>
                                                    <label>
                                                        Authentication code
                                                        <span className={styles.asterisk}>*</span>
                                                    </label>
                                                    <input
                                                        type="text"
                                                        placeholder="Authentication code"
                                                        name="AuthenticatorCode"
                                                        className={`${styles.form_input} ${errors.AuthenticatorCode && touched.AuthenticatorCode ? "input-error" : ""} `}
                                                        {...formAttr(runform, "AuthenticatorCode")}
                                                    />
                                                    {errorContainer(runform, "AuthenticatorCode")}
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className={`${styles.personal_info_btn} text-center d-flex pt-3`}>
                                                    <button
                                                        type="button"
                                                        className={`${styles.button} ${styles.button_border}`}
                                                        onClick={() => {
                                                            handleGoogleAuthShow();
                                                            runform.resetForm();
                                                            setErrorMsg({ ...errorMsg, googleAuthErrorMsg: [] });
                                                        }}
                                                    >
                                                        Cancel
                                                    </button>
                                                    <button type="submit" className={`${styles.button} primary_button`} disabled={errorMsg.googleAuthErrorMsg?.length >= 1}>
                                                        {userAuthData.IsAuthenticator ? "Disable" : "Enable"}
                                                    </button>
                                                </div>
                                            </div>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </div>
                        {!userAuthData?.IsAuthenticator && (
                            <div className="mb-5">
                                <h6>Using 2-FA is highly recommended.</h6>
                                <p>
                                    Download & Install the "Google Authenticator" App for your Android, iOS or Windows device. Write and store this back-up key <b>{userAuthData?.AuthenticatorKey}</b>{" "}
                                    safely. You shall need it if you re-install "Google Authenticator" App.
                                </p>
                                <p>Scan the QR Code with "Google Authenticator" App.</p>
                                <p>Enter the authentication code generated by the app.</p>
                            </div>
                        )}
                    </div>
                )}
                <div className="mobile-auth">
                    <div className={`${styles.title} d-flex align-items-center mb-2`}>
                        <h6 className="mb-0 me-3">Mobile Authentication</h6>
                        <span className={!props.data?.IsMobileAuthentication ? "red_color" : "green_color"}>{!props.data?.IsMobileAuthentication ? "OFF" : "ON"}</span>
                    </div>
                    <p>You can use mobile otp for login in your account.</p>
                    {!mobileAuthSHow ? (
                        <div className="form-group">
                            <div className={`${styles.personal_info_btn} p-0 mb-5`}>
                                <button type="submit" className={`${styles.button} primary_button`} onClick={handleMobileAuthShow}>
                                    {props.data?.IsMobileAuthentication ? "Change" : "Add"}
                                </button>
                            </div>
                        </div>
                    ) : (
                        <div className={styles.auth_body}>
                            <div>
                                <Formik
                                    initialValues={{
                                        name: "",
                                        MobileNo: "",
                                        ...(showOTP ? { OtpCode: "" } : {}),
                                    }}
                                    validationSchema={Yup.object().shape({
                                        name: Yup.string().required("Country code is crequired."),
                                        MobileNo: Yup.string().required("Mobile number is required."),
                                        ...(showOTP && { OtpCode: Yup.string().required("OTP is required.") }),
                                    })}
                                    onSubmit={(values, { resetForm }) => {
                                        showOTP ? setSubmitSendOTPData(values, resetForm) : setSubmitMobileData(values, resetForm);
                                    }}
                                >
                                    {(runform) => {
                                        const { touched, errors } = runform;
                                        return (
                                            <Form onSubmit={runform.handleSubmit}>
                                                <div className={`${styles.personalinfo_width} d-block`}>
                                                    <div className={styles.personalinfocontent}>
                                                        <label>
                                                            Country Code<span className={styles.asterisk}>*</span>
                                                        </label>
                                                        <div className="select" ref={selectRef}>
                                                            <div
                                                                className={`select-styled ${isActive ? "active" : ""} ${errors.name && touched.name ? "input-error" : ""}`}
                                                                onClick={handleSelectClick}
                                                            >
                                                                {selectedOption}
                                                            </div>
                                                            {isActive && (
                                                                <div className={styles.country_option}>
                                                                    <div className={`${styles.serach_countryName} text-center`}>
                                                                        <input type="search" placeholder="search" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                                                                    </div>
                                                                    <ul className="select-options">
                                                                        {filteredCoinData.map((option, i) => (
                                                                            <li
                                                                                key={i}
                                                                                onClick={() => {
                                                                                    runform.setFieldValue("name", option.number);
                                                                                    handleOptionClick(option);
                                                                                }}
                                                                                name="name"
                                                                                className={selectedOption.name === option.name ? "is-selected" : ""}
                                                                            >
                                                                                <img src={apiBaseUrlImg + option.image} className="img_flag" alt="" />+{option.number} | {option.name}
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </div>
                                                            )}
                                                        </div>
                                                        {errorContainer(runform, "name")}
                                                    </div>
                                                    <div className={styles.personalinfocontent}>
                                                        <label>
                                                            Mobile No<span className={styles.asterisk}>*</span>
                                                        </label>
                                                        <div>
                                                            <input
                                                                type="tel"
                                                                placeholder="Enter mobile no."
                                                                name="MobileNo"
                                                                className={`${styles.form_input} ${errors.MobileNo && touched.MobileNo ? "input-error" : ""} `}
                                                                value={value}
                                                                onChange={(e) => {
                                                                    handleOnChange(e);
                                                                    runform.setFieldValue("MobileNo", e.target.value);
                                                                }}
                                                                // {...formAttr(runform, "MobileNo")}
                                                            />
                                                        </div>
                                                        {errorContainer(runform, "MobileNo")}
                                                    </div>
                                                    {showOTP && (
                                                        <div className={styles.personalinfocontent}>
                                                            <label>
                                                                Enter OTP<span className={styles.asterisk}>*</span>
                                                            </label>
                                                            <div className={styles.auth_content}>
                                                                <input
                                                                    type="tel"
                                                                    placeholder="OTP"
                                                                    name="OtpCode"
                                                                    className={`${styles.form_input} ${errors.OtpCode && touched.OtpCode ? "input-error" : ""} `}
                                                                    value={OTPValue}
                                                                    onChange={(e) => {
                                                                        handleOTPChnage(e);
                                                                        runform.setFieldValue("OtpCode", e.target.value);
                                                                    }}
                                                                    // {...formAttr(runform, "OtpCode")}
                                                                />
                                                                <button
                                                                    type="submit"
                                                                    onClick={() => {
                                                                        setSubmitSendOTPData(runform.initialValues, runform.resetForm);
                                                                    }}
                                                                    disabled={loading}
                                                                >
                                                                    Re-send OTP
                                                                </button>
                                                            </div>
                                                            {errorContainer(runform, "OtpCode")}
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="form-group">
                                                    <div className={`${styles.personal_info_btn} text-center d-flex pt-3`}>
                                                        <button
                                                            type="button"
                                                            className={`${styles.button} ${styles.button_border}`}
                                                            onClick={() => {
                                                                runform.resetForm();
                                                                setValue("");
                                                                setSelectedOption("Select country");
                                                                handleMobileAuthShow();
                                                            }}
                                                        >
                                                            Cancel
                                                        </button>
                                                        <button
                                                            type="submit"
                                                            className={`${styles.button} primary_button`}
                                                            disabled={showOTP ? errorMsg.otpAuthErrorMsg?.length >= 1 : errorMsg.numberAuthErrorMsg.length >= 1}
                                                        >
                                                            {showOTP ? (props.data.IsMobileAuthentication ? "Disable" : "Enable") : "Send OTP"}
                                                        </button>
                                                    </div>
                                                </div>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
};
export default Security;
