import React from 'react';
import ProfessionalConnectivityImg from '../assets/images/ProfessionalConnectivity.png';
import styles from '../styles/professional_connectivity.module.css';
import { companyName } from '../const';
import CoLocationServices from '../assets/images/professional_img1.svg';
import VPSUtility from '../assets/images/professional_img2.svg';
import CrossConnectivity from '../assets/images/professional_img3.svg';
import Layout from '../components/Layout/Layout';

export default function ProfessionalConnectivity() {
    return (
        <Layout>
            <section>
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-sm-12 col-lg-6 col-md-6'>
                            <div className={styles.section_left}>
                                <p>Professional Connectivity</p>
                                <h4>INDUSTRY LEADING CO-LOCATION, VPS AND CROSS-CONNECTIVITY SERVICES <bdi>(Launching Soon)</bdi></h4>
                                <p>{companyName} aims to provide secure, uninterrupted, need-based connectivity to its major Institutional, Prop-Trading Group and Hedge Funds Clients.</p>
                                <p>We are collaborating with other reputed third-party companies to provide our clients with the undermentioned facilities alongwith 24X7 support services :</p>
                            </div>
                        </div>
                        <div className={`${styles.pre_img} col-sm-12 col-lg-6 col-md-6`}>
                            <img src={ProfessionalConnectivityImg} className='img-fluid' alt=""/>
                        </div>
                    </div>
                    <div className={styles.section}>
                        <div className={`${styles.section_flex} row p-0`} >
                            <div className='col-sm-6 col-lg-4 col-md-6'>
                                <div className={styles.section_blog}>
                                    <img src={CoLocationServices} className='img-fluid' alt=''/>
                                    <h4>Dedicated Co-Location <br /> Services</h4>
                                    <p className='mb-0'>{companyName} aims to facilitate its clients with the fastest trading abilities in the market via its co-location services. Approved Institutional Clients can co-locate their servers in our highly secured data centers.</p>
                                </div>
                            </div>
                            <div className='col-sm-6 col-lg-4 col-md-6'>
                                <div className={styles.section_blog}>
                                    <img src={VPSUtility} className='img-fluid' alt=''/>
                                    <h4>VPS Utility</h4>
                                    <p className='mb-0'>{companyName} serves you best with Virtual Private Server (VPS) connectivity within the co-location space. VPS is well-known for imparting the customized combination of CPUs, SSD Storage and RAM for giving you the best-in-class connectivity experience.</p>
                                </div>
                            </div>
                            <div className='col-sm-6 col-lg-4 col-md-6'>
                                <div className={styles.section_blog}>
                                    <img src={CrossConnectivity} className='img-fluid' alt=''/>
                                    <h4>Cross-Connectivity</h4>
                                    <p>Take advantage of tailor-made FIX Feed and other multiple connectivity options making our platform accessible from various POPs across the globe including LD4, ZH4 and NY4.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}
