import React, { useEffect, useState } from "react";
import styles from "../styles/market.module.css";
import Table from "react-bootstrap/Table";
import Layout from "../components/Layout/Layout";
import io from "socket.io-client";
import Loading from "../components/loader/Loading";
import NoDataFoundImg from "../components/nodatafound/NoDataFoundImg";
import { socketUrl } from "../const";

// Global variables
let socket = "";

export default function MarketStatistics() {
    const [pairsList, setPairsList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");

    let initialized = false;
    useEffect(() => {
        if (!initialized) {
            initialized = true;
            socketConnect(true);
            sendSocketService();
            setLoading(true);
        }
    }, []);

    const socketConnect = (value) => {
        if (value) {
            //if (socket != "" && socket != null) io.disconnect();
            socket = io.connect(socketUrl);
            setTimeout(() => {
                if (!socket.connected) {
                    console.log("some statastic error");
                }
            }, 5000);
        } else {
            if (socket !== null) {
                socket.disconnect();
                console.log("socketStatasticDisConnect");
            }
        }
    };

    const sendSocketService = () => {
        socketReceiveMarkets();
        setTimeout(() => {
            socketSendMarket();
        }, 2000);
    };

    const socketReceiveMarkets = () => {
        socket.on("ReceiveMarket", (data) => {
            try {
                var markets = [];
                if (data && data.length > 0) {
                    markets = data.map((marketItem) => ({
                        PairSymbol: marketItem.PairName,
                        ShowPairSymbol: marketItem.First + "/" + marketItem.Last,
                        // CoinSymbol: marketItem.First,
                        // CurrencySymbol: marketItem.Last,
                        LastPrice: marketItem.Price,
                        Change: marketItem.TwentyHRPriceChangePrice,
                        ChangePercent: marketItem.TwentyHRPriceChangePer,
                        Volume: parseFloat(marketItem.TwentyHRVol),
                        High: marketItem.TwentHRHigh,
                        Low: marketItem.TwentHRLow,
                        // PercentClass: parseFloat(marketItem.TwentyHRPriceChangePrice) >= 0 ? "green-color" : "red-color",
                        // Amount: 0,
                        // IsFavourite: false,
                        // SocketPair: marketItem.PairName,
                        // CoinImage: marketItem.coinImage,
                        // currentPriceClass: marketItem.CurrentPriceChangeClass,
                        // agoTFhourprice: marketItem.agoTFhourprice,
                        // agoSDhourprice: marketItem.agoSDhourprice,
                        // agoTDhourprice: marketItem.agoTDhourprice,
                        // agoSDchangeprice: marketItem.agoSDchangeprice,
                        // agoTDchangeprice: marketItem.agoTDchangeprice,
                        // agoSDchangeper: marketItem.agoSDchangeper,
                        // agoTDchangeper: marketItem.agoTDchangeper,
                        // priceDecimal: marketItem.PRICE_PRECISION,
                        // SDchangeClass: parseFloat(marketItem.agoSDchangeprice) >= 0 ? "green-color" : "red-color",
                        // TDchangeClass: parseFloat(marketItem.agoTDchangeprice) >= 0 ? "green-color" : "red-color",
                    }));
                    setPairsList(markets);
                    setLoading(false);
                }
            } catch (error) {}
        });
    };

    const socketSendMarket = () => {
        socket.emit("SendMarket", {});
    };

    const filteredLoginHistory = pairsList?.filter((item) => item.PairSymbol.toLowerCase().includes(searchQuery.toLowerCase()));

    return (
        <Layout>
            {loading && <Loading />}
            <section>
                <div className="container">
                    <div className="title_heading d-flex pb-0">
                        {/* <p>Home /</p> */}
                        <h4 className="ps-0">Market Statistics</h4>
                    </div>
                </div>
                <div className="common_bg_style pt-4 pb-0">
                    <div className="container">
                        <div className={`${styles.symbol_search_box} search_Box position-relative pb-3 ms-auto`}>
                            <div className={styles.form_group}>
                                <input type="text" name="CoinSearch" className="form-control" placeholder="Search" onChange={(e) => setSearchQuery(e.target.value)} />
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                    <path d="M15.7508 15.7498L12.4883 12.4873" stroke="#404040" strokeLinecap="round" strokeLinejoin="round" />
                                    <path
                                        d="M8.25 14.25C11.5637 14.25 14.25 11.5637 14.25 8.25C14.25 4.93629 11.5637 2.25 8.25 2.25C4.93629 2.25 2.25 4.93629 2.25 8.25C2.25 11.5637 4.93629 14.25 8.25 14.25Z"
                                        stroke="#404040"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </div>
                        </div>
                        <div className={styles.trading_table_body}>
                            <Table striped bordered hover className="mb-0">
                                <thead>
                                    <tr>
                                        <th>Pair</th>
                                        <th>Last Trade</th>
                                        <th>24H Change</th>
                                        <th>24H % Change</th>
                                        <th>24H Vol</th>
                                    </tr>
                                </thead>
                                {filteredLoginHistory.length > 0 ? (
                                    <tbody>
                                        {filteredLoginHistory.map((item, i) => (
                                            <tr key={i}>
                                                <td>{item.ShowPairSymbol}</td>
                                                <td>{item.LastPrice}</td>
                                                <td className={parseFloat(item.Change) >= 0 ? "green_color" : "red_color"}>{item.Change}</td>
                                                <td className={`${parseFloat(item.ChangePercent) >= 0 ? "green_color" : "red_color"}`}>
                                                    {parseFloat(item.ChangePercent) >= 0 ? (
                                                        <span className="me-1">▲</span>
                                                    ) : (
                                                        <span span className="me-1">
                                                            ▼
                                                        </span>
                                                    )}
                                                    {item.ChangePercent}
                                                </td>
                                                <td>{item.Volume}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                ) : (
                                    <NoDataFoundImg  />
                                )}
                            </Table>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}
