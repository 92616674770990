import React from "react";
import styles from "../styles/apitermsofservice.module.css";
import { baseName } from "../const";
import Layout from "../components/Layout/Layout";

export default function RiskdisClosureStatement() {
    return (
        <Layout>
            <section className={styles.section}>
                <div className="container">
                    <div className={styles.section_body}>
                        <div className="title_heading d-flex">
                            {/* <p>Explore /</p> */}
                            <h4 className="ps-0">Risk Disclosure Statement</h4>
                        </div>
                        <div className={styles.section_content}>
                            <p>
                                Before joining {baseName.toLowerCase()} to engage in any form of trading activity, you must understand the risk associated with the services offered by{" "}
                                {baseName.toLowerCase()}. You are advised to read and understand major potential risks related to your digital token transactions or ones which might be encountered
                                during the usage of our Services. However, you agree that there is no such intention from our side to prioritize the risks or present them in an order in which they are
                                given below. We advise you to read about all the risks carefully and check whether such risks are applicable to any of your transactions on our platform.
                            </p>
                            <p>You should seek expert legal advice in case you are not able to understand any of the risks associated with the use of our services.</p>
                            <div className={styles.pere_content_box}>
                                <h2>1. BUSINESS RISKS OF TRADING IN CRYPTOCURRENCIES AND DIGITAL ASSETS</h2>
                                <p>
                                    You agree and understand that you are solely responsible for the risk and accompanying loss arising out of use of our Services by you. During the exchange of a
                                    digital token pair, you might experience considerable or complete loss. Hence, you should take complete precaution and safety to figure out whether a particular
                                    trade on our exchange is profitable for you or not, which primarily depends upon your trading objectives and financial situation. You must also stay aware of the
                                    following things :
                                </p>
                                <p>
                                    During fund transfer “from” and “to” your account, it is highly possible that you may suffer a total loss of funds. The price at which digital tokens are traded may
                                    vary greatly over short time intervals. Hence, there is a possibility that the token price used as a reference by you may go against you due to multiple
                                    market-linked factors, including modification in digital token properties, cyber attacks, suspension of trading in a token, the discovery of unlawful behavior and
                                    market manipulation, etc.
                                </p>
                                <p>
                                    In certain market conditions, it might be impossible for you to Buy/Sell or trade a particular digital token on our exchange due to insufficient market liquidity.{" "}
                                    {baseName} as a platform never assures and can not be held responsible for the liquidity or availability of the digital tokens for trading.
                                </p>
                                <p>
                                    {baseName.toLowerCase()} reserve its right over the suspension of trading activities for a particular digital token on its platform. In such scenario, you would not
                                    be permitted to withdraw these tokens. Once {baseName.toLowerCase()} revokes the suspension or trading ban from the specific digital token, you shall be allowed to
                                    start trading. You can face irreversible financial loss or loss in token value in your {baseName.toLowerCase()} account due to such suspension activities which are
                                    primarily directed at protecting the users and our platform.
                                </p>
                                <p>
                                    The description given here cannot explain or disclose all the business risks associated with the trading of digital tokens on our exchange. Hence, you, as a user,
                                    would be solely responsible for understanding or acceptance of any or all kinds of risks that might arise due to your transactions or trading activities on our
                                    platform.
                                </p>
                                <p>
                                    You also agree that you will be ready to accept and support any losses in your {baseName.toLowerCase()} account due to the transaction or trading activities related
                                    to digital tokens made by you.
                                </p>
                            </div>

                            <div className={styles.pere_content_box}>
                                <h2>2. RISKS RELATED TO INTERNET-BASED TRADING</h2>
                                <p>
                                    You accede that the use of internet-enabled trading activities carries certain risks, including software, hardware, and internet connection problems. Hence, you
                                    accept and understand that {baseName.toLowerCase()}, in any manner, will not be responsible for any transaction delays, interruptions or communication errors during
                                    your exchange activities on our platform, regardless of the cause.
                                </p>
                                <p>
                                    If any transaction/order/trade or transfer on our platform is based on a manifest error (regardless of whether such transaction has benefitted you or any other
                                    user) from our end, {baseName.toLowerCase()} may, at its sole discretion, act in good faith and reasonably for:
                                </p>
                                <p>Correcting, Cancellation or Deleting of such order, transaction or transfer;</p>
                                <p>Cancel and Delete the transaction as if it has never transpired.</p>
                            </div>
                            <div className={styles.pere_content_box}>
                                <h2>3. RISKS ASSOCIATED WITH ATTACKS ON THE {baseName.toUpperCase()} SERVICES OR ITS ACCOUNTS</h2>
                                <p>
                                    The funds, accounts or services offered to you by {baseName.toLowerCase()} might be subject to external data attacks, which might affect the security, integrity or
                                    operations of your accounts. You acknowledge that {baseName.toLowerCase()}, in any case, would not be responsible for resulting losses arising out of such external
                                    data attacks.
                                </p>
                            </div>
                            <div className={styles.pere_content_box}>
                                <h2>4. RISKS SPECIFIC TO CRYPTOCURRENCIES & TOKENS AS AN ASSET CLASS</h2>
                                <p>
                                    Any software network, protocols, systems or other technologies that are used to govern, monitor, transfer, transmit, delete, use or exchange any digital token on
                                    our platform might be susceptible to attack on its security, integrity or mode of operation, including attacks that uses sufficient computing power to disrupt the
                                    normal functioning of a blockchain or its associated underlying technology.
                                </p>
                                <p>
                                    The change in features, functionality, mode of operation, usage or any other properties of a digital token might lead to the loss, deletion, acceleration or
                                    reduction in the negotiated price of it. The negotiated price and functioning of a digital token might also get affected due to the external attacks and market
                                    constraints.
                                </p>
                                <p>
                                    {baseName} might not support any of the core digital token technologies or side chains that are derived based on a coin fork or enhancement or as a derivative of an
                                    already existing digital token, even if such token is already supported by {baseName.toLowerCase()} for trading. The digital token sent to a wrong wallet address by
                                    you can be lost and may never be recovered (considering you have entered a wrong wallet address or entered an address that contains errors).
                                </p>
                            </div>
                            <div className={styles.pere_content_box}>
                                <h2>5. COUNTERPARTY RISK</h2>
                                <p>
                                    {baseName}, in any manner, is not responsible for the security, legality, quality or any other aspect of digital tokens that you trade on our platform. We are not
                                    in a position to guarantee the authenticity of the third-party buyer or seller with whom you carry out a token transaction via our exchange. Furthermore, we do not
                                    provide any assurance to indemnify you for any kind of loss suffered as a result of counterparty risk.
                                </p>
                                <b>You hereby confirm and acknowledge that you have :</b>
                                <p>
                                    The necessary experience, knowledge and information base to cross-examine the benefits as well as the risks associated with any of the digital token or any related
                                    business ;
                                </p>
                                <p>
                                    The required technical proficiency to review or examine the integrity, security or mode of operation of any digital token that you choose to trade, purchase or
                                    redeem via our platform.
                                </p>
                                <p>
                                    You also accept and acknowledge that you clearly understand the risk associated with the exchange of digital tokens using our Services and are also ready to take
                                    the responsibility of performing an independent risk analysis on your own before trading digital tokens via our platform. You must also do not put yourself in the
                                    position of trade until you have a sufficient amount of financial resources, experience and technical expertise to handle all kinds of losses related to digital
                                    tokens.
                                </p>
                                <p>
                                    Our decision to engage in the transfer, trade, store or negotiation related to a particular digital token does not mean that {baseName.toLowerCase()} approves or
                                    disapproves the specific token, or its integrity, security, mode of operation or core technology. {baseName.toLowerCase()}, in any case, does not make any
                                    representation or warranties regarding the digital tokens that are supported by the platform for trading. All kinds of risks or legal issues related to the trading
                                    of digital tokens apply, despite {baseName.toLowerCase()} decision to provide services for such tokens. All risks associated with the trading of such digital tokens
                                    shall be entirely borne by you.
                                </p>
                            </div>
                            <div className={styles.pere_content_box}>
                                <h2>6. REGULATORY RISKS</h2>
                                <p>
                                    Global regulatory environment for Cryptocurrency and Digital Assets is still evolving and is highly uncertain. Predicting the legal status of digital currencies in
                                    many countries is very difficult today due to the lack of a proper regulatory framework around them. It is impossible to predict how the government or legal
                                    authorities across various jurisdictions are going to regulate digital tokens. Similarly, predicting the changes or modifications in already existing law or
                                    regulations related to digital currencies is also difficult.
                                </p>
                                <p>
                                    {baseName} reserves its complete right over prohibiting the user access to our platform for users from certain regions, basis the local government renders
                                    cryptocurrencies or specific digital assets as unlawful or, from a commercial point of view, undesirable. The same guidelines have also been given under our Terms
                                    of Use Policy, which clearly mentions that you will not use our Services or trade digital tokens via our platform if the same has been prohibited by the law of your
                                    country.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}
