export const decimalScaleCount = (num) => {
    const numStr = String(num);
    if (numStr.includes('.')) {
        return numStr.split('.')[1].length;
    }
    return 0;
};

export const operation = (a, sign, b) => {
    if (sign == "-") {
        return a - b; // minus
    } else {
        return a + b; // default plus
    }
};

export const calc = (firstInput, sign, secondInput) => {
    // Count the decimal places for each input
    const firstCount = decimalScaleCount(firstInput);
    const secondCount = decimalScaleCount(secondInput);
    const maxCount = (firstCount >= secondCount) ? firstCount : secondCount;

    if (maxCount !== 0) {
        const maxPow = Math.pow(10, maxCount);  // Calculate power of 10 based on max decimal places
        // Convert to integers to avoid floating-point precision issues
        const firstInputInt = Math[firstInput < 0 ? 'floor' : 'ceil'](firstInput * maxPow);
        const secondInputInt = Math[secondInput < 0 ? 'floor' : 'ceil'](secondInput * maxPow);

        return operation(firstInputInt, sign, secondInputInt) / maxPow;
    } else {
        // Direct operation if no decimals
        return operation(firstInput, sign, secondInput);
    }
};

export const sum = (firstInput, secondInput) => {
    return calc(firstInput, '+', secondInput);
};

// Function to calculate the difference between two numbers
export const minus = (firstInput, secondInput) => {
    return calc(firstInput, '-', secondInput);
};