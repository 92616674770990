import React from 'react';
import styles from '../../styles/register.module.css';
import { useLocation } from 'react-router';
import { baseName, companyName } from '../../const';
import SideImg from '../../assets/images/verification.png';
import Logo from './Logo';
import { Link } from 'react-router-dom';

export default function VerificationMailModal() {
    const location = useLocation();

    return (
        <React.Fragment>
            <div className="container-fluid">
                <div className='row'>
                    <div className={`${styles.col_width_left} ${styles.col_width_flex}`}>
                        <div className={styles.main_box}>
                            <div className={styles.lr_logo}>
                                <Logo />
                            </div>
                            <div className="mb-4">
                                <h3>Check your inbox for {baseName.toLowerCase()} <br /> verification e-mail</h3>
                            </div>

                            <div className={styles.verify_mail_body}>
                                <p>Thank you for signing up with {companyName.toLowerCase()}.</p>
                                <p>We have sent a verification e-mail to <bdi className='fw-semibold'>{location?.state?.data ?? ""}</bdi></p>
                                <p>Don’t see it? Check your spam and other folders.</p>
                                <p>Wrong e-mail address? <Link to='/register'> Restart Your Registration Process.</Link> </p>
                                <p><b>Important: </b>The verification link provided in the mail is valid for 10 minutes.</p>
                                <p>Did not receive e-mail or need to resend verification link ? <Link  to='/resend-email-verify'>Click Here</Link> to resend verification</p>
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.col_width_right} p-0 d-lg-block d-none`}>
                        <div className={styles.side_img}>
                            <img src={SideImg} alt="" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
