import React from 'react';
import Header from '../../pages/common/Header';
import Footer from '../../pages/common/Footer';

export default function Layout(props) {

    return (
        <React.Fragment>
            <div>
                <Header />
                {props.children}
                <Footer />
            </div>
        </React.Fragment>
    );
}
