import React, {  useState } from "react";
import styles from "../../styles/footer.module.css";
import Logo from "./Logo";
import { Link } from "react-router-dom";
import { currentYear } from "../../components/common/CommonFun";
import useFetch from "../../components/customHook/useFetch";
import { toast } from "react-toastify";
import { GetApi, PostApi } from "../../api/api-service";
import { API_Path } from "../../const";

export default function Footer() {
    const [FirstCoin, BaseCoin] = useFetch();
    const [emailId, setEmailId] = useState("");
    const [CountryName, setCountryName] = useState("");
    const [errorMsg , setErrorMsg] = useState([]);

    const getCountryCode = () => {
        const GetCountryCodeData = new Promise((resolve) => {
            resolve(GetApi(API_Path.getcountrycodeIp));
        });
        GetCountryCodeData.then((res) => {
            const data = res.data;
            setCountryName(data.country_name);
        });
    };

    const validateEmail = (email) => {
        return email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    };

    const setSubmitData = () => {
        if (emailId.length === 0) {
            toast.error("Email address is required.");
        } else if (!validateEmail(emailId)) {
            toast.error("Email address is invalid.");
        } else {
            getCountryCode();
            let data = {
                EmailId: emailId,
                CountryName: CountryName || "India",
            };
            const getCryptoCurrency = new Promise((resolve) => {
                resolve(PostApi(API_Path.addSubscriber, data));
            });

            getCryptoCurrency.then((res) => {
                if (res) {
                    if (res.data.Isvalid === true) {
                        toast.success(res.data.Successes[0]);
                        setEmailId("");
                    } else {
                        setErrorMsg(res.data.Errors[0]);
                        toast.error(res.data.Errors[0]);
                    }
                }
            });
        }
    };

    return (
        <div>
            <footer className={styles.footer}>
                <div className="container">
                    <div className={`${styles.flex_section}`}>
                        <div className={styles.colmd3}>
                            <div className={styles.links}>
                                <h6 className="">FEATURES</h6>
                                <ul>
                                    <li>
                                        <Link to={`/trading/advance/${FirstCoin}_${BaseCoin}`} target="_blank">Trading</Link>
                                    </li>
                                    <li>
                                        <Link to="/security">Security</Link>
                                    </li>
                                    <li>
                                        <Link to="/professional-connectivity">Professional Connectivity</Link>
                                    </li>
                                    <li>
                                        <a href={`${process.env.REACT_APP_API_BASE_URL}`} target="_blank" rel="noreferrer">
                                            API Documentation
                                        </a>
                                    </li>
                                    <li>
                                        <Link to="/orderbook">Full Order Book</Link>
                                    </li>
                                    <li>
                                        <Link to="/market-statistics">Market Statistics</Link>
                                    </li>
                                    <li>
                                        <Link to="/coin-status">System Status</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className={styles.colmd3}>
                            <div className={styles.links}>
                                <h6>EXPLORE</h6>
                                <ul>
                                    <li>
                                        <Link to="/trading-rules">Trading Rules</Link>
                                    </li>
                                    <li>
                                        <Link to="/fees">Our Fees</Link>
                                    </li>
                                    <li>
                                        <a href={`${process.env.REACT_APP_BASE_SUPPORT_URL}/news`} target="_blank" rel="noreferrer">
                                            Announcements
                                        </a>
                                    </li>
                                    <li>
                                        <Link to="/law-enforcement-policy">Law Enforcement Request Policy</Link>
                                    </li>
                                    <li>
                                        {/* <Link to="/trademark-notices">Trademark Notices</Link> */}
                                        <a href="https://funexcoin.com/branding" target="_blank">
                                            Trademark Notices
                                        </a>
                                    </li>
                                    <li>
                                        <Link to="/kyc-aml-policy">KYC & AML Policy</Link>
                                    </li>
                                    <li>
                                        <Link to="/info">Info</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className={styles.colmd3}>
                            <div className={styles.links}>
                                <h6>SUPPORT</h6>
                                <ul>
                                    <li>
                                        <Link to="/about-us">About Us</Link>
                                    </li>
                                    <li>
                                        <a href={`${process.env.REACT_APP_BASE_SUPPORT_URL}/support`} target="_blank" rel="noreferrer">
                                            Knowledge Base
                                        </a>
                                    </li>
                                    <li>
                                        <a href={`${process.env.REACT_APP_BASE_SUPPORT_URL}/sendtickets`} target="_blank" rel="noreferrer">
                                            Contact Us
                                        </a>
                                    </li>
                                    <li>
                                        <Link to="/blog">Blogs</Link>
                                    </li>
                                    <li>
                                        <Link to="/download">Downloads</Link>
                                    </li>
                                    <li>
                                        <Link to="/careers">Careers</Link>
                                    </li>
                                    <li>
                                        <Link to="/community">Our Community</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className={styles.colmd3}>
                            <div className={styles.links}>
                                <h6 className="border-top-0">LEGAL</h6>
                                <ul>
                                    <li>
                                        <Link to="/terms-of-service">Terms of Service</Link>
                                    </li>
                                    <li>
                                        <Link to="/api-terms-of-service">API Terms of Service</Link>
                                    </li>
                                    <li>
                                        <Link to="/risk-disclosure-statement">Risk Disclosure Statement</Link>
                                    </li>
                                    <li>
                                        <Link to="/privacy-policy">Privacy Policy</Link>
                                    </li>
                                    <li>
                                        <Link to="/cookies-policy">Cookies Policy</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className={`${styles.colmd3} ${styles.social_logo}`}>
                            <div className={styles.content}>
                                <Logo />
                                <div className={styles.get_start}>
                                    <h5>get started</h5>
                                    <form>
                                        <div className={styles.form_group}>
                                            <input
                                                type="email"
                                                className="form-control"
                                                placeholder="Enter email address"
                                                value={emailId}
                                                onChange={(e) => setEmailId(e.target.value)} // Use onChange to update the state
                                            />
                                            <button className={styles.arw_box} onClick={setSubmitData} disabled={errorMsg?.length >= 1}>
                                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M4.58301 11H17.4163" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path d="M11 4.58325L17.4167 10.9999L11 17.4166" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                <ul className={styles.social_icons}>
                                    <li>
                                        <a href="https://t.me/Funex_official" target="_blank" rel="noreferrer">
                                            <svg
                                                className="svg-inline--fa fa-telegram fa-w-16"
                                                aria-hidden="true"
                                                data-prefix="fab"
                                                data-icon="telegram"
                                                role="img"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 496 512"
                                                data-fa-i2svg=""
                                            >
                                                <path
                                                    fill="currentColor"
                                                    d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm121.8 169.9l-40.7 191.8c-3 13.6-11.1 16.9-22.4 10.5l-62-45.7-29.9 28.8c-3.3 3.3-6.1 6.1-12.5 6.1l4.4-63.1 114.9-103.8c5-4.4-1.1-6.9-7.7-2.5l-142 89.4-61.2-19.1c-13.3-4.2-13.6-13.3 2.8-19.7l239.1-92.2c11.1-4 20.8 2.7 17.2 19.5z"
                                                ></path>
                                            </svg>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.linkedin.com/company/funex-club" target="_blank" rel="noreferrer">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                <path
                                                    fill="currentColor"
                                                    d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z"
                                                />
                                            </svg>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://twitter.com/Funexclub" target="_blank" rel="noreferrer">
                                            <svg
                                                className="svg-inline--fa fa-twitter fa-w-16"
                                                aria-hidden="true"
                                                data-prefix="fab"
                                                data-icon="twitter"
                                                role="img"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 512 512"
                                                data-fa-i2svg=""
                                            >
                                                <path
                                                    fill="currentColor"
                                                    d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                                                ></path>
                                            </svg>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.facebook.com/officialfunexcoin/" target="_blank" rel="noreferrer">
                                            <svg
                                                className="svg-inline--fa fa-facebook-f fa-w-9"
                                                aria-hidden="true"
                                                data-prefix="fab"
                                                data-icon="facebook-f"
                                                role="img"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 264 512"
                                                data-fa-i2svg=""
                                            >
                                                <path
                                                    fill="currentColor"
                                                    d="M76.7 512V283H0v-91h76.7v-71.7C76.7 42.4 124.3 0 193.8 0c33.3 0 61.9 2.5 70.2 3.6V85h-48.2c-37.8 0-45.1 18-45.1 44.3V192H256l-11.7 91h-73.6v229"
                                                ></path>
                                            </svg>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.reddit.com/user/FunexCoin/" target="_blank" rel="noreferrer">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                <path
                                                    fill="currentColor"
                                                    d="M373 138.6c-25.2 0-46.3-17.5-51.9-41l0 0c-30.6 4.3-54.2 30.7-54.2 62.4l0 .2c47.4 1.8 90.6 15.1 124.9 36.3c12.6-9.7 28.4-15.5 45.5-15.5c41.3 0 74.7 33.4 74.7 74.7c0 29.8-17.4 55.5-42.7 67.5c-2.4 86.8-97 156.6-213.2 156.6S45.5 410.1 43 323.4C17.6 311.5 0 285.7 0 255.7c0-41.3 33.4-74.7 74.7-74.7c17.2 0 33 5.8 45.7 15.6c34-21.1 76.8-34.4 123.7-36.4l0-.3c0-44.3 33.7-80.9 76.8-85.5C325.8 50.2 347.2 32 373 32c29.4 0 53.3 23.9 53.3 53.3s-23.9 53.3-53.3 53.3zM157.5 255.3c-20.9 0-38.9 20.8-40.2 47.9s17.1 38.1 38 38.1s36.6-9.8 37.8-36.9s-14.7-49.1-35.7-49.1zM395 303.1c-1.2-27.1-19.2-47.9-40.2-47.9s-36.9 22-35.7 49.1c1.2 27.1 16.9 36.9 37.8 36.9s39.3-11 38-38.1zm-60.1 70.8c1.5-3.6-1-7.7-4.9-8.1c-23-2.3-47.9-3.6-73.8-3.6s-50.8 1.3-73.8 3.6c-3.9 .4-6.4 4.5-4.9 8.1c12.9 30.8 43.3 52.4 78.7 52.4s65.8-21.6 78.7-52.4z"
                                                />
                                            </svg>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.youtube.com/c/FunexClub" target="_blank" rel="noreferrer">
                                            <svg
                                                className="svg-inline--fa fa-youtube fa-w-18"
                                                aria-hidden="true"
                                                data-prefix="fab"
                                                data-icon="youtube"
                                                role="img"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 576 512"
                                                data-fa-i2svg=""
                                            >
                                                <path
                                                    fill="currentColor"
                                                    d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"
                                                ></path>
                                            </svg>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/officialfunexclub/" target="_blank" rel="noreferrer">
                                            <svg
                                                className="svg-inline--fa fa-instagram fa-w-14"
                                                aria-hidden="true"
                                                data-prefix="fab"
                                                data-icon="instagram"
                                                role="img"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 448 512"
                                                data-fa-i2svg=""
                                            >
                                                <path
                                                    fill="currentColor"
                                                    d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                                                ></path>
                                            </svg>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://medium.com/@funexclub" target="_blank" rel="noreferrer">
                                            <svg
                                                className="svg-inline--fa fa-medium-m fa-w-16"
                                                aria-hidden="true"
                                                data-prefix="fab"
                                                data-icon="medium-m"
                                                role="img"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 512 512"
                                                data-fa-i2svg=""
                                            >
                                                <path
                                                    fill="currentColor"
                                                    d="M71.5 142.3c.6-5.9-1.7-11.8-6.1-15.8L20.3 72.1V64h140.2l108.4 237.7L364.2 64h133.7v8.1l-38.6 37c-3.3 2.5-5 6.7-4.3 10.8v272c-.7 4.1 1 8.3 4.3 10.8l37.7 37v8.1H307.3v-8.1l39.1-37.9c3.8-3.8 3.8-5 3.8-10.8V171.2L241.5 447.1h-14.7L100.4 171.2v184.9c-1.1 7.8 1.5 15.6 7 21.2l50.8 61.6v8.1h-144v-8L65 377.3c5.4-5.6 7.9-13.5 6.5-21.2V142.3z"
                                                ></path>
                                            </svg>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://discord.com/invite/akWYcUGwwp" target="_blank" rel="noreferrer">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
                                                <path
                                                    fill="currentColor"
                                                    d="M524.5 69.8a1.5 1.5 0 0 0 -.8-.7A485.1 485.1 0 0 0 404.1 32a1.8 1.8 0 0 0 -1.9 .9 337.5 337.5 0 0 0 -14.9 30.6 447.8 447.8 0 0 0 -134.4 0 309.5 309.5 0 0 0 -15.1-30.6 1.9 1.9 0 0 0 -1.9-.9A483.7 483.7 0 0 0 116.1 69.1a1.7 1.7 0 0 0 -.8 .7C39.1 183.7 18.2 294.7 28.4 404.4a2 2 0 0 0 .8 1.4A487.7 487.7 0 0 0 176 479.9a1.9 1.9 0 0 0 2.1-.7A348.2 348.2 0 0 0 208.1 430.4a1.9 1.9 0 0 0 -1-2.6 321.2 321.2 0 0 1 -45.9-21.9 1.9 1.9 0 0 1 -.2-3.1c3.1-2.3 6.2-4.7 9.1-7.1a1.8 1.8 0 0 1 1.9-.3c96.2 43.9 200.4 43.9 295.5 0a1.8 1.8 0 0 1 1.9 .2c2.9 2.4 6 4.9 9.1 7.2a1.9 1.9 0 0 1 -.2 3.1 301.4 301.4 0 0 1 -45.9 21.8 1.9 1.9 0 0 0 -1 2.6 391.1 391.1 0 0 0 30 48.8 1.9 1.9 0 0 0 2.1 .7A486 486 0 0 0 610.7 405.7a1.9 1.9 0 0 0 .8-1.4C623.7 277.6 590.9 167.5 524.5 69.8zM222.5 337.6c-29 0-52.8-26.6-52.8-59.2S193.1 219.1 222.5 219.1c29.7 0 53.3 26.8 52.8 59.2C275.3 311 251.9 337.6 222.5 337.6zm195.4 0c-29 0-52.8-26.6-52.8-59.2S388.4 219.1 417.9 219.1c29.7 0 53.3 26.8 52.8 59.2C470.7 311 447.5 337.6 417.9 337.6z"
                                                />
                                            </svg>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://bitcointalk.org/index.php?action=profile;u=2877069" target="_blank" rel="noreferrer">
                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M13.0559 9.67992C12.7718 10.8166 11.0026 10.2391 10.4526 10.0832L10.9568 8.08492C11.5251 8.24992 13.3493 8.48825 13.0559 9.67992ZM10.2051 11.1099L9.65511 13.3191C10.3334 13.4932 12.4326 14.1624 12.7443 12.9157C13.0743 11.6141 10.8834 11.2749 10.2051 11.1099ZM19.8943 13.2182C18.6659 18.1316 13.6976 21.1199 8.78427 19.8916C3.87094 18.6632 0.885355 13.6949 2.11094 8.78158C2.40211 7.61383 2.92048 6.51488 3.63643 5.54749C4.35238 4.5801 5.25189 3.76323 6.28359 3.14353C7.31529 2.52383 8.45896 2.11344 9.64928 1.93581C10.8396 1.75817 12.0533 1.81677 13.2209 2.10825C18.1251 3.33658 21.1134 8.30492 19.8943 13.2182ZM13.0284 7.37908L13.4409 5.72908L12.4326 5.49992L12.0293 7.08575C11.7634 7.02158 11.4976 6.95742 11.2226 6.90242L11.6259 5.27992L10.6268 5.04158L10.2143 6.68242C9.99427 6.62742 9.77427 6.58158 9.5726 6.52658L8.18844 6.17825L7.91344 7.25075C7.91344 7.25075 8.66511 7.42492 8.64677 7.43408C9.05927 7.53492 9.13261 7.79158 9.11427 8.02075L7.98677 12.5399C7.94094 12.6682 7.79427 12.8332 7.52844 12.7874C7.53761 12.7966 6.79511 12.6041 6.79511 12.6041L6.30011 13.7499L7.60177 14.0799C7.84927 14.1441 8.08761 14.2083 8.32594 14.2632L7.90427 15.9316L8.91261 16.1882L9.32511 14.5291C9.60011 14.6024 9.86594 14.6666 10.1226 14.7399L9.71011 16.3807L10.7184 16.6374L11.1401 14.9691C12.8359 15.2899 14.1376 15.1616 14.6693 13.6124C15.1276 12.3749 14.6693 11.6416 13.7526 11.1741C14.4126 10.9999 14.9076 10.5874 15.0451 9.68908C15.2284 8.46992 14.2934 7.81908 13.0284 7.37908Z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://github.com/funexcoin" target="_blank" rel="noreferrer">
                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M11.0026 1.8335C9.79882 1.8335 8.60682 2.0706 7.49467 2.53127C6.38252 2.99194 5.372 3.66715 4.52079 4.51835C2.80171 6.23743 1.83594 8.56901 1.83594 11.0002C1.83594 15.0518 4.46677 18.4893 8.10594 19.7085C8.56427 19.7818 8.71094 19.4977 8.71094 19.2502V17.701C6.17177 18.251 5.63094 16.4727 5.63094 16.4727C5.20927 15.4093 4.61344 15.1252 4.61344 15.1252C3.77927 14.5568 4.6776 14.5752 4.6776 14.5752C5.59427 14.6393 6.0801 15.5193 6.0801 15.5193C6.8776 16.9127 8.2251 16.5002 8.7476 16.2802C8.8301 15.6843 9.06844 15.281 9.3251 15.0518C7.2901 14.8227 5.15427 14.0343 5.15427 10.5418C5.15427 9.52433 5.5026 8.7085 6.09844 8.05766C6.00677 7.8285 5.68594 6.87516 6.1901 5.63766C6.1901 5.63766 6.9601 5.39016 8.71094 6.57266C9.4351 6.371 10.2234 6.27016 11.0026 6.27016C11.7818 6.27016 12.5701 6.371 13.2943 6.57266C15.0451 5.39016 15.8151 5.63766 15.8151 5.63766C16.3193 6.87516 15.9984 7.8285 15.9068 8.05766C16.5026 8.7085 16.8509 9.52433 16.8509 10.5418C16.8509 14.0435 14.7059 14.8135 12.6618 15.0427C12.9918 15.3268 13.2943 15.886 13.2943 16.7385V19.2502C13.2943 19.4977 13.4409 19.791 13.9084 19.7085C17.5476 18.4802 20.1693 15.0518 20.1693 11.0002C20.1693 9.79638 19.9322 8.60438 19.4715 7.49223C19.0108 6.38008 18.3356 5.36955 17.4844 4.51835C16.6332 3.66715 15.6227 2.99194 14.5105 2.53127C13.3984 2.0706 12.2064 1.8335 11.0026 1.8335Z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://github.com/TechRate/Smart-Contract-Audits/blob/main/August_2022/Funexcoin.pdf" target="_blank" rel="noreferrer">
                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M16.4974 20.1668C16.9836 20.1668 17.4499 19.9737 17.7938 19.6299C18.1376 19.286 18.3307 18.8197 18.3307 18.3335V7.3335L12.8307 1.8335H5.4974C5.01117 1.8335 4.54485 2.02665 4.20103 2.37047C3.85722 2.71428 3.66406 3.1806 3.66406 3.66683V18.3335C3.66406 18.8197 3.85722 19.286 4.20103 19.6299C4.54485 19.9737 5.01117 20.1668 5.4974 20.1668H16.4974ZM11.9141 3.66683L16.4974 8.25016H11.9141V3.66683ZM6.41406 7.3335H9.16406V9.16683H6.41406V7.3335ZM6.41406 11.0002H15.5807V12.8335H6.41406V11.0002ZM6.41406 14.6668H15.5807V16.5002H6.41406V14.6668Z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://steemit.com/@funexcoin" target="_blank" rel="noreferrer">
                                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clipPath="url(#clip0_2348_211)">
                                                    <path
                                                        d="M19.2307 6.64239C18.3462 5.75682 17.284 5.06886 16.1141 4.62389C16.775 2.68055 19.118 1.97289 19.118 1.97289C15.5403 0.267087 11.5336 -0.32743 7.61474 0.266054C5.51598 0.680831 3.62454 1.80689 2.25957 3.45422C1.53133 4.29238 0.976725 5.26677 0.627896 6.32088C0.279066 7.375 0.142955 8.48787 0.227455 9.59499C0.311955 10.7021 0.615385 11.7814 1.12015 12.7704C1.62492 13.7594 2.32098 14.6383 3.16799 15.3562C3.71615 15.8256 4.94082 16.5552 4.96282 16.5928C4.15982 18.6333 1.59132 19.1916 1.59132 19.1916C4.0398 20.5992 6.74279 21.5079 9.54432 21.8655C10.8126 22.0267 12.0951 22.0421 13.3668 21.9113C15.5742 21.6509 17.6255 20.6414 19.1785 19.0513C20.0021 18.2429 20.6572 17.2793 21.1061 16.2162C21.555 15.1531 21.7886 14.0115 21.7936 12.8575C21.7985 11.7035 21.5746 10.5599 21.1349 9.493C20.6952 8.42605 20.0483 7.45686 19.2317 6.64147L19.2307 6.64239ZM17.6687 17.6956C16.3791 18.9171 14.7255 19.6835 12.9598 19.8781C11.8763 19.9923 10.7835 19.9874 9.70107 19.8635C8.37124 19.68 7.0709 19.3244 5.83274 18.8056C6.42415 18.2541 6.89266 17.5841 7.20774 16.8394C7.28218 16.647 7.31652 16.4413 7.30864 16.2352C7.30076 16.029 7.25082 15.8266 7.1619 15.6404C6.57264 14.4258 6.38176 13.0562 6.61648 11.7267C6.85119 10.3973 7.49952 9.17586 8.46907 8.23647C9.7197 7.02542 11.3951 6.35265 13.1359 6.36242C14.8768 6.37219 16.5445 7.06372 17.7815 8.28872C18.999 9.55875 19.6693 11.256 19.6482 13.0152C19.6271 14.7744 18.9163 16.4551 17.6687 17.6956Z"
                                                        fill="currentColor"
                                                    />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_2348_211">
                                                        <rect width="22" height="22" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            <div className={styles.copy_bar}>
                <div className={styles.container}>
                    <p>© {currentYear} Funexexchange All Rights Reserved</p>
                </div>
            </div>
        </div>
    );
}
