import React from 'react';
import styles from '../styles/downloads.module.css';

// import playstoreImg from '../assets/images/playstore.svg';
// import appstoreImg from '../assets/images/appstore.svg';
import playstoreImg from '../assets/images/playstore_comingsoon.png';
import appstoreImg from '../assets/images/appstore_comingsoon.png';
import downloadImg from '../assets/images/downloadImg.png';
import Layout from '../components/Layout/Layout';

export default function Downloads() {
    return (
        <Layout>
            
            <section className={styles.banner_section}> 
                <div className='container'>
                    <div className='row align-items-center'>
                        <div className='col-lg-5 col-md-5 col-sm-12'>
                            <div className={styles.banner_section_content}>
                                <h2>Trade Any Time <br /> Any Whare</h2>
                                <p>We've created the mobile tools you need for trading on the go.</p>
                                <div className={styles.appDownload}>
                                    <a href='' className='ps-0'>
                                        <img src={playstoreImg} alt="" className='img-fluid'/>
                                    </a>
                                    <a href=''>
                                        <img src={appstoreImg} alt="" className='img-fluid'/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-7 col-md-7 col-sm-12'>
                            <div className={styles.banner_section_img}>
                                <img src={downloadImg} alt='' className='img-fluid' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </Layout>
    );
}
