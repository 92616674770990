import React from "react";

export default function Loader() {
    return (
        <div className="loading_wave">
            <div className="loading_bar"></div>
            <div className="loading_bar"></div>
            <div className="loading_bar"></div>
            <div className="loading_bar"></div>
        </div>
    );
}
