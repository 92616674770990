import React, { useState, useEffect, useContext } from "react";
import OtpInput from "react-otp-input";
import Logo from "./Logo";
import styles from "../../styles/register.module.css";
import authImg from "../../assets/images/verification.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { GetApi, PostApi } from "../../api/api-service";
import { API_Path } from "../../const";
import { toast } from "react-toastify";
import Loading from "../../components/loader/Loading";
import { AuthContext } from "../../context/AuthContext";
import useFetch from "../../components/customHook/useFetch";

export default function LoginVerify() {
    const navigate = useNavigate();
    const location = useLocation();
    const [firstCoin , baseCoin , RunningPair] = useFetch();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const [otp, setOtp] = useState("");
    const [alertErrors, setAlertErrors] = useState([]);
    const [isShowMobileVerify, setIsShowMobileVerify] = useState(false);
    const [userModel, setUserModel] = useState({
        EmailIdOrApi: "",
        OneTimeCode: "",
        LoginSafetyCode: "",
        MobileNo: "",
        IsMobileAuthentication: false,
        OtpCode: "",
    });

    useEffect(() => {
        loginVerifyGetUser();
    }, []);

    const loginVerifyGetUser = () => {
        const loginVerifyGetUserData = new Promise((resolve) => {
            resolve(GetApi(API_Path.loginVerifyGetUser + location?.state?.id));
        });
        loginVerifyGetUserData.then((res) => {
            if (res) {
                if (res.data.Isvalid) {
                    var responseModel = res.data.Model;
                    setUserModel({
                        EmailIdOrApi: responseModel.EmailIdOrApi,
                        EmailIdOrApi: responseModel.EmailIdOrApi,
                        LoginSafetyCode: responseModel.LoginSafetyCode != null && responseModel.LoginSafetyCode,
                        MobileNo: responseModel.MobileNo,
                        IsMobileAuthentication: responseModel.IsMobileAuthentication,
                    });
                } else {
                    setAlertErrors(res.data.Errors);
                }
            } else {
                toast.error("Something went wrong!");
            }
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (otp.trim() === "") {
            setError(true);
        } else {
            setError(false);
            setLoading(true);
            let data = {
                EncryptedUrl: location?.state?.id,
                AuthenticatorCode: otp,
                IsVerifyByMobileOtp: false,
                OtpCode: "",
            };
            const getCryptoCurrency = new Promise((resolve) => {
                resolve(PostApi(API_Path.userLoginAuthVerify, data));
            });
            getCryptoCurrency.then((res) => {
                if (res) {
                    if (res.data.Isvalid === true) {
                        setOtp("");
                        let auth = res.data.Model.Token;
                        let email = res.data.Model.UserName;
                        localStorage.setItem("FunexAuthorizationData", auth);
                        localStorage.setItem("FunexAuthData", email);
                        localStorage.setItem("RunningPair", RunningPair.toUpperCase());
                        localStorage.setItem("FirstCoin", firstCoin.toUpperCase());
                        localStorage.setItem("BaseCoin", baseCoin.toUpperCase());
                        navigate(`/trading/advance/${firstCoin.toUpperCase()}_${baseCoin.toUpperCase()}`);
                        toast.success(res.data.Successes[0]);
                        setLoading(false);
                    } else {
                        setLoading(false);
                        toast.error(res.data.Errors[0]);
                    }
                }
            });
        }
    };
    const setResendOtpData = () => {
        let data = {
            EmailIdOrApi: userModel.EmailIdOrApi,
            EncryptedUrl: location?.state?.id,
        };
        const getCryptoCurrency = new Promise((resolve) => {
            resolve(PostApi(API_Path.resendUserLoginVerify, data));
        });
        getCryptoCurrency.then((res) => {
            if (res) {
                if (res.data.Isvalid === true) {
                    toast.success(res.data.Successes[0]);
                } else {
                    toast.error(res.data.Errors[0]);
                }
            }
        });
    };
    const SendLoginVerifyMobileOtp = () => {
        let data = {
            EncryptedUrl: localStorage.getItem("EncryptedUrl"),
        };
        const getCryptoCurrency = new Promise((resolve) => {
            resolve(PostApi(API_Path.SendLoginVerifyMobileOtp, data));
        });
        getCryptoCurrency.then((res) => {
            if (res) {
                if (res.data.Isvalid === true) {
                    toast.success(res.data.Successes[0]);
                } else {
                    toast.error(res.data.Errors[0]);
                }
            }
        });
    };

    return (
        <React.Fragment>
            {loading && <Loading />}
            <div className="container-fluid">
                <div className="row">
                    <div className={`${styles.col_width_left} ${styles.col_width_flex}`}>
                        <div className={styles.main_box}>
                            <div className={styles.lr_logo}>
                                <Logo />
                            </div>
                            <div className="mb-4">
                                <h3>
                                    Login <br /> Verify
                                </h3>
                            </div>
                            {alertErrors.length > 0 && (
                                <div>
                                    {alertErrors?.map((item, i) => (
                                        <div className="alert alert-danger mb-1" key={i}>
                                            <span>{item}</span>
                                        </div>
                                    ))}
                                </div>
                            )}
                            {alertErrors.length <= 0 && (
                                <>
                                    {userModel.LoginSafetyCode != "" && <p className={styles.sefty_code}>{userModel.LoginSafetyCode}</p>}
                                    <div className={`${styles.agreeTermsStyle} my-4`}>
                                        {!isShowMobileVerify ? "Please enter the Login OTP received by e-mail." : "Please enter the Login OTP received by SMS."}
                                    </div>
                                    {!isShowMobileVerify ? (
                                        <div className={`${styles.agreeTermsStyle} my-4`}>
                                            <b>Email Address : {userModel?.EmailIdOrApi}</b>
                                        </div>
                                    ) : (
                                        <div className={`${styles.login_bottomtext} mb-4`}>
                                            <b>Mobile No : {userModel?.MobileNo}</b>
                                            <button type="button" className={`${styles.btn_link} ms-2`} onClick={() => SendLoginVerifyMobileOtp()}>
                                                Send OTP
                                            </button>
                                        </div>
                                    )}

                                    <form onSubmit={handleSubmit}>
                                        <div className={`${styles.box_login_verify} mb-3`}>
                                            <OtpInput
                                                value={otp}
                                                onChange={setOtp}
                                                numInputs={isShowMobileVerify ? 6 : 4}
                                                renderSeparator={<span></span>}
                                                renderInput={(props) => <input {...props} />}
                                                placeholder={isShowMobileVerify ? "000000" : "0000"}
                                                inputStyle={error ? "border_data" : ""}
                                                shouldAutoFocus={true}
                                            />
                                            {error && <div className="error-message mt-2">Please enter otp code</div>}
                                        </div>
                                        {!isShowMobileVerify && (
                                            <div className={`${styles.timeStyle} pt-3`}>
                                                <p>
                                                    Did not receive OTP?{" "}
                                                    <button type="button" className={styles.btn_link} onClick={() => setResendOtpData()}>
                                                        Click Here
                                                    </button>{" "}
                                                    to re-send OTP.
                                                </p>
                                            </div>
                                        )}
                                        <div className={styles.loginBtn}>
                                            <button type="submit" className="button primary_button w-100" disabled={loading}>
                                                Confirm
                                            </button>
                                        </div>
                                    </form>
                                    {userModel.IsMobileAuthentication && (
                                        <div className={`${styles.login_bottomtext} mt-4`}>
                                            <>
                                                Switch To{" "}
                                                <button type="button" className={styles.btn_link} onClick={() => setIsShowMobileVerify(!isShowMobileVerify)}>
                                                    {isShowMobileVerify ? "GOOGLE AUTHENTICATIOR ?" : "SMS OTP ?"}
                                                </button>
                                            </>
                                        </div>
                                    )}
                                </>
                            )}

                            <div className={`${styles.login_bottomtext} mt-3`}>
                                Go to <Link to="/login">Login</Link>
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.col_width_right} p-0 d-lg-block d-none`}>
                        <div className={styles.side_img}>
                            <img src={authImg} alt="" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
