import React, { useEffect, useState } from "react";
import style from "../styles/blog.module.css";
import { Link } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import { GetApi } from "../api/api-service";
import { API_Path, baseName } from "../const";
import Loading from "../components/loader/Loading";
import { toast } from "react-toastify";

export default function Blog() {
    const [activeBlog, setActiveBlog] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        GetAllActiveBlogs();
    }, []);

    const GetAllActiveBlogs = () => {
        const AllActiveBlogsData = new Promise((resolve) => {
            resolve(GetApi(API_Path.GetAllActiveBlogs));
        });
        AllActiveBlogsData.then((res) => {
            if (res) {
                if (typeof res?.data !== "string") setActiveBlog(res?.data);
            } else {
                toast.error("Something went wrong!");
            }
            setLoading(false);
        });
    };

    return (
        <Layout>
            {loading && <Loading />}
            <section>
                <div className={style.banner}>
                    <div className="container title_heading d-block">
                        <h4 className="p-0">{baseName} Blog</h4>
                        <span>Highly informative articles about Cryptocurrency and Digital Assets markets from the world’s leading cryptocurrency exchange</span>
                    </div>
                </div>
                <div className="container">
                        <div className={style.section}>
                            <div className="row">   
                            {activeBlog.length > 0 ? (
                                activeBlog?.map((item, i) => (
                                    <div className="col-lg-3 col-md-6 col-sm-6" key={i}>
                                        <div className={style.box}>
                                            <div className={style.blog_img}>
                                                <img src={`https://exchange-admin.funexcoin.com/site/images/blogs/${item.BLOG_FILE}`} alt="" className="img-fluid" />
                                            </div>
                                            <div className={style.details}>
                                                <h6>{item.NAME}</h6>
                                                <span>{item.UPD_DATE}</span>
                                                <p className={style.short_description}>{item.SHORT_DESCRIPTION}</p>
                                                <Link to={item.DESCRIPTION === null || item.DESCRIPTION == "" ? "/blog" : `/blog/${item.NAME_URL}`} state={{ item }}>
                                                    READ MORE
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <>
                                    <h5>No records found</h5>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}
