import React, { useEffect, useState } from "react";
import styles from "../../styles/register.module.css";
import { API_Path, FunexAuthorizationData } from "../../const";
import { PostTokenApi } from "../../api/api-service";
import Logo from "./Logo";
import SideImg from "../../assets/images/verification.png";
import Loading from "../../components/loader/Loading";
import { Link, useNavigate } from "react-router-dom";

//#region  Global veriable

var valueUrl;

//#endregion Global veriable

export default function ApiverifyModal() {
    const navigate = useNavigate();
    const [errorMsg, setErrorMsg] = useState();
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("Your have take some time for create new api.");
    const [encryptedUrl, setEncryptedUrl] = useState(null);

    useEffect(() => {
        setLoading(false);
        if (FunexAuthorizationData !== null) {
            SendAPIMangement();
        }
        setEncryptedUrl( localStorage.getItem("APIUrl"));
    }, []);

    const SendAPIMangement = async () => {
        const data = {
            EncryptedUrl: encryptedUrl,
        };
        try {
            const res = await PostTokenApi(API_Path.SendAPIManagement, data);
            if (res) {
                setLoading(false);
                if (res.data.Isvalid === true) {
                    navigate("/useraccount/profile/api-management");
                    setMessage("Your new api key has been created successfully.");
                } else {
                    setMessage("Create new api verify has been failed.");
                    setErrorMsg(res.data.Errors);
                }
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    return (
        <React.Fragment>
            {loading && <Loading />}

            <div className="container-fluid">
                <div className="row">
                    <div className={`${styles.col_width_left} ${styles.col_width_flex}`}>
                        <div className={styles.main_box}>
                            <div className={styles.lr_logo}>
                                <Logo />
                            </div>
                            <div className="mb-4">
                                <h3>Verify Create New Api</h3>
                            </div>

                            <div className={styles.verify_mail_body}>
                                <p className="mb-4">{message}</p>
                                {errorMsg && (
                                    <div className={`${styles.alert} ${styles.alert_danger}`}>
                                        <span>{errorMsg[0]}</span>
                                    </div>
                                )}
                            </div>
                            <p className={`${styles.login_bottomtext} text-start`}>
                                Go to? {FunexAuthorizationData !== null ? <Link to="/useraccount/profile/api-management">Account</Link> : <Link to="/">Home</Link>}
                            </p>
                        </div>
                    </div>
                    <div className={`${styles.col_width_right} p-0 d-lg-block d-none`}>
                        <div className={styles.side_img}>
                            <img src={SideImg} alt="JAMSKE" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
