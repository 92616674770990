import React, { useEffect, useState } from "react";
import styles from "../styles/coinInfo.module.css";
import { Link } from "react-router-dom";
import { API_Path } from "../const";
import { GetApi } from "../api/api-service";
import { toast } from "react-toastify";
import Loading from "../components/loader/Loading";
import Layout from "../components/Layout/Layout";

export default function CoinInfo() {
    const [coinName, setCoinName] = useState("BCH");
    const [CryptoCurrencyData, setCryptoCurrencyData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");

    const filteredCoinData = CryptoCurrencyData.filter((item) => item.COIN.toLowerCase().includes(searchQuery.toLowerCase()) || item.COIN_NAME.toLowerCase().includes(searchQuery.toLowerCase()));

    useEffect(() => {
        setLoading(true);
        getActiveCryptoCurrencyData();
    }, []);

    const getActiveCryptoCurrencyData = () => {
        const getCryptoCurrency = new Promise((resolve) => {
            resolve(GetApi(API_Path.getActiveCryptoCurrency));
        });
        getCryptoCurrency.then((res) => {
            setLoading(false);
            if (res) {
                setCryptoCurrencyData(res.data);
            } else {
                toast.error(res.statusText);
            }
        });
    };

    return (
        <React.Fragment>
            {loading && <Loading />}

            <Layout>
                <section className={styles.sectionMain}>
                    <div className={styles.container}>
                        <div className={styles.symbol_detail_flex}>
                            <div className={styles.coin_display}>
                                <ul className={`${styles.nav} ${styles.nav_tabs}`}>
                                    <li>
                                        <div className="form-group">
                                            <div className={`${styles.info_search} searchbar_style`}>
                                                <input type="search" placeholder="Search" className="form-control" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 18 18" fill="none">
                                                    <path d="M15.7469 15.7498L12.4844 12.4873" stroke="#404040" strokeLinecap="round" strokeLinejoin="round" />
                                                    <path
                                                        d="M8.25 14.25C11.5637 14.25 14.25 11.5637 14.25 8.25C14.25 4.93629 11.5637 2.25 8.25 2.25C4.93629 2.25 2.25 4.93629 2.25 8.25C2.25 11.5637 4.93629 14.25 8.25 14.25Z"
                                                        stroke="#404040"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </div>
                                        </div>
                                    </li>
                                    {filteredCoinData &&
                                        filteredCoinData.map((item, i) => (
                                            <li className={styles.nav_item} key={item.Id}>
                                                <Link to="" key={i} className={item.COIN == coinName ? `${styles.nav_link}  ${styles.active}` : styles.nav_link} onClick={() => setCoinName(item.COIN)}>
                                                    <div className="d-flex justify-content-between">
                                                        <span>
                                                            {item.COIN} - {item.COIN_NAME}
                                                        </span>
                                                        {/* <div className={styles.arrow_img}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 14 14" fill="none">
                                                        <path d="M4.90188 0.699951L3.92188 1.74995L9.10187 6.99995L3.92188 12.25L4.90188 13.3L11.2019 6.99995L4.90188 0.699951Z" fill="currentColor"/>
                                                    </svg>
                                                </div> */}
                                                    </div>
                                                </Link>
                                            </li>
                                        ))}
                                </ul>
                                {/* {coinName && searchQuery ? (
                                    <div className={styles.coin_tab_content}>
                                        <div dangerouslySetInnerHTML={{ __html: filteredCoinData[0]?.CONTENTS ?? "" }}></div>
                                    </div>
                                ) : (
                                )} */}
                                {filteredCoinData.map(
                                    (item) =>
                                        item.COIN == coinName && (
                                            <div className={styles.coin_tab_content} key={item.ID}>
                                                <div dangerouslySetInnerHTML={{ __html: item.CONTENTS }}></div>
                                            </div>
                                        )
                                )}
                            </div>
                        </div>
                    </div>
                </section>
            </Layout>
        </React.Fragment>
    );
}
