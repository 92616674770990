import React, { useContext, useEffect, useState } from "react";
import Logo from "./Logo";
import styles from "../../styles/header.module.css";
import globleImg from "../../assets/images/globle.svg";
import lightImg from "../../assets/images/light_img.svg";
import { useLocation } from "react-router";
import LogoImg from "../../assets/images/logoTwo.svg";
import Dropdown from "react-bootstrap/Dropdown";
import dolarImg from "../../assets/images/dolar.svg";
import mobliedownImg from "../../assets/images/mobliedown.svg";
import userErrorImg from "../../assets/images/userError.svg";
import { Link } from "react-router-dom";
import LanguageCurrencyModal from "../../components/language_currency/LanguageCurrencyModal";
import { AuthContext } from "../../context/AuthContext";
import useFetch from "../../components/customHook/useFetch";
import closeIcon from "../../assets/images/close_icon.svg";
import { supportUrl } from "../../const";

export default function Header() {
    const location = useLocation();
    const [firstCoin, baseCoin, RunningPair] = useFetch();
    const [theme, setTheme]= useState("light");
    const [isNavCollapsed, setIsNavCollapsed] = useState(true); // Default to true for collapsed state
    const [scroll, setScroll] = useState(0);
    const [showLanguageModal, setShowLanguageModal] = useState(false);
    const [authToken, setauthToken] = useState();
    const { userCurrentData, getCurrentUserDetails } = useContext(AuthContext);
    let initialized = false;

    useEffect(() => {
        const items = localStorage.getItem("FunexAuthorizationData");
        if (items) setauthToken(items);
        if (!initialized) {
            initialized = true;
            if (items) getCurrentUserDetails();
        }
        const handleScroll = () => setScroll(window.scrollY);
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const handlesetLocalstroage = () => {
        localStorage.setItem("RunningPair", RunningPair.toUpperCase());
        localStorage.setItem("FirstCoin", firstCoin.toUpperCase());
        localStorage.setItem("BaseCoin", baseCoin.toUpperCase());
    };

    const handleNavToggle = () => setIsNavCollapsed(!isNavCollapsed);

    const handleShow = () => setShowLanguageModal(true);
    const handleClose = () => setShowLanguageModal(false);

    const handleLogout = () => {
        localStorage.removeItem("FunexAuthData");
        localStorage.removeItem("FunexAuthorizationData");
        window.location.href = "/";
    };

    const handleTheme = (type) => setTheme(type);

    return (
        <React.Fragment>
            <header className={styles.section} id="main-header">
                <div className={styles.main_row}>
                    <nav className={`${authToken ? "nav_pad " : ""} navbar navbar-expand-lg bg-body-tertiary`}>
                        <div className="container-fluid">
                            {authToken ? (
                                <div className={styles.two_logo_left}>
                                    <Link to="/">
                                        <img src={LogoImg} className="img-fluid" alt="" />
                                    </Link>
                                </div>
                            ) : (
                                <div className={`${styles.logo_left} ${styles.header_logo} text-start`}>
                                    <Logo />
                                </div>
                            )}
                            <button
                                className={`navbar-toggler ${!isNavCollapsed ? "d-none" : ""}`}
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target="#navbarTogglerDemo01"
                                aria-controls="navbarTogglerDemo01"
                                aria-label="Toggle navigation"
                                aria-expanded={!isNavCollapsed}
                                onClick={handleNavToggle}
                            >
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className={`${isNavCollapsed ? "collapse" : "collapse show"} navbar-collapse`} id="navbarTogglerDemo01">
                                <button
                                    className="btn btn-link navbar-toggler"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#navbarTogglerDemo01"
                                    aria-controls="navbarTogglerDemo01"
                                    aria-label="Toggle navigation"
                                    aria-expanded={!isNavCollapsed}
                                    onClick={handleNavToggle}
                                >
                                    <img src={closeIcon} alt="closeicon" className="closeicon" />
                                </button>
                                <div className={styles.class_two}>
                                    <div className={`${styles.menu_head} ${styles.manu_option}`} id="menu-box">
                                        {authToken ? (
                                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                                <li className={`nav-item position-relative ${styles.dropdown_manu}`}>
                                                    <Link
                                                        to={`/trading/advance/${firstCoin}_${baseCoin}`}
                                                        className={
                                                            location.pathname === `/trading/advance/${firstCoin}_${baseCoin}` || location.pathname === `/trading/classic/${firstCoin}_${baseCoin}`
                                                                ? `nav-link ${styles.active}`
                                                                : "nav-link"
                                                        }
                                                        target="_blank"
                                                        onClick={handlesetLocalstroage}
                                                    >
                                                        Exchange
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none" className="ms-2">
                                                            <path
                                                                d="M11.8616 4.67563C11.9912 4.56253 12.1591 4.5 12.333 4.5C12.507 4.5 12.6748 4.56253 12.8045 4.67563C12.8659 4.72879 12.9152 4.79407 12.9489 4.86715C12.9826 4.94023 13 5.01945 13 5.09959C13 5.17972 12.9826 5.25894 12.9489 5.33202C12.9152 5.4051 12.8659 5.47038 12.8045 5.52355L7.47153 10.3244C7.34188 10.4375 7.17403 10.5 7.00008 10.5C6.82613 10.5 6.65828 10.4375 6.52863 10.3244L1.19569 5.52355C0.935003 5.28959 0.935002 4.90958 1.1943 4.67563C1.4536 4.44167 1.87652 4.44167 2.1372 4.67563L6.99869 9.05046L11.8616 4.67563Z"
                                                                fill="currentColor"
                                                            />
                                                        </svg>
                                                    </Link>
                                                    <ul className={styles.dropdown_nav}>
                                                        <li>
                                                            <Link
                                                                to={`/trading/advance/${firstCoin}_${baseCoin}`}
                                                                className={location.pathname === `/trading/advance/${firstCoin}_${baseCoin}` ? `nav-link ${styles.active}` : "nav-link"}
                                                                onClick={handlesetLocalstroage}
                                                                target="_blank"
                                                            >
                                                                Advance
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link
                                                                to={`/trading/classic/${firstCoin}_${baseCoin}`}
                                                                className={location.pathname === `/trading/classic/${firstCoin}_${baseCoin}` ? `nav-link ${styles.active}` : "nav-link"}
                                                                onClick={handlesetLocalstroage}
                                                                target="_blank"
                                                            >
                                                                Classic
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </li>
                                                <li className={`${styles.dropdown_item} nav-item`}>
                                                    <Link
                                                        to="/useraccount/asset-overview"
                                                        className={`nav-link ${
                                                            location.pathname === "/useraccount/asset-overview" ||
                                                            location.pathname === "/useraccount/spot" ||
                                                            location.pathname === "/useraccount/funding" ||
                                                            location.pathname === "/useraccount/funds-history-spot"
                                                                ? `${styles.active}`
                                                                : ""
                                                        }`}
                                                        aria-expanded="false"
                                                    >
                                                        Funds
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/orderhistory" className={location.pathname === "/orderhistory" ? `nav-link ${styles.active}` : "nav-link"}>
                                                        Orders
                                                    </Link>
                                                </li>
                                            </ul>
                                        ) : (
                                            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                                <li className="nav-item">
                                                    <Link to="/" className={location.pathname === "/" ? `nav-link ${styles.active}` : "nav-link"}>
                                                        Home
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link
                                                        to={`/trading/advance/${firstCoin}_${baseCoin}`}
                                                        className={location.pathname === `/trading/advance/${firstCoin}_${baseCoin}` ? `nav-link ${styles.active}` : "nav-link"}
                                                        onClick={() => handlesetLocalstroage()}
                                                        target="_blank"
                                                    >
                                                        Trading
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link
                                                        to={`${supportUrl}/news`}
                                                        className={location.pathname === "/news" ? `nav-link ${styles.active}` : "nav-link"}
                                                        target="_blank"
                                                    >
                                                        News
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/blog" className={location.pathname === "/blog" ? `nav-link ${styles.active}` : "nav-link"}>
                                                        Blog
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link
                                                        to={`${supportUrl}/support`}
                                                        className="nav-link"
                                                        // className={location.pathname === "/support" ? `nav-link ${styles.active}` : "nav-link"}
                                                        target="_blank"
                                                    >
                                                        Support
                                                    </Link>
                                                </li>
                                                <li className="nav-item">
                                                    <Link to="/about-us" className={location.pathname === "/about-us" ? `nav-link ${styles.active}` : "nav-link"}>
                                                        About us
                                                    </Link>
                                                </li>
                                            </ul>
                                        )}
                                    </div>
                                    <div className={`${styles.icon_social}`}>
                                        {authToken ? (
                                            <div className={`${styles.soical_icon_auth} d-flex align-items-center justify-content-center`}>
                                                <Link to="/tickets" className="tickets-header">
                                                    <p className="mb-0">Tickets</p>
                                                </Link>
                                                <ul>
                                                    <li className="nav-item">
                                                        <Dropdown>
                                                            <Dropdown.Toggle id="dropdown-basic" className={styles.drodown_toggle}>
                                                                <img src={userErrorImg} alt="" />
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <Dropdown.ItemText>
                                                                    <div className={styles.profile_details}>
                                                                        <div className={styles.profile_img}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                                                <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z" />
                                                                            </svg>
                                                                        </div>
                                                                        <div className={styles.profile_text}>{userCurrentData?.EMAIL_ID}</div>
                                                                    </div>
                                                                </Dropdown.ItemText>
                                                                <Dropdown.Divider />
                                                                <Dropdown.Item href="/useraccount/profile/user-kyc-info " className={styles.use_profile_body}>
                                                                    Profile
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                                        <path d="M438.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L338.7 288H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H338.7L233.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                                                                    </svg>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item href="/useraccount/profile/user-kyc" className={styles.use_profile_body}>
                                                                    KYC
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                                        <path d="M438.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L338.7 288H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H338.7L233.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                                                                    </svg>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item href="/useraccount/profile/user-beneficiary" className={styles.use_profile_body}>
                                                                    Payment Options
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                                        <path d="M438.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L338.7 288H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H338.7L233.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                                                                    </svg>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item href="/useraccount/profile/security" className={styles.use_profile_body}>
                                                                    Security
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                                        <path d="M438.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L338.7 288H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H338.7L233.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                                                                    </svg>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item href="/useraccount/profile/safety-phrase" className={styles.use_profile_body}>
                                                                    Safety Phrase
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                                        <path d="M438.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L338.7 288H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H338.7L233.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                                                                    </svg>
                                                                </Dropdown.Item>
                                                                <Dropdown.Item href="/useraccount/profile/api-management" className={styles.use_profile_body}>
                                                                    API Management
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                                        <path d="M438.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L338.7 288H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H338.7L233.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z" />
                                                                    </svg>
                                                                </Dropdown.Item>
                                                                <Dropdown.Divider />
                                                                <Dropdown.Item className={styles.use_profile_body} onClick={handleLogout}>
                                                                    Logout
                                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                                        <path d="M502.6 233.4l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L402.7 224H192c-17.7 0-32 14.3-32 32s14.3 32 32 32H402.7l-105.4 105.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3zM64 64C64 46.3 78.3 32 96 32H256c17.7 0 32-14.3 32-32s-14.3-32-32-32H96C43 0 0 43 0 96V416c0 53 43 96 96 96H256c17.7 0 32-14.3 32-32s-14.3-32-32-32H96c-17.7 0-32-14.3-32-32V96z" />
                                                                    </svg>
                                                                </Dropdown.Item>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </li>
                                                </ul>
                                            </div>
                                        ) : (
                                            <div className={styles.auth_btton}>
                                                <ul className="m-0">
                                                    <li>
                                                        <Link to="/login">
                                                            Login
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/register" className={styles.login_btn}>
                                                            Get Started
                                                        </Link>
                                                    </li>
                                                    {/* <li className={styles.theme_switch} onClick={() => handleTheme(!theme)}>
                                                        <img src={lightImg} alt="" />
                                                    </li> */}
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </header>
            <LanguageCurrencyModal show={showLanguageModal} handleClose={handleClose} />
        </React.Fragment>
    );
}
