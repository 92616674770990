import React, { useEffect, useState } from "react";
import Logo from "./Logo";
import styles from "../../styles/register.module.css";
import authImg from "../../assets/images/Forgot-passwor.png";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { API_Path } from "../../const";
import { PostApi } from "../../api/api-service";
import { toast } from "react-toastify";
import Loading from "../../components/loader/Loading";

export default function ConfirmPassword() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [isVisible, setVisible] = useState(false);
    const [isResendVisible, setResendVisible] = useState(false);
    const [email, setEmail] = useState();

    useEffect(() => {
        if (localStorage.getItem("FunexAuthData")) setEmail(localStorage.getItem("FunexAuthData"));
    }, []);

    const handleToggle = () => setVisible(!isVisible);
    const handleResendToggle = () => setResendVisible(!isResendVisible);

    const setSubmitData = (value) => {
        setLoading(true);
        let data = {
            EncryptedUrl: "",
            EmailIdOrApi: "",
            Password: value.password,
            confirmPass: value.confirmPass,
        };
        const getCryptoCurrency = new Promise((resolve) => {
            resolve(PostApi(API_Path.resetPasswordVerify, data));
        });
        getCryptoCurrency.then((res) => {
            if (res) {
                if (res.data.Isvalid === true) {
                    navigate("/login");
                    toast.success(res.data.Successes[0]);
                } else {
                    toast.error(res.data.Errors[0]);
                }
            }
            setLoading(false);
        });
    };

    return (
        <React.Fragment>
            {loading && <Loading />}
            <div className="container-fluid">
                <div className="row">
                    <div className={`${styles.col_width_left} ${styles.col_width_flex}`}>
                        <div className={styles.main_box}>
                            <div className={styles.lr_logo}>
                                <Logo />
                            </div>
                            <div className="mb-4">
                                <h3>
                                    Reset <br /> Password
                                </h3>
                                <div className={`${styles.agreeTermsStyle} my-4 fw-semibold`}>{email}</div>
                            </div>
                            <Formik
                                initialValues={{ password: "", confirmPass: "" }}
                                validationSchema={Yup.object().shape({
                                    password: Yup.string().required("Password is required.").min(8),
                                    confirmPass: Yup.string()
                                    .required("Confirm password is required.")
                                    .test("passwords-match", "Confirm password does not match.", function (value) {
                                        return this.parent.password === value;
                                    }),
                                })}
                                onSubmit={(values) => {
                                    setSubmitData(values);
                                }}
                            >
                                {(runform) => {
                                    const { touched, errors } = runform;
                                    return (
                                        <Form onSubmit={runform.handleSubmit}>
                                            <div className={`${styles.inputWidth} mb-3`}>
                                            <label className={`${styles.login_label_text} d-block`}>New Password <span className='text-danger'>*</span></label>
                                                <div className="d-block position-relative">
                                                    <Field
                                                        type={!isVisible ? "password" : "text"}
                                                        className={`${styles.login_comn_input} ${errors.password && touched.password && "input-error"}`}
                                                        name="password"
                                                        placeholder="Enter new password"
                                                    />
                                                    <span className={styles.login_input_icon} onClick={handleToggle}>
                                                        {isVisible ? (
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none">
                                                                <path
                                                                    d="M6.24984 17.4999C8.78114 17.4999 10.8332 15.4479 10.8332 12.9166C10.8332 10.3853 8.78114 8.33325 6.24984 8.33325C3.71853 8.33325 1.6665 10.3853 1.6665 12.9166C1.6665 15.4479 3.71853 17.4999 6.24984 17.4999Z"
                                                                    stroke="#404040"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                                <path d="M17.5 1.66675L15.1667 4.00008M9.5 9.66675L13.6667 5.50008" stroke="#404040" strokeLinecap="round" strokeLinejoin="round" />
                                                                <path
                                                                    d="M12.9165 6.24992L15.4165 8.74992L18.3332 5.83325L15.8332 3.33325"
                                                                    stroke="#404040"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                            </svg>
                                                        ) : (
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none">
                                                                <path
                                                                    d="M8.54167 9.16675H7.08333H4.16667C3.24619 9.16675 2.5 9.91294 2.5 10.8334V16.6667C2.5 17.5872 3.24619 18.3334 4.16667 18.3334H15.8333C16.7538 18.3334 17.5 17.5872 17.5 16.6667V10.8334C17.5 9.91294 16.7538 9.16675 15.8333 9.16675H12.9167H11.4583"
                                                                    stroke="#404040"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                                <path
                                                                    d="M5.83301 9.16675V5.83341C5.83301 4.72835 6.27199 3.66854 7.0534 2.88714C7.8348 2.10573 8.89461 1.66675 9.99967 1.66675C11.1047 1.66675 12.1646 2.10573 12.946 2.88714C13.7274 3.66854 14.1663 4.72835 14.1663 5.83341V9.16675"
                                                                    stroke="#404040"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                            </svg>
                                                        )}
                                                    </span>
                                                </div>
                                                <ErrorMessage name="password" component="div" className="error-message" />
                                            </div>
                                            <div className={`${styles.inputWidth} mb-3`}>
                                            <label className={`${styles.login_label_text} d-block`}>Confirm Password <span className='text-danger'>*</span></label>
                                                <div className="d-block position-relative">
                                                    <Field
                                                        type={!isResendVisible ? "password" : "text"}
                                                        className={`${styles.login_comn_input} ${errors.confirmPass && touched.confirmPass && "input-error"}`}
                                                        name="confirmPass"
                                                        placeholder="Enter confirm password"
                                                    />
                                                    <span className={styles.login_input_icon} onClick={handleResendToggle}>
                                                        {isResendVisible ? (
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none">
                                                                <path
                                                                    d="M6.24984 17.4999C8.78114 17.4999 10.8332 15.4479 10.8332 12.9166C10.8332 10.3853 8.78114 8.33325 6.24984 8.33325C3.71853 8.33325 1.6665 10.3853 1.6665 12.9166C1.6665 15.4479 3.71853 17.4999 6.24984 17.4999Z"
                                                                    stroke="#404040"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                                <path d="M17.5 1.66675L15.1667 4.00008M9.5 9.66675L13.6667 5.50008" stroke="#404040" strokeLinecap="round" strokeLinejoin="round" />
                                                                <path
                                                                    d="M12.9165 6.24992L15.4165 8.74992L18.3332 5.83325L15.8332 3.33325"
                                                                    stroke="#404040"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                            </svg>
                                                        ) : (
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none">
                                                                <path
                                                                    d="M8.54167 9.16675H7.08333H4.16667C3.24619 9.16675 2.5 9.91294 2.5 10.8334V16.6667C2.5 17.5872 3.24619 18.3334 4.16667 18.3334H15.8333C16.7538 18.3334 17.5 17.5872 17.5 16.6667V10.8334C17.5 9.91294 16.7538 9.16675 15.8333 9.16675H12.9167H11.4583"
                                                                    stroke="#404040"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                                <path
                                                                    d="M5.83301 9.16675V5.83341C5.83301 4.72835 6.27199 3.66854 7.0534 2.88714C7.8348 2.10573 8.89461 1.66675 9.99967 1.66675C11.1047 1.66675 12.1646 2.10573 12.946 2.88714C13.7274 3.66854 14.1663 4.72835 14.1663 5.83341V9.16675"
                                                                    stroke="#404040"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                />
                                                            </svg>
                                                        )}
                                                    </span>
                                                </div>
                                                <ErrorMessage name="confirmPass" component="div" className="error-message" />
                                            </div>
                                            <div className={styles.loginBtn}>
                                                <button type="submit" className="button primary_button w-100" disabled={loading}>
                                                    Confirm New Password
                                                </button>
                                            </div>
                                            <p className={`${styles.login_bottomtext} text-start`}>
                                                Not Reset Password? <Link to="/login">Login</Link>
                                            </p>
                                        </Form>
                                    );
                                }}
                            </Formik>
                        </div>
                    </div>
                    <div className={`${styles.col_width_right} p-0 d-lg-block d-none`}>
                        <div className={`${styles.side_img} ${styles.confirm_img} pb-5`}>
                            <img src={authImg} alt="JAMSKE" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
