import React, { useEffect, useState } from "react";
import styles from "../../styles/profile.module.css";
import { GetTokenApi, PostTokenApi } from "../../api/api-service";
import { API_Path } from "../../const";
import { toast } from "react-toastify";
import Loading from "../loader/Loading";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { errorContainer, formAttr } from "../common/CommonFun";

var dataOfApi = [];

export default function () {
    const [loading, setLoading] = useState(false);
    const [userApiData, setUserApiData] = useState(dataOfApi);

    useEffect(() => {
        getUserApi();
    }, []);

    const getUserApi = () => {
        setLoading(true);
        let fatchUserApiData = new Promise((resolve) => {
            resolve(GetTokenApi(API_Path.getUserApi));
        });
        fatchUserApiData.then((res) => {
            setLoading(false);
            if (res) {
                dataOfApi = res.data;
                setUserApiData(dataOfApi);
                toast.success(res.data.Successes);
            } else {
                toast.error(res.data.Errors);
            }
        });
    };

    const setSubmitData = (formdata, resetForm) => {
        setLoading(true);
        let createuserAPiData = new Promise((resolve) => {
            resolve(PostTokenApi(API_Path.createUserApi, { ...formdata }));
        });
        createuserAPiData.then((res) => {
            setLoading(false);
            if (res.data.Isvalid === true) {
                toast.success(res.data.Successes[0]);
                localStorage.setItem("APIUrl", res.data.Model);
                resetForm();
            } else {
                toast.error(res.data.Errors[0]);
            }
        });
    };

    const RemoveUserApi = (Id) => {
        setLoading(true);
        let removeUserApiData = new Promise((resolve) => {
            resolve(PostTokenApi(API_Path.removeUserApi, { Id: Id }));
        });
        removeUserApiData.then((res) => {
            setLoading(false);
            if (res) {
                setUserApiData(res.data);
                toast.success(res.data.Successes);
            } else {
                toast.error(res.data.Errors);
            }
        });
    };

    return (
        <React.Fragment>
            {loading && <Loading />}
            <div className={`${styles.personal_info_body}`}>
                <div className={styles.title}>
                    <h6 className="mb-2">API Management</h6>
                </div>
                <p>For the security of you account, NEVER SHARE your unique API Keys and Secret Key with anyone. Please store the Secret Key safely as you shall not be able to recover it.</p>
                <div className={styles.api_title}>
                    <h3>Create new API</h3>
                </div>
                <div>
                    <Formik
                        initialValues={{ ApiLabel: "" }}
                        validationSchema={Yup.object().shape({
                            ApiLabel: Yup.string().required("Authentication code is required."),
                        })}
                        onSubmit={(values, { resetForm }) => {
                            setSubmitData(values, resetForm);
                        }}
                    >
                        {(runform) => {
                            const { touched, errors } = runform;
                            return (
                                <Form onSubmit={runform.handleSubmit}>
                                    <div className={styles.personalinfocontent}>
                                        <label>
                                            API label
                                            <span className={styles.asterisk}>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            placeholder="Enter API label"
                                            className={`${styles.form_input} ${errors.ApiLabel && touched.ApiLabel ? "input-error" : ""} `}
                                            name="ApiLabel"
                                            {...formAttr(runform, "ApiLabel")}
                                            maxLength={20}
                                        />
                                        {errorContainer(runform, "ApiLabel")}
                                    </div>
                                    <div className="form-group">
                                        <div className={`${styles.personal_info_btn} text-center d-flex pt-3`}>
                                            <button type="button" className={`${styles.button} ${styles.button_border}`} onClick={() => runform.resetForm()}>
                                                Reset
                                            </button>
                                            <button type="submit" className={`${styles.button} primary_button`}>
                                                Create
                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
                {dataOfApi.length > 0 && (
                    <>
                        <div className={styles.title}>
                            <h6 className="mb-2">Created APIs</h6>
                        </div>
                        <p>Please store the Secret Key safely as you shall not be able to recover it.</p>
                        <div className={styles.create_box}>
                            {userApiData &&
                                userApiData.map((item) => (
                                    <div className={styles.create_box_bg} key={item.ID}>
                                        <div className={styles.create_api_body}>
                                            <h5 className="mb-2">
                                                API Label : <b className="ms-1">{item.API_LABEL}</b>
                                            </h5>
                                            <h5 className="mb-0">
                                                API key : <b className="ms-1">{item.API_KEY}</b>
                                            </h5>
                                        </div>
                                        <button type="submit" className={styles.create_btn} onClick={() => RemoveUserApi(item.ID)}>
                                            Delete
                                        </button>
                                    </div>
                                ))}
                        </div>
                    </>
                )}
            </div>
        </React.Fragment>
    );
}
