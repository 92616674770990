import React, { useEffect, useState } from "react";
import styles from "../../styles/profile.module.css";
import { GetTokenApi } from "../../api/api-service";
import { API_Path, baseName } from "../../const";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import Loading from "../loader/Loading";

var KYCData = "";
let isAllowKycComplete = false;
let kycStep = 0;

export default function KYCInfo(props) {
    const Navigate = useNavigate();
    const [useKycData, setUserKycData] = useState({ ...KYCData });
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        // if (!props.apiCalled) {
        setLoading(true);
        getUserKYCDetails();
        // }
    }, []);

    const getUserKYCDetails = () => {
        const getCryptoCurrency = new Promise((resolve) => {
            resolve(GetTokenApi(API_Path.getUserKyc));
        });
        getCryptoCurrency.then((res) => {
            if (res) {
                if (res.status === 200) {
                    setLoading(false);
                    KYCData = res.data;
                    setUserKycData(KYCData);
                    if (res.data.Id > 0) kycStep = 2;
                    else kycStep = 1;
                    if (KYCData.IsAllowKycComplete) {
                        isAllowKycComplete = true;
                    }
                } else {
                    setLoading(false);
                    toast.error(res.statusText);
                }
            }
        });
    };

    const handleRedirect = () => Navigate("/useraccount/profile/user-kyc-info ");

    return (
        <React.Fragment>
            {loading && <Loading />}
            <div className={`${styles.personal_info_body}`}>
                {kycStep == 1 && (
                    <div className={`${styles.kyc_alert} ${styles.kyc_alert_info}`}>
                        <span>Please complete the Profile before initiating KYC verification.</span>
                    </div>
                )}
                {kycStep === 2 && (
                    <>
                        {useKycData?.DocStatus === 0 && (
                            <>
                                <div className={styles.title}>
                                    <h6>Complete your kyc</h6>
                                </div>
                                <div className={styles.kyc_content}>
                                    <p>You will be redirected to a trusted, highly secure third-party service for “Instant KYC Verification” where you shall be required to :</p>
                                    <h3>Upload Desired Identity Document.</h3>
                                    <h3>Click & Upload Selfie.</h3>
                                    <span>
                                        <bdi>Important :</bdi> You can scan the QR code or request for a SMS link for completing KYC verification on your mobile.
                                    </span>
                                </div>
                            </>
                        )}
                        {useKycData?.DocStatus === 1 && (
                            <div className={`${styles.detatils_white_box} text-center`}>
                                <div className={styles.title}>
                                    <h6>We Are Reviewing Your KYC</h6>
                                </div>
                                <div className={styles.kyc_content}>
                                    <p className="mb-0">Your KYC is under review at {baseName.toLowerCase()}. KYC is instant if the documents are</p>
                                    <p className="mb-0">valid and clearly visible. If not, we have to manually review the documents</p>
                                    <p>and information provided by you. This could take some time.</p>
                                    <span>You can still :</span>
                                    <ul>
                                        <li className="fw-medium">Trade Crypto-Crypto Pairs</li>
                                        <li className="fw-medium">Deposit Crypto</li>
                                    </ul>
                                </div>
                            </div>
                        )}
                        {useKycData?.DocStatus === 2 && (
                            <div className={styles.kyc_congras}>
                                <h5>CONGRATULATIONS !</h5>
                                <p className="mb-0">Your KYC is verified.</p>
                            </div>
                        )}
                        {useKycData?.DocStatus === 3 && (
                            <>
                                <div className={styles.title}>
                                    <h5>KYC Rejected</h5>
                                </div>
                                <div className={styles.kyc_content}>
                                    <p>Please re-start and complete KYC verification.</p>
                                    <div ng-if="userKyc.KycRejectReasons.length > 0">
                                        <span>Your KYC has been rejected due to following reason(s):</span>
                                        <ul>
                                            {useKycData?.KycRejectReasons?.map((item, index) => (
                                                <li className="fw-medium" key={index}>
                                                    {{ item }}
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                    <span>You can still:</span>
                                    <ul>
                                        <li className="fw-medium">Trade Crypto-Crypto Pairs</li>
                                        <li className="fw-medium">Deposit Crypto</li>
                                    </ul>
                                </div>
                            </>
                        )}
                        {isAllowKycComplete && (
                            <div className="form-group">
                                <div className={`${styles.personal_info_btn} text-center d-flex`}>
                                    <button type="button" className={`${styles.button} ${styles.button_border}`} onClick={handleRedirect}>
                                        Go To Profile
                                    </button>
                                    <button type="submit" className={`${styles.button} primary_button`}>
                                        Continue
                                    </button>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </React.Fragment>
    );
}
