import React, { createContext, useContext, useState } from "react";
import { toast } from "react-toastify";
import { GetTokenApi } from "../api/api-service";
import { API_Path } from "../const";

export const AuthContext = createContext();

export default function AuthContextProvider({ children }) {
    const [userCurrentData, setUserCurrentData] = useState();

    const getCurrentUserDetails = () => {
        if (localStorage.getItem("FunexAuthData")) {
            const getCryptoCurrency = new Promise((resolve) => {
                resolve(GetTokenApi(API_Path.GetCurrentUserDetail));
            });
            getCryptoCurrency.then((res) => {
                if (res) {
                    setUserCurrentData(res.data);
                } else {
                    localStorage.removeItem("FunexAuthData");
                    localStorage.removeItem("FunexAuthorizationData");
                    toast.error("Something went wrong!");
                }
            });
        }
    };

    return <AuthContext.Provider value={{ userCurrentData, getCurrentUserDetails }}>{children}</AuthContext.Provider>;
}

export function useAuth() {
    return useContext(AuthContext);
}
