import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ToastContainer } from "react-toastify";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <>
        {/* <React.StrictMode> */}
        <ToastContainer />
        <App />
        {/* </React.StrictMode> */}
    </>
);
reportWebVitals();
