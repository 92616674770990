import React, { useEffect, useState } from "react";
import styles from "../../styles/register.module.css";
import Modal from "react-bootstrap/Modal";
import { API_Path, baseUrl } from "../../const";
import { PostApi } from "../../api/api-service";
import { toast } from "react-toastify";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import EmailVerificationModal from "./ApiverifyModal";
import Loading from "../../components/loader/Loading";

export default function ResendemailVerifyModal() {
    let navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setShowModal(true);
    }, []);

    const handleClose = () => {
        setShowModal(false);
        navigate(-1);
    };
    const ResendVerificationData = (formData, resetForm) => {
        setLoading(true);
        let data = {
            EmailIdOrApi: formData.email,
            BaseUrl: baseUrl,
        };
        const getCryptoCurrency = new Promise((resolve) => {
            resolve(PostApi(API_Path.resendEmailVerify, data));
        });
        getCryptoCurrency.then((res) => {
            if (res) {
                if (res.data.Isvalid === true) {
                    resetForm(formData);
                    toast.success(res.data.Successes[0]);
                } else {
                    toast.error(res.data.Errors[0]);
                }
                setLoading(false);
            }
        });
    };
    return (
        <>
            {loading && <Loading />}
            <EmailVerificationModal />

            <Modal show={showModal} centered className={styles.verify_mail_modal}>
                <Modal.Header className="pb-0">
                    <Modal.Title>
                        Resend Verification <br /> E-mail
                    </Modal.Title>
                    <div onClick={handleClose} className={styles.close_modal_icon}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x-lg" viewBox="0 0 16 16">
                            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8z" />
                        </svg>
                    </div>
                </Modal.Header>

                <Modal.Body className={styles.resend_verify_mail_body}>
                    <p>Please enter your registered e-mail address and click on the link below to receive verification e-mail.</p>
                    <Formik
                        initialValues={{ email: "" }}
                        validationSchema={Yup.object().shape({
                            email: Yup.string().email("Email address is invalid.").required("Email address is required."),
                        })}
                        onSubmit={(values, { resetForm }) => {
                            ResendVerificationData(values, resetForm);
                        }}
                    >
                        {(runform) => (
                            <Form onSubmit={runform.handleSubmit}>
                                <div className={`${styles.inputWidth} mb-3 w-auto`}>
                                    <label className={`${styles.login_label_text} d-block`}>Email Address</label>
                                    <div className="d-block position-relative">
                                        <Field
                                            type="email"
                                            className={` ${styles.login_comn_input} ${runform.errors.email && runform.touched.email && "input-error"}`}
                                            name="email"
                                            placeholder="Enter email address"
                                        />
                                        <span className={styles.login_input_icon} autoComplete="off">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 20 20" fill="none">
                                                <path
                                                    d="M6.66675 3.33337H3.33341C2.41294 3.33337 1.66675 4.07957 1.66675 5.00004V15C1.66675 15.9205 2.41294 16.6667 3.33341 16.6667H16.6667C17.5872 16.6667 18.3334 15.9205 18.3334 15V5.00004C18.3334 4.07957 17.5872 3.33337 16.6667 3.33337H13.3334"
                                                    stroke="#404040"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                                <path
                                                    d="M18.3334 5.83337L10.8584 10.5834C10.6011 10.7446 10.3037 10.83 10.0001 10.83C9.69648 10.83 9.39902 10.7446 9.14175 10.5834L1.66675 5.83337"
                                                    stroke="#404040"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                />
                                            </svg>
                                        </span>
                                    </div>
                                    <ErrorMessage name="email" component="div" className="error-message" />
                                </div>
                                <div className={styles.loginBtn}>
                                    <button type="submit" className="button primary_button w-100" disabled={loading}>
                                        Submit
                                    </button>
                                </div>
                                <p className={`${styles.login_bottomtext} text-start mb-0`}>
                                    E-mail Verified? <Link to="/login">Login</Link>
                                </p>
                            </Form>
                        )}
                    </Formik>
                </Modal.Body>
            </Modal>
        </>
    );
}

// export default function ResendRegistrationCompletedModal() {
//     return (
//        <>
//             <Register />
//        </>
//     );
// }
