import React, { useState } from "react";
import style from "../styles/careers.module.css";
import career_img1 from "../assets/images/careers_img1.svg";
import career_img2 from "../assets/images/careers_img2.svg";
import benefitsImg from "../assets/images/benefitsImg.png";
import careerImg1 from "../assets/images/team_img1.svg";
import careerImg2 from "../assets/images/team_img2.svg";
import careerImg3 from "../assets/images/team_img3.svg";
import careerImg4 from "../assets/images/team_img4.svg";
import careerImg5 from "../assets/images/team_img5.svg";
import careerImg6 from "../assets/images/team_img6.svg";
import careerImg7 from "../assets/images/team_img7.svg";
import careerImg8 from "../assets/images/team_img8.svg";
import careerImg9 from "../assets/images/team_img9.svg";
import careerImg10 from "../assets/images/team_img10.svg";
import careerImg11 from "../assets/images/team_img11.svg";
import careerImg12 from "../assets/images/team_img12.svg";
import careerImg13 from "../assets/images/team_img13.svg";
import careerImg14 from "../assets/images/team_img14.svg";
import careerImg15 from "../assets/images/team_img15.svg";
import careerImg16 from "../assets/images/team_img16.svg";
import { baseName, companyName } from "../const";
import Layout from "../components/Layout/Layout";

export default function Careers() {
    const [isShowMore, setIsShowMore] = useState({
        opportunties: false,
        constant: false,
        authority: false,
        work: false,
        actualization: false,
        broad: false,
        integrity: false,
        power: false,
        driven: false,
        communication: false,
    });
    const handleShowText = (type) => {
        setIsShowMore((prevState) => ({
            ...prevState,
            [type]: !prevState[type],
        }));
    };

    return (
        <Layout>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="title_heading d-flex">
                            <p>Home /</p>
                            <h4>Careers</h4>
                        </div>

                        <div className={style.bannar_section}>
                            <div className={style.bannar_section_left}>
                                <div className={style.title}>
                                    <h2>BUILDING LEADERS IN MODERN TECHNOLOGIES</h2>
                                    <p>
                                        At {companyName.toLowerCase()}, we believe in evolution, be it technological or human. We take our human resources to a higher level of integrity and leadership
                                        as we foresee them excelling at all levels of the company.
                                    </p>
                                    <p>
                                        At {companyName.toLowerCase()}, we constantly motivate our colleagues to enhance their skills & proficiency while enhancing crypto trade and making it a better
                                        experience.
                                    </p>
                                </div>
                            </div>
                            <div className={`${style.bannar_section_right} text-end`}>
                                <div className={style.bannar_section_img}>
                                    <img src={career_img1} alt="" className="img-fluid" />
                                </div>
                            </div>
                        </div>

                        <div className={style.future_section}>
                            <div className={`${style.title} text-center`}>
                                <h2>Be A Part Of Future-Technology</h2>
                                <p>
                                    We don’t just explore, but develop leaders of the next generation.{companyName}’s cutting-edge approach guarantees opportunities for you to soar if you believe in
                                    working smartly because we value your innovative ideas and hard work and put them in the best profitable spot.
                                </p>
                            </div>
                        </div>

                        <div className={style.bannar_section}>
                            <div className="col-lg-5 col-md-12 col-sm-12 text-end">
                                <div className={`${style.bannar_section_img} text-start`}>
                                    <img src={career_img2} alt="" className="img-fluid w-75" />
                                </div>
                            </div>
                            <div className={`${style.Policies_section_left} col-lg-7 col-md-12 col-sm-12`}>
                                <div className={style.title}>
                                    <h2>Our Policies</h2>
                                    <p>Our policies are based ona a single objective, which is to invite, foster and preserve the leadership skills and passionate teams.</p>
                                    <p>We work in an open culture to bring together innovative minds to develop a robust enterprise with the freedom to innovate.</p>
                                </div>
                            </div>
                        </div>

                        <div className={style.ourcore_section}>
                            <div className={`${style.title} text-center`}>
                                <h2>Our Core Values</h2>
                                <p>
                                    our core values are the foundation of everything we do. They guide our actions, shape our culture, and inspire us to deliver exceptional experiences to our clients
                                    and team members.
                                </p>
                            </div>
                            <div className={style.ourcore_section_body}>
                                <ul>
                                    <li className={`${style.ourcore_section_content} ${style.ourcore_box_1}`}>
                                        <h5>
                                            Higher <br /> Opportunities
                                        </h5>
                                        <p>
                                            Higher opportunities originate constantly with the development & expansion of
                                            {isShowMore.opportunties && " our business. We make sure that our human assets seize new prospects."}
                                        </p>
                                        <button onClick={() => handleShowText("opportunties")}>{!isShowMore.opportunties ? "READ MORE" : "READ LESS"}</button>
                                    </li>
                                    <li className={`${style.ourcore_section_content} ${style.ourcore_box_2}`}>
                                        <h5>
                                            Constant <br /> Learning
                                        </h5>
                                        <p>
                                            Constant evolvement is what we believe in, for this, we provide in-house training and
                                            {isShowMore.constant &&
                                                " freedom we make sure that our human assets seize new prospects.to innovate & learn through experiences as well as leaning & inspiration from outside."}
                                        </p>
                                        <button onClick={() => handleShowText("constant")}>{!isShowMore.constant ? "READ MORE" : "READ LESS"}</button>
                                    </li>
                                    <li className={`${style.ourcore_section_content} ${style.ourcore_box_3}`}>
                                        <h5>
                                            Authority & <br /> Responsibility
                                        </h5>
                                        <p>Through empowerment and assignment of responsibilities, we design {isShowMore.authority && " best-in-industry experience for our association as a team."}</p>
                                        <button onClick={() => handleShowText("authority")}>{!isShowMore.authority ? "READ MORE" : "READ LESS"}</button>
                                    </li>
                                    <li className={`${style.ourcore_section_content} ${style.ourcore_box_4}`}>
                                        <h5>
                                            Work & <br />
                                            Fun
                                        </h5>
                                        <p>
                                            We believe in upkeeping a healthy work ambience for our employees to balance
                                            {isShowMore.work && " work and life. Through a casual work environment and regular extra-curricular activities, we keep our colleagues away from humdrum."}
                                        </p>
                                        <button onClick={() => handleShowText("work")}>{!isShowMore.work ? "READ MORE" : "READ LESS"}</button>
                                    </li>
                                    <li className={`${style.ourcore_section_content} ${style.ourcore_box_5}`}>
                                        <h5>Actualization Of Expectations</h5>
                                        <p>
                                            We understand the future expectations of employees to grow intellectually
                                            {isShowMore.actualization && " & financially and so we make sure that our human resource touch top positions in the organization."}
                                        </p>
                                        <button onClick={() => handleShowText("actualization")}>{!isShowMore.actualization ? "READ MORE" : "READ LESS"}</button>
                                    </li>
                                    <li className={`${style.ourcore_section_content} ${style.ourcore_box_1}`}>
                                        <h5>
                                            Broad Outlook & <br /> Big Thinkers
                                        </h5>
                                        <p>
                                            To materialize our mission and vision at the targeted time, we keep a watchful eye
                                            {isShowMore.broad && " and retain big thinkers & doers with a broad outlook."}
                                        </p>
                                        <button onClick={() => handleShowText("broad")}>{!isShowMore.broad ? "READ MORE" : "READ LESS"}</button>
                                    </li>
                                    <li className={`${style.ourcore_section_content} ${style.ourcore_box_2}`}>
                                        <h5>
                                            High <br /> Integrity
                                        </h5>
                                        <p>
                                            {baseName} is dedicated to becoming the most reliable and authentic platform and {isShowMore.integrity && " performing the right & legitimate actions."}
                                        </p>
                                        <button onClick={() => handleShowText("integrity")}>{!isShowMore.integrity ? "READ MORE" : "READ LESS"}</button>
                                    </li>
                                    <li className={`${style.ourcore_section_content} ${style.ourcore_box_3}`}>
                                        <h5>
                                            Power To <br /> Innovate & Invent
                                        </h5>
                                        <p>
                                            {baseName} gives liberty to employees to do experiments with their innovative ideas to
                                            {isShowMore.power && " lay the ground for propelling crypto trade to higher dimensions of success, as we understand that success comes from iteration."}
                                        </p>
                                        <button onClick={() => handleShowText("power")}>{!isShowMore.power ? "READ MORE" : "READ LESS"}</button>
                                    </li>
                                    <li className={`${style.ourcore_section_content} ${style.ourcore_box_4}`}>
                                        <h5>
                                            Results <br /> Driven
                                        </h5>
                                        <p>
                                            At {baseName.toLowerCase()}, we get things done and results achieved. Effective decision making,
                                            {isShowMore.driven && " followed by the fast execution of tasks accordingly makes us result-driven."}
                                        </p>
                                        <button onClick={() => handleShowText("driven")}>{!isShowMore.driven ? "READ MORE" : "READ LESS"}</button>
                                    </li>
                                    <li className={`${style.ourcore_section_content} ${style.ourcore_box_5}`}>
                                        <h5>
                                            Clear <br /> Communication
                                        </h5>
                                        <p>Collaboration requires clear communication; We encourage direct feedback {isShowMore.communication && " and an open mind to self-improvement."}</p>
                                        <button onClick={() => handleShowText("communication")}>{!isShowMore.communication ? "READ MORE" : "READ LESS"}</button>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className={style.your_team_section}>
                            <div className={`${style.title} text-center`}>
                                <h2>Choose Your Team</h2>
                                <p>Select a team most relevant to your interests and experience to view job openings.</p>
                            </div>
                            <div className={style.yourteam_section_body}>
                                <ul>
                                    <li className={style.yourteam_section_content}>
                                        <div className={style.yourteam_section_name}>
                                            <img src={careerImg1} alt="" className="img-fluid" />
                                            <h5>Business Development</h5>
                                        </div>
                                    </li>
                                    <li className={style.yourteam_section_content}>
                                        <div className={style.yourteam_section_name}>
                                            <img src={careerImg2} alt="" className="img-fluid" />
                                            <h5>Communications</h5>
                                        </div>
                                    </li>
                                    <li className={style.yourteam_section_content}>
                                        <div className={style.yourteam_section_name}>
                                            <img src={careerImg3} alt="" className="img-fluid" />
                                            <h5>Customer Care Support</h5>
                                        </div>
                                    </li>
                                    <li className={style.yourteam_section_content}>
                                        <div className={style.yourteam_section_name}>
                                            <img src={careerImg4} alt="" className="img-fluid" />
                                            <h5>Data & Analytics</h5>
                                        </div>
                                    </li>
                                    <li className={style.yourteam_section_content}>
                                        <div className={style.yourteam_section_name}>
                                            <img src={careerImg5} alt="" className="img-fluid" />
                                            <h5>Editorial & Video</h5>
                                        </div>
                                    </li>
                                    <li className={style.yourteam_section_content}>
                                        <div className={style.yourteam_section_name}>
                                            <img src={careerImg6} alt="" className="img-fluid" />
                                            <h5>Engineering</h5>
                                        </div>
                                    </li>
                                    <li className={style.yourteam_section_content}>
                                        <div className={style.yourteam_section_name}>
                                            <img src={careerImg7} alt="" className="img-fluid" />
                                            <h5>Finance & Administration</h5>
                                        </div>
                                    </li>
                                    <li className={style.yourteam_section_content}>
                                        <div className={style.yourteam_section_name}>
                                            <img src={careerImg8} alt="" className="img-fluid" />
                                            <h5>Legal & Compliance</h5>
                                        </div>
                                    </li>
                                    <li className={style.yourteam_section_content}>
                                        <div className={style.yourteam_section_name}>
                                            <img src={careerImg9} alt="" className="img-fluid" />
                                            <h5>Local Marketing</h5>
                                        </div>
                                    </li>
                                    <li className={style.yourteam_section_content}>
                                        <div className={style.yourteam_section_name}>
                                            <img src={careerImg10} alt="" className="img-fluid" />
                                            <h5>Marketing</h5>
                                        </div>
                                    </li>
                                    <li className={style.yourteam_section_content}>
                                        <div className={style.yourteam_section_name}>
                                            <img src={careerImg11} alt="" className="img-fluid" />
                                            <h5>Operations</h5>
                                        </div>
                                    </li>
                                    <li className={style.yourteam_section_content}>
                                        <div className={style.yourteam_section_name}>
                                            <img src={careerImg12} alt="" className="img-fluid" />
                                            <h5>Product & Design</h5>
                                        </div>
                                    </li>
                                    <li className={style.yourteam_section_content}>
                                        <div className={style.yourteam_section_name}>
                                            <img src={careerImg13} alt="" className="img-fluid" />
                                            <h5>Quantitative Strategy</h5>
                                        </div>
                                    </li>
                                    <li className={style.yourteam_section_content}>
                                        <div className={style.yourteam_section_name}>
                                            <img src={careerImg14} alt="" className="img-fluid" />
                                            <h5>Security</h5>
                                        </div>
                                    </li>
                                    <li className={style.yourteam_section_content}>
                                        <div className={style.yourteam_section_name}>
                                            <img src={careerImg15} alt="" className="img-fluid" />
                                            <h5>Talent Acquisition</h5>
                                        </div>
                                    </li>
                                    <li className={style.yourteam_section_content}>
                                        <div className={style.yourteam_section_name}>
                                            <img src={careerImg16} alt="" className="img-fluid" />
                                            <h5>Human Resource</h5>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className={style.benefit_section}>
                            <div className={`${style.title} text-center`}>
                                <h2>Benefits at {baseName.toLowerCase()}</h2>
                            </div>
                            <div className={style.benefit_section_body}>
                                <div className={`${style.benefit_section_content} col-lg-6 col-md-6 col-sm-12`}>
                                    <ul>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30 30" fill="none">
                                                <path
                                                    d="M4.66833 17.0035C5.8611 17.9071 6.31291 18.0336 7.30688 19.389C8.93339 21.6119 8.69845 22.5336 9.1864 22.9493C9.67435 23.3649 11.8792 23.7083 13.3972 21.7746C14.9153 19.8408 17.2647 14.5095 20.2828 11.3107C23.3009 8.11192 24.7105 7.19024 25.6141 6.70228C26.5177 6.21433 23.6442 6.32277 18.0057 10.8047C14.084 13.9312 11.3009 18.2324 11.3009 18.2324C11.3009 18.2324 10.5238 15.883 8.77074 14.3107C6.99966 12.7384 2.68038 15.5035 4.66833 17.0035Z"
                                                    fill="#2FA124"
                                                />
                                            </svg>
                                            <span>Competitive salary & attractive perks</span>
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30 30" fill="none">
                                                <path
                                                    d="M4.66833 17.0035C5.8611 17.9071 6.31291 18.0336 7.30688 19.389C8.93339 21.6119 8.69845 22.5336 9.1864 22.9493C9.67435 23.3649 11.8792 23.7083 13.3972 21.7746C14.9153 19.8408 17.2647 14.5095 20.2828 11.3107C23.3009 8.11192 24.7105 7.19024 25.6141 6.70228C26.5177 6.21433 23.6442 6.32277 18.0057 10.8047C14.084 13.9312 11.3009 18.2324 11.3009 18.2324C11.3009 18.2324 10.5238 15.883 8.77074 14.3107C6.99966 12.7384 2.68038 15.5035 4.66833 17.0035Z"
                                                    fill="#2FA124"
                                                />
                                            </svg>
                                            <span>Option to be paid in popular cryptocurrencies</span>
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30 30" fill="none">
                                                <path
                                                    d="M4.66833 17.0035C5.8611 17.9071 6.31291 18.0336 7.30688 19.389C8.93339 21.6119 8.69845 22.5336 9.1864 22.9493C9.67435 23.3649 11.8792 23.7083 13.3972 21.7746C14.9153 19.8408 17.2647 14.5095 20.2828 11.3107C23.3009 8.11192 24.7105 7.19024 25.6141 6.70228C26.5177 6.21433 23.6442 6.32277 18.0057 10.8047C14.084 13.9312 11.3009 18.2324 11.3009 18.2324C11.3009 18.2324 10.5238 15.883 8.77074 14.3107C6.99966 12.7384 2.68038 15.5035 4.66833 17.0035Z"
                                                    fill="#2FA124"
                                                />
                                            </svg>
                                            <span>Experienced colleagues & teams</span>
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30 30" fill="none">
                                                <path
                                                    d="M4.66833 17.0035C5.8611 17.9071 6.31291 18.0336 7.30688 19.389C8.93339 21.6119 8.69845 22.5336 9.1864 22.9493C9.67435 23.3649 11.8792 23.7083 13.3972 21.7746C14.9153 19.8408 17.2647 14.5095 20.2828 11.3107C23.3009 8.11192 24.7105 7.19024 25.6141 6.70228C26.5177 6.21433 23.6442 6.32277 18.0057 10.8047C14.084 13.9312 11.3009 18.2324 11.3009 18.2324C11.3009 18.2324 10.5238 15.883 8.77074 14.3107C6.99966 12.7384 2.68038 15.5035 4.66833 17.0035Z"
                                                    fill="#2FA124"
                                                />
                                            </svg>
                                            <span>Multiple work locations to choose from</span>
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30 30" fill="none">
                                                <path
                                                    d="M4.66833 17.0035C5.8611 17.9071 6.31291 18.0336 7.30688 19.389C8.93339 21.6119 8.69845 22.5336 9.1864 22.9493C9.67435 23.3649 11.8792 23.7083 13.3972 21.7746C14.9153 19.8408 17.2647 14.5095 20.2828 11.3107C23.3009 8.11192 24.7105 7.19024 25.6141 6.70228C26.5177 6.21433 23.6442 6.32277 18.0057 10.8047C14.084 13.9312 11.3009 18.2324 11.3009 18.2324C11.3009 18.2324 10.5238 15.883 8.77074 14.3107C6.99966 12.7384 2.68038 15.5035 4.66833 17.0035Z"
                                                    fill="#2FA124"
                                                />
                                            </svg>
                                            <span>Company holidays</span>
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30 30" fill="none">
                                                <path
                                                    d="M4.66833 17.0035C5.8611 17.9071 6.31291 18.0336 7.30688 19.389C8.93339 21.6119 8.69845 22.5336 9.1864 22.9493C9.67435 23.3649 11.8792 23.7083 13.3972 21.7746C14.9153 19.8408 17.2647 14.5095 20.2828 11.3107C23.3009 8.11192 24.7105 7.19024 25.6141 6.70228C26.5177 6.21433 23.6442 6.32277 18.0057 10.8047C14.084 13.9312 11.3009 18.2324 11.3009 18.2324C11.3009 18.2324 10.5238 15.883 8.77074 14.3107C6.99966 12.7384 2.68038 15.5035 4.66833 17.0035Z"
                                                    fill="#2FA124"
                                                />
                                            </svg>
                                            <span>Professional learning classes & programs</span>
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30 30" fill="none">
                                                <path
                                                    d="M4.66833 17.0035C5.8611 17.9071 6.31291 18.0336 7.30688 19.389C8.93339 21.6119 8.69845 22.5336 9.1864 22.9493C9.67435 23.3649 11.8792 23.7083 13.3972 21.7746C14.9153 19.8408 17.2647 14.5095 20.2828 11.3107C23.3009 8.11192 24.7105 7.19024 25.6141 6.70228C26.5177 6.21433 23.6442 6.32277 18.0057 10.8047C14.084 13.9312 11.3009 18.2324 11.3009 18.2324C11.3009 18.2324 10.5238 15.883 8.77074 14.3107C6.99966 12.7384 2.68038 15.5035 4.66833 17.0035Z"
                                                    fill="#2FA124"
                                                />
                                            </svg>
                                            <span>Team activities & outings</span>
                                        </li>
                                        <li>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30 30" fill="none">
                                                <path
                                                    d="M4.66833 17.0035C5.8611 17.9071 6.31291 18.0336 7.30688 19.389C8.93339 21.6119 8.69845 22.5336 9.1864 22.9493C9.67435 23.3649 11.8792 23.7083 13.3972 21.7746C14.9153 19.8408 17.2647 14.5095 20.2828 11.3107C23.3009 8.11192 24.7105 7.19024 25.6141 6.70228C26.5177 6.21433 23.6442 6.32277 18.0057 10.8047C14.084 13.9312 11.3009 18.2324 11.3009 18.2324C11.3009 18.2324 10.5238 15.883 8.77074 14.3107C6.99966 12.7384 2.68038 15.5035 4.66833 17.0035Z"
                                                    fill="#2FA124"
                                                />
                                            </svg>
                                            <span>Flexible work hours</span>
                                        </li>
                                    </ul>
                                </div>
                                <div className={`${style.benefit_section_img} col-lg-6 col-md-6 col-sm-12`}>
                                    <img src={benefitsImg} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}
