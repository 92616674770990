import React from 'react';
import style from '../styles/trademark_notices.module.css';
import logo from '../assets/images/trademarklogo.svg';
import { companyName } from '../const.js';
import Layout from '../components/Layout/Layout.js';

export default function TrademarkNotices() {
    return (
        <Layout>
            <section className={style.section}>
                <div className="container">
                    <div className={style.section_body}>
                        <h2>Trademark Notices</h2>
                        <p>{companyName} and the image below are trademarks. All rights reserved.</p>
                        <span></span>
                        {/* <Link to="/"> */}
                            <img src={logo} alt='' className='img-fluid' />
                        {/* </Link> */}
                    </div>
                </div>
            </section>
        </Layout>
    );
}
