import React, { useEffect, useState } from "react";
import styles from "../../styles/asset.module.css";
import { Link } from "react-router-dom";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import { GetTokenApi } from "../../api/api-service";
import { API_Path } from "../../const";
import { toast } from "react-toastify";
import Loading from "../loader/Loading";
import emptyRecordImg from "../../assets/images/empty_record.svg";

// Register the required components

// const pairsPriceData = [];

export default function AssetOverview({ walletName }) {
    const [loading, setLoading] = useState(false);
    const [walletData, setWalletData] = useState([]);
    const [pairsPriceData, setpairsPriceData] = useState([]);

    useEffect(() => {
        getAllWalletAccount();
    }, [walletName]);

    useEffect(() => {
        // const fetchPriceAndRepeat = async () => {
        //     // if (stateCurrentName == stateCurrentName) {
        //     // setLoading(true);
        //     await GetPairPrice();
        //     setTimeout(fetchPriceAndRepeat, 6000); // Schedule the next call
        //     // }
        // };
        // fetchPriceAndRepeat();
        GetPairPrice();
    }, []);

    const GetPairPrice = async () => {
        try {
            const res = await GetTokenApi(API_Path.getPairPrice);
            if (res && res.data) {
                setpairsPriceData(res.data);
            } else {
                toast.error(res.data.Errors);
            }
            setLoading(false);
        } catch (error) {
            console.error("Error:", error);
        }
    };

    const getAllWalletAccount = async () => {
        try {
            const response = await GetTokenApi(API_Path.GetWalletBalance);
            if (response && response.data) {
                const allCoinFilter = walletName.map((item) => {
                    const cryptoItem = response.data.find((crypto) => item.WalletCode === crypto.WalletCode);
                    return cryptoItem ? { ...item, ...cryptoItem } : item;
                });
                const allCoinFilterData = allCoinFilter.filter((item) => response.data.some((cryptoItem) => item.WalletCode === cryptoItem.WalletCode));
                setWalletData(allCoinFilterData);
            }
        } catch (error) {}
    };

    // ----------------------- CONVERT TO USDT

    const calculateBalancesInUSDT = (walletData, pairsPriceData) => {
        const prices = pairsPriceData.reduce((acc, { Base, ClosePrice, Pair, Quote }) => {
            if (Quote === "USDT") {
                acc[Base] = ClosePrice;
            }
            return acc;
        }, {});
        const calculateWalletValue = (balances) => {
            return balances.reduce((total, { Coin, Free }) => {
                const price = prices === undefined ? 0 : prices[Coin];
                if (price) {
                    total += Free * price;
                }
                return total;
            }, 0); // Format to 5 decimal places
        };

        return walletData.map((wallet) => {
            return {
                WalletName: wallet.WalletName,
                TotalValueInUSDT: calculateWalletValue(wallet.Balances),
            };
        });
    };

    const result = calculateBalancesInUSDT(walletData, pairsPriceData);

    const calculateTotalUSDT = (wallets) => {
        return wallets.reduce((sum, wallet) => sum + wallet.TotalValueInUSDT, 0);
    };

    const calculatePercentages = (wallets) => {
        return wallets.map((wallet) => {
            const percentage = (wallet.TotalValueInUSDT / totalUSDT) * 100;
            return {
                ...wallet,
                percentage: Math.round(percentage * 100) / 100, // Keeping the percentage with two decimal points
            };
        });
    };

    const totalUSDT = calculateTotalUSDT(result);
    const resultWithPercentages = calculatePercentages(result);

    const combinedArray = resultWithPercentages.map((item) => {
        const walletDetail = walletName.find((w) => w.WalletName === item.WalletName);
        return { ...item, ...walletDetail };
    });

    // ----------------------- CONVERT TO USDT

    // ----------------------- CONVERT TO BTC

    const calculateBalancesInBTC = (pairsPriceData) => {
        const prices = pairsPriceData.reduce((acc, { Base, ClosePrice, Pair, Quote }) => {
            if (Base === "BTC") {
                acc[Base] = ClosePrice;
            }
            return acc;
        }, {});

        const calculateTotalBTC = totalUSDT && totalUSDT / prices.BTC;
        return calculateTotalBTC;
    };

    const result_BTC = calculateBalancesInBTC(pairsPriceData);

    // ----------------------- CONVERT TO BTC

    const extractPercentage = (percentageString) => {
        return parseFloat(percentageString);
    };
    const transformedData = resultWithPercentages.map((item) => ({
        name: item.WalletName ?? "name",
        y: extractPercentage(item.percentage),
    }));

    const options = {
        colors: transformedData.length === 0 ? ["#8f8f8f"] : ["#F2BD27", "#00c4f4"],
        subtitle: {
            text: `${result_BTC.toFixed(8)} <small> BTC ≈ <br /> ${totalUSDT.toFixed(8)} USDT </small>`,
            verticalAlign: "middle",
        },
        title: {
            text: "Total Balance",
            verticalAlign: "middle",
            floating: true,
            y: -10,
        },
        plotOptions: {
            pie: {
                dataLabels: { enabled: false },
                borderWidth: 0,
            },
        },
        series: [
            {
                type: "pie",
                innerSize: "95%",
                data:
                    transformedData.length === 0
                        ? [
                              {
                                  name: "",
                                  y: 100,
                              },
                          ]
                        : transformedData,
                tooltip: {
                    pointFormat: transformedData.length === 0 && "0.00%",
                },
            },
        ],
        credits: { enabled: false },
    };

    return (
        <>
            {loading && <Loading />}
            <section>
                <div className="container">
                    <div className="d-flex align-items-center">
                        <div className="title_heading">
                            <h4 className="ps-0">My Assets</h4>
                        </div>
                        <div className={styles.view_text_sectico}>
                            <Link to="/useraccount/funds-history-spot">History</Link>
                        </div>
                    </div>
                    <div className={styles.asset_card}>
                        <div className={styles.card_details}>
                            <div className="d-flex align-items-center justify-content-between">
                                <div className={styles.total_balance}>
                                    <div>
                                        <span>Total Equity</span>
                                        <h4>
                                            {result_BTC == "NaN" ? "0.00" : result_BTC.toFixed(8)} <small> BTC ≈ {totalUSDT.toFixed(8)} USDT </small>
                                        </h4>
                                    </div>
                                </div>
                                <div className={styles.today_pl}>
                                    <div className="icon icon__src">
                                        {/* <span className="text_underline d-block">Today’s PnL</span> */}
                                        {/* <b className={totalUSDT >= 0 ? "green_color" : "red_color"}>{totalUSDT}</b> */}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.overview_table}>
                                <div className="row">
                                    <div className="col-lg-7">
                                        {walletData && walletData.length > 0 ? (
                                            <table className={`${styles.table_overview} table mb-0`}>
                                                <tbody>
                                                    {combinedArray
                                                        .filter((item) => item.IsInOverview === true)
                                                        .map((item, i) => (
                                                            <tr className="cursor-pointer" key={i}>
                                                                <td>{item.WalletName}</td>
                                                                <td>{item.percentage}%</td>
                                                                <td>{item.TotalValueInUSDT.toFixed(8)} USDT</td>
                                                                <td>
                                                                    <Link to={`/useraccount/${item.WalletName.toLowerCase()}`}>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                                                            <path
                                                                                d="M4.90188 0.699951L3.92188 1.74995L9.10187 6.99995L3.92188 12.25L4.90188 13.3L11.2019 6.99995L4.90188 0.699951Z"
                                                                                fill="#212121"
                                                                            />
                                                                        </svg>
                                                                    </Link>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                </tbody>
                                            </table>
                                        ) : (
                                            <div className={styles.empty_record}>
                                                <img src={emptyRecordImg} alt="" />
                                                <span className="d-block">No data found</span>
                                            </div>
                                        )}
                                    </div>
                                    {/* {walletData && walletData.length > 0 && ( */}
                                    <div className={`${styles.chart_style} col-lg-5`}>
                                        <HighchartsReact highcharts={Highcharts} options={options} />
                                    </div>
                                    {/* )} */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
