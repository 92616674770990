import "./App.css";
import RoutesMain from "./RoutesMain";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import AOS from "aos";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect } from "react";
import "./styles/style.css";
import AuthContextProvider from "./context/AuthContext";

function App() {
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
    return (
        <AuthContextProvider>
            <RoutesMain />
        </AuthContextProvider>
    );
}

export default App;
