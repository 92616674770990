import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { Link, useNavigate } from "react-router-dom";
import styles from "../styles/home.module.css";
import Layout from "../components/Layout/Layout";

import bannerImg from "../assets/images/banner-01.png";
import mexcImg from "../assets/images/mexc.svg";
import alchemyImg from "../assets/images/alchemy.svg";
import dashImg from "../assets/images/dash.svg";
import busdImage from "../assets/images/busd_logo_black_flushleft 1.svg";

import unbeatableImg from "../assets/images/unbeatable.png";
import trustedImg from "../assets/images/trusted.png";
import secureImg from "../assets/images/secure.png";
import globalImg from "../assets/images/global.png";
import kycImg from "../assets/images/kyc.png";
import BestAPIImg from "../assets/images/BestAPI.png";
import hotListImg from "../assets/images/hot_flame_icon.svg";

import playstoreImg from "../assets/images/playstore_comingsoon.png";
import appstoreImg from "../assets/images/appstore_comingsoon.png";
// import playstoreImg from '../assets/images/playstore.svg';
// import appstoreImg from '../assets/images/appstore.svg';

import mobileImg from "../assets/images/mobile.png";
import tradingCryptoImg from "../assets/images/tradingCrypto.png";

import registerImg from "../assets/images/register.svg";
import fundImg from "../assets/images/fund.svg";
import tradeImg from "../assets/images/trade.svg";
import Table from "react-bootstrap/esm/Table";
import { settings } from "../components/common/CommonFun";
import io from "socket.io-client";

import { API_Path, baseName, companyName, socketUrl, supportUrl } from "../const";
import { GetApi, PostTokenApi } from "../api/api-service";
import NoDataFoundImg from "../components/nodatafound/NoDataFoundImg";
import { toast } from "react-toastify";

const socket = io.connect(socketUrl);
let btcvol = 0,
    usdvol = 0;

export default function Home() {
    const navigate = useNavigate();
    const allowMainMarketPairs = ["BTCUSDT", "ETHUSDT", "LTCUSDT", "BCHUSDT", "DOGEUSDT", "DASHUSDT", "LINKUSDT", "BTCINR", "ETHINR", "TTNINR", "BATBTC", "BNBBTC", "DOGEBTC", "LINKBTC", "ZRXBTC"];

    const [loading, setLoading] = useState(false);

    const [activeTab, setActiveTab] = useState("BTC");
    const [isShowMore, setIsShowMore] = useState({
        pricing: false,
        trusted: false,
        secure: false,
        globle: false,
        kyc: false,
        security: false,
    });
    const [btcVolume, setBTCVolume] = useState(0);
    const [usdVolume, setUSDVolume] = useState(0);
    const [marketTabs, setMarketTabs] = useState([]);
    const [markets, setMarkets] = useState([]);
    const [mainMarketPairs, setMainMarketPairs] = useState([]);
    const [getDataLocalStorage, setgetDataLocalStorage] = useState({});
    const [hotList, setHotList] = useState(null);

    let initialized = false;

    useEffect(() => {
        setgetDataLocalStorage({
            firstCoin: localStorage.getItem("FirstCoin") ?? "BTC",
            baseCoin: localStorage.getItem("BaseCoin") ?? "USDT",
            isLogin: localStorage.getItem("FunexAuthorizationData") ?? false,
        });
        const favoritePairs = JSON.parse(localStorage.getItem("favoritePairs"));
        setHotList(favoritePairs ?? []);
    }, []);

    useEffect(() => {
        if (!initialized) {
            initialized = true;
            getMarketMainCoin(); //Call methods here
        }
    }, [activeTab]);

    useEffect(() => {
        socket.emit("SendMarket");
        socket.on("ReceiveMarket", getMarketData);
        return () => {
            socket.off("ReceiveMarket", getMarketData);
        };
    }, [activeTab, hotList]);

    const setMainMarketPair = (markets) => {
        const pairMarketData = markets.filter((item) => allowMainMarketPairs.includes(item.PairSymbol));
        const mainMarkets = Object.entries(pairMarketData).map(([PairSymbol, market]) => ({
            PairSymbol,
            ...market,
        }));

        if (mainMarkets.length > 0) {
            if (mainMarketPairs.length <= 0) {
                const pairs = mainMarkets.map((item) => ({
                    PairSymbol: item.PairSymbol,
                    CoinSymbol: item.CoinSymbol,
                    CurrencySymbol: item.CurrencySymbol,
                    CoinImage: item.CoinImage,
                    PercentClass: item.PercentClass,
                    ChangePercent: item.ChangePercent,
                    LastPrice: item.LastPrice,
                    priceDecimal: item.priceDecimal,
                }));
                setMainMarketPairs(pairs);
            } else {
                const updatedPairs = mainMarketPairs.map((item) => {
                    const obj = mainMarkets.find((x) => x.PairSymbol === item.PairSymbol);
                    if (obj) {
                        return {
                            ...item,
                            CoinSymbol: obj.CoinSymbol,
                            CurrencySymbol: obj.CurrencySymbol,
                            CoinImage: obj.CoinImage,
                            PercentClass: obj.PercentClass,
                            ChangePercent: obj.ChangePercent,
                            LastPrice: obj.LastPrice,
                            priceDecimal: obj.priceDecimal,
                        };
                    }
                    return item;
                });
                setMainMarketPairs(updatedPairs);
            }
        }
    };

    const getMarketMainCoin = () => {
        const marketMainCoin = new Promise((resolve) => {
            resolve(GetApi(API_Path.GetMarketMainCoin));
        });
        marketMainCoin.then((response) => {
            if (response) {
                if (response.data.length > 0) {
                    const pairLastNames = Array.from(new Set(response.data.map((item) => item.LAST)));
                    const updatedMarketTabs = pairLastNames.map((item, index) => {
                        const lastCoinImageUrl = response.data.find((x) => x.LAST.toLowerCase() === item.toLowerCase()).LastCoinImageUrl;
                        return {
                            Id: index,
                            LastName: item,
                            TabTitle: item,
                            CoinImageUrl: lastCoinImageUrl,
                        };
                    });
                    setMarketTabs(updatedMarketTabs);
                }
            }
            // else {
            //     toast.error("Something went wrong!");
            // }
        });
    };

    const getMarketData = (data) => {
        setLoading(true);
        try {
            let tempMarkets = [];
            let MarketData = [];
            let list = [];

            if (data && data.length > 0) {
                tempMarkets = data.map((marketItem) => ({
                    PairSymbol: marketItem.PairName,
                    ShowPairSymbol: `${marketItem.First}/${marketItem.Last}`,
                    CoinSymbol: marketItem.First,
                    CurrencySymbol: marketItem.Last,
                    LastPrice: marketItem.Price,
                    Change: marketItem.TwentyHRPriceChangePrice,
                    ChangePercent: marketItem.TwentyHRPriceChangePer,
                    Volume: parseFloat(marketItem.TwentyHRVol),
                    High: marketItem.TwentHRHigh,
                    Low: marketItem.TwentHRLow,
                    PercentClass: "",
                    Amount: 0,
                    IsFavourite: false,
                    SocketPair: marketItem.FULLFORM,
                    CoinImage: marketItem.coinImage,
                    currentPriceClass: marketItem.CurrentPriceChangeClass,
                    Port: marketItem.port,
                    priceDecimal: marketItem.PRICE_PRECISION,
                    volumeDecimal: marketItem.VOLUME_PRECISION,
                    TwentyHRAvgPrice: marketItem.TwentyHRAvgPrice,
                    BTC_VOL: parseFloat(marketItem.BTC_VOL),
                    USD_VOL: parseFloat(marketItem.USD_VOL),
                }));
                MarketData.push(tempMarkets);

                tempMarkets.forEach((marketItem) => {
                    btcvol += marketItem.BTC_VOL;
                    usdvol += marketItem.USD_VOL;
                });
                setBTCVolume(btcvol.toFixed(8));
                setUSDVolume(usdvol.toFixed(8));
                const pairNames = data
                    .filter((x) => x.Last.toLowerCase() === activeTab.toLowerCase())
                    .map((item) => item.PairName.toLowerCase())
                    .filter((value, index, self) => self.indexOf(value) === index);
                list = tempMarkets.filter((x) => {
                    return pairNames.includes(x.PairSymbol.toLowerCase());
                });
                list.sort((a, b) => {
                    if (a.CoinSymbol < b.CoinSymbol) return -1;
                    if (a.CoinSymbol > b.CoinSymbol) return 1;
                    return 0;
                });
                if (activeTab === "hotList") {
                    const hotListSymbols = hotList.map((item) => item.PairSymbol);
                    const filteredMarkets = tempMarkets.filter((market) => hotListSymbols.includes(market.PairSymbol));
                    const combinedMarkets = [...filteredMarkets];
                    setMarkets(combinedMarkets);
                } else {
                    setMarkets(list);
                }
                setMainMarketPair(tempMarkets);
            }
            setLoading(false);
        } catch (e) {
            console.error(e);
            setLoading(false);
        }
    };

    const handleShowText = (type) => {
        setIsShowMore((prevState) => ({
            ...prevState,
            [type]: !prevState[type],
        }));
    };

    const handleTabClick = (tabKey) => {
        setActiveTab(tabKey);
        setMarkets([]);
    };

    const setUserAllFavouritePairs = (first, last, flag) => {
        let data = { First: first, Last: last };
        const UserFavouritePairs = new Promise((resolve) => {
            resolve(PostTokenApi(flag === "addFavourite" ? API_Path.AddUserFavouritePairs : API_Path.RemoveFavouritePairs, data));
        });
        UserFavouritePairs.then((res) => {
            if (!res) toast.error("Something went wrong!");
        });
    };

    const handleFavoriteCoin = (id) => {
        const alreadyLiked = hotList.some((likedItem) => likedItem.id === id);
        if (!alreadyLiked) {
            const likedItem = markets.find((item) => item.PairSymbol === id);
            const favData = {
                Coin: likedItem.CoinSymbol,
                Currency: likedItem.CurrencySymbol,
                PairSymbol: likedItem.PairSymbol,
            };
            setHotList([...hotList, favData]);
            if (localStorage.getItem("FunexAuthorizationData")) setUserAllFavouritePairs(likedItem.FirstSymbol, likedItem.LastSymbol, "addFavourite");
        }
    };
    useEffect(() => {
        if (hotList) localStorage.setItem("favoritePairs", JSON.stringify(hotList));
    }, [hotList]);

    const handleDisFavoriteCoin = (id) => {
        const newLikedCoins = hotList.filter((coin) => coin.PairSymbol !== id);
        console.log(newLikedCoins, "newLikedCoins");
        setHotList(newLikedCoins);
        if (localStorage.getItem("FunexAuthorizationData")) {
            const likedItem = hotList.find((item) => item.PairSymbol === id);
            setUserAllFavouritePairs(likedItem.FirstSymbol, likedItem.LastSymbol, "removeFavourite");
        }
    };
    const handleRedirect = (coin, basecoin) => {
        localStorage.setItem("FirstCoin", coin.toUpperCase());
        localStorage.setItem("BaseCoin", basecoin.toUpperCase());
        localStorage.setItem("RunningPair", coin.toUpperCase() + basecoin.toUpperCase());
        navigate(`/trading/advance/${coin.toUpperCase()}_${basecoin.toUpperCase()}`);
    };
    const handleTradeButton = (coin, basecoin) => {
        if (getDataLocalStorage.isLogin) navigate(`/trading/advance/${coin.toUpperCase()}_${basecoin.toUpperCase()}`);
        else navigate("/login");
    };
    const parseFloatAmt = (amt, size) => {
        return parseFloat(amt).toFixed(size);
    };

    return (
        <Layout>
            {/* --------- BANNER SECTION -- START --------- */}
            <section className={styles.banner}>
                <div className="container">
                    <div className="col-12">
                        <div className="row">
                            <div className={`${styles.banner_col} col-6`}>
                                <div className={styles.banner_container} data-aos="fade-right" data-aos-duration="2000">
                                    <h1>Exchange is the most affordable way to trade cryptocurrencies</h1>
                                    <h3>Trade, Invest &amp; Spend Cryptocurrency Of Your Choice</h3>
                                    <div className={styles.bannerdflex}>
                                        <button type="button" onClick={() => handleRedirect(getDataLocalStorage.firstCoin, getDataLocalStorage.baseCoin)} className="button primary_button">
                                            Trade Now
                                        </button>
                                    </div>
                                </div>
                                <div className={styles.banner_end}>
                                    <h5>Our Partner</h5>
                                    <div className={styles.banner_icon}>
                                        <img src={busdImage} alt="" className="img-fluid"></img>
                                        <img src={mexcImg} alt="" className="img-fluid"></img>
                                        <img src={alchemyImg} alt="" className="img-fluid"></img>
                                        <img src={dashImg} alt="" className="img-fluid"></img>
                                    </div>
                                </div>
                            </div>
                            <div className={`${styles.banner_img} col-6`} data-aos="fade-up" data-aos-duration="3000">
                                <img src={bannerImg} alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* --------- BANNER SECTION -- END ----------- */}

            {/* --------- COIN SECTION -- START --------- */}
            {mainMarketPairs.length > 0 && (
                <section className={`${styles.h_coin_price} ${styles.section_spese}`}>
                    <div className="container">
                        <div className={styles.h_coin_priceflex}>
                            <Slider {...settings}>
                                {mainMarketPairs.map((item, i) => (
                                    <div className={styles.h_icon_width} key={i}>
                                        <div className={styles.h_coin_price_flex}>
                                            <img alt={item.CoinSymbol} src={item.CoinImage} />
                                            <span>{parseFloatAmt(item.LastPrice, item.priceDecimal)}</span>
                                        </div>
                                        <div className={styles.h_coin_text}>
                                            <h5>
                                                {item.CoinSymbol} <br /> <span>{item.CurrencySymbol}</span>
                                            </h5>
                                            <b className={item.ChangePercent >= 0 ? "green_color" : "red_color"}>{`${item.ChangePercent}%`}</b>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </section>
            )}
            {/* --------- COIN SECTION -- END ----------- */}

            {/* ------------------- MARKET UPDATE -- START --------------------- */}
            <section className={`${styles.market_section} ${styles.section_spese}`}>
                <div className="container">
                    <div className={styles.heading_text}>
                        <h4>Market Update</h4>
                    </div>
                    <div className={styles.market_head}>
                        <div className={styles.market_tab}>
                            <ul className={`${styles.tab} ${styles.navtabs}`}>
                                <li className={styles.navitem}>
                                    <Link className={`${styles.navlink} ${activeTab === "hotList" ? styles.active : ""} ms-0 d-flex`} aria-current="page" onClick={() => handleTabClick("hotList")}>
                                        <img src={hotListImg} alt="" />
                                        Hot List
                                    </Link>
                                </li>
                                {marketTabs.map((item, i) => (
                                    <li className={styles.navitem} key={i}>
                                        <Link className={`${styles.navlink} ${activeTab === item.TabTitle ? styles.active : ""}`} onClick={() => handleTabClick(item.TabTitle)}>
                                            <img src={item.CoinImageUrl} alt="" />
                                            {item.TabTitle}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className={styles.market_table_body}>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Markets</th>
                                    <th>Last Price</th>
                                    <th>24h Change</th>
                                    <th>24h Vol{activeTab !== "hotList" && `(${activeTab})`}</th>
                                    <th>24h High{activeTab !== "hotList" && `(${activeTab})`}</th>
                                    <th>24h Low{activeTab !== "hotList" && `(${activeTab})`}</th>
                                    <th>Action</th>
                                </tr>
                            </thead>

                            {loading ? (
                                <div className="loading_wave">
                                    <div className="loading_bar"></div>
                                    <div className="loading_bar"></div>
                                    <div className="loading_bar"></div>
                                    <div className="loading_bar"></div>
                                </div>
                            ) : markets && markets.length > 0 ? (
                                <tbody className="cursor-pointer">
                                    {markets.map((items, i) => (
                                        // var coin = a ctiveTab === "hotList" ? items.FirstSymbol : items.CoinSymbol;
                                        <tr key={i} onClick={() => handleRedirect(items.CoinSymbol, items.CurrencySymbol)}>
                                            <td>
                                                {hotList.some((likedItem) => likedItem.PairSymbol === items.PairSymbol) ? (
                                                    <span
                                                        className={styles.dislike}
                                                        onClick={(e) => {
                                                            handleDisFavoriteCoin(items.PairSymbol);
                                                            e.stopPropagation();
                                                        }}
                                                    >
                                                        ★
                                                    </span>
                                                ) : (
                                                    <span
                                                        className={styles.like}
                                                        onClick={(e) => {
                                                            handleFavoriteCoin(items.PairSymbol);
                                                            e.stopPropagation();
                                                        }}
                                                    >
                                                        ☆
                                                    </span>
                                                )}
                                                <img src={items.CoinImage} alt="" />
                                                <span>
                                                    {items.CoinSymbol} <bdi>{items.CurrencySymbol}</bdi>
                                                </span>
                                            </td>
                                            <td>{parseFloatAmt(items.LastPrice, items.priceDecimal)}</td>
                                            <td className={items.ChangePercent >= 0 ? "green_color" : "red_color"}>
                                                {parseFloatAmt(items.Change, items.priceDecimal)}({items.ChangePercent}%)
                                            </td>
                                            <td>{parseFloatAmt(items.Volume, items.volumeDecimal)}</td>
                                            <td>{parseFloatAmt(items.High, items.priceDecimal)}</td>
                                            <td>{parseFloatAmt(items.Low, items.priceDecimal)}</td>
                                            <td>
                                                <button
                                                    type="button"
                                                    onClick={(e) => {
                                                        handleTradeButton(items.CoinSymbol, items.CurrencySymbol);
                                                        e.stopPropagation();
                                                    }}
                                                >
                                                    Trade
                                                </button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            ) : (
                                markets.length === 0 && <NoDataFoundImg bordered="true" />
                            )}
                        </Table>
                    </div>
                        <div className={styles.volume_sticky}>
                            <p className="pt-3 pb-3 abc">
                                Total Volume (24hr) :{" "}
                                <b className="ng-binding">
                                    {btcVolume} <span>BTC</span>
                                </b>{" "}
                                ≈{" "}
                                <b className="ng-binding">
                                    {usdVolume} <span>USD</span>
                                </b>
                            </p>
                        </div>
                </div>
            </section>
            {/* ------------------- MARKET UPDATE -- END ----------------------- */}

            {/* ------------------- FEATURES  -- START --------------------- */}
            <section className={`${styles.feature_section} ${styles.section_spese}`}>
                <div className="container">
                    <div className={styles.heading_text}>
                        <h4>Our Features</h4>
                        <p>
                            We're the most trusted place for people <br /> and businesses to buy, sell, and manage crypto.
                        </p>
                    </div>
                    <div className={`row ${styles.feature_style}`}>
                        <div className="col-12 col-md-6 col-lg-4">
                            <div className={`${styles.feature_body}`}>
                                <img src={unbeatableImg} alt="" className="img-fluid" />
                                <h6>UNBEATABLE PRICING</h6>
                                <p>
                                    We are pioneering with the concept of flat fees for cryptocurrency trading
                                    {isShowMore.pricing
                                        ? " on funex. Our unbeatable and transparent pricing shall ensure that a trader can take advantage of every small move in the markets. Flat fees and no hidden charges"
                                        : "..."}
                                </p>
                                <button onClick={() => handleShowText("pricing")}>{isShowMore.pricing ? "READ LESS" : "READ MORE"}</button>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <div className={`${styles.feature_body}`}>
                                <img src={trustedImg} alt="" className="img-fluid" />
                                <h6>TRUSTED PLATFORM</h6>
                                <p>
                                    Enjoy trading in digital assets and cryptocurrencies on our secure, ultra-
                                    {isShowMore.trusted
                                        ? "fast flagship trading platform with streaming market data, advanced charts and an elegant UI. Trade seamlessly on your Android and iOS devices."
                                        : "..."}
                                </p>
                                <button onClick={() => handleShowText("trusted")}>{isShowMore.trusted ? "READ LESS" : "READ MORE"}</button>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <div className={`${styles.feature_body}`}>
                                <img src={secureImg} alt="" className="img-fluid" />
                                <h6>SECURE DIGITAL ASSETS</h6>
                                <p>
                                    At {baseName.toLowerCase()}, we ensure security of your cryptocurrency and digital assets
                                    {isShowMore.secure
                                        ? " by storing almost all the crypto and digital assets in secure off-line storage. We have been consistently rated amongst the best and most secure cryptocurrency trading platforms."
                                        : "..."}
                                </p>
                                <button onClick={() => handleShowText("secure")}>{isShowMore.secure ? "READ LESS" : "READ MORE"}</button>
                            </div>
                        </div>
                        <div className="col-12  col-md-6 col-lg-4">
                            <div className={styles.feature_body}>
                                <img src={globalImg} alt="" className="img-fluid" />
                                <h6>GLOBAL 24X7 SUPPORT</h6>
                                <p>
                                    Need help? No matter what, we are always here for you. Our global customer support
                                    {isShowMore.globle ? (
                                        <>
                                            team is here 24x7x365 to help you with your concerns, answer your trading queries and resolve transaction related issues. Join {baseName.toLowerCase()}{" "}
                                            <Link to="/community">Community</Link> or visit our{" "}
                                            <Link to={`${supportUrl}/`} target="_blank">
                                                Support Center.
                                            </Link>
                                        </>
                                    ) : (
                                        "..."
                                    )}
                                </p>
                                <button onClick={() => handleShowText("globle")}>{isShowMore.globle ? "READ LESS" : "READ MORE"}</button>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <div className={`${styles.feature_body} ${styles.feature_body_five}`}>
                                <img src={kycImg} alt="" className="img-fluid" />
                                <h6>REAL TIME KYC</h6>
                                <p>Our ai powered robust customer identity verification systems ensure a seamless.</p>
                                {/* <button onClick={() => handleShowText("kyc")}>READ MORE</button> */}
                            </div>
                        </div>
                        <div className="col-12 col-md-6 col-lg-4">
                            <div className={styles.feature_body}>
                                <img src={BestAPIImg} alt="" className="img-fluid" />
                                <h6>CATEGORY-BEST APIS</h6>
                                <p>
                                    Build full-fledged trading systems or develop and deploy your own trading bots.
                                    {isShowMore.security ? (
                                        <>
                                            Our robust and secure trading APIs are designed to offer seamless access to our platform. Access{" "}
                                            <Link to="https://exchange-api.funexcoin.com/" target="_blank">
                                                {baseName} API Documentation
                                            </Link>
                                            .
                                        </>
                                    ) : (
                                        ".."
                                    )}
                                </p>
                                <button onClick={() => handleShowText("security")}>{isShowMore.security ? "READ LESS" : "READ MORE"}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* ------------------- FEATURES -- END ------------------------ */}

            {/* ------------------- Money & Invest -- START --------------------- */}
            <section className={styles.money_invest} data-aos="fade-up" data-aos-duration="1000">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className={styles.heading_text}>
                                <h4 className="mb-4">
                                    Free Your Money & Invest <br /> With Confident
                                </h4>
                                <p>
                                    We've created the mobile tools you need for trading <br /> on the go.
                                </p>
                                <div className={styles.app_download}>
                                    <a href="" className={`${styles.coming_soon_btn} ${styles.rot} cursor-pointer`} >
                                        <img src={playstoreImg} alt="" className="img-fluid ps-0" />
                                    </a>
                                    <a href="" className="cursor-pointer">
                                        <img src={appstoreImg} alt="" className="img-fluid" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className={`${styles.scan_mobile_body} text-end`}>
                                <img src={mobileImg} alt="" className="img-fluid" />
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* ------------------- Money & Invest -- END ------------------------ */}

            {/* ------------------- TRADING CRYPTO -- START --------------------- */}
            <section className={`${styles.trading_crypto} ${styles.section_spese}`} data-aos="fade-up" data-aos-duration="1000">
                <div className="container">
                    <div className={`${styles.heading_text} mb-5`}>
                        <h4 className="mb-4 text-center">
                            Start Trading On <br /> {baseName} Crypto Exchange Today
                        </h4>
                        {/* <p>
                            Powerful analytical tools with the safety <br /> and security of {baseName} deliver the ultimate trading experience.
                        </p> */}
                    </div>
                    <div className={styles.trad_number}>
                        <ul className={styles.trad_num_style}>
                            <li className={styles.approved}></li>
                            <li className={styles.approved}></li>
                            <li className={styles.approved}></li>
                        </ul>
                    </div>
                    <div className={styles.trad_flex}>
                        <div className={styles.trad_step_body}>
                            <img src={registerImg} alt="" className="img-fluid" />
                            <h6>Register</h6>
                            <p>Choose the right account type and verify your identity.</p>
                        </div>
                        <div className={styles.trad_step_body}>
                            <img src={fundImg} alt="" className="img-fluid" />
                            <h6>Fund</h6>
                            <p>Use your debit or credit card and a range of global bank deposit methods.</p>
                        </div>
                        <div className={styles.trad_step_body}>
                            <img src={tradeImg} alt="" className="img-fluid" />
                            <h6>Trade</h6>
                            <p>Buy, sell and convert major cryptocurrencies and digital assets.</p>
                        </div>
                    </div>
                    <div className={styles.trad_btns}>
                        <Link to={`${supportUrl}/sendtickets`} target="_blank">
                            <button className="button secondary_button me-3">Contact Us</button>
                        </Link>
                        <Link to="/register">
                            <button className="button primary_button">Sign Up For Free</button>
                        </Link>
                    </div>
                </div>
            </section>
            {/* ------------------- TRADING CRYPTO -- END ----------------------- */}

            {/* ------------------- TRADING -- START --------------------- */}
            <section className={styles.trading_step}>
                <div className="container">
                    <div className={styles.trading_body}>
                        <h4>Start Your Journey <br /> With Us !</h4>
                        <p className="mb-4">With the {companyName.toLowerCase()} app and website, trading has <br /> never been easier.</p>
                        <Link to="/register">
                            <button className="button primary_button">Sign Up Now</button>
                        </Link>
                    </div>
                </div>
            </section>
            {/* --------------------- TRADING -- END --------------------- */}
        </Layout>
    );
}
