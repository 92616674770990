import React, { useEffect, useState } from "react";
import styles from "../../styles/profile.module.css";
import { GetTokenApi } from "../../api/api-service";
import { API_Path } from "../../const";
import { toast } from "react-toastify";
import Loading from "../loader/Loading";
import moment from "moment";
import DataTableComponents from "../data_Table/DataTableComponents";
import { exportHandlePrint, exportTableToExcel, exportTableToPDF, maxDateString } from "../common/CommonFun";
import { Table } from "react-bootstrap";
import NoDataFoundImg from "../nodatafound/NoDataFoundImg";

export default function UserActivity() {
    const [userActivityData, setUserActivityData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState({
        fromDate: "",
        todate: "",
    });
    const [totleRecode, setTotleRecode] = useState(25);
    const [searchQuery, setSearchQuery] = useState("");

    useEffect(() => {
        setLoading(true);
        if (date.fromDate && date.todate) GetUserActivityHistoryData(1, 25);
    }, []);

    useEffect(() => {
        if (date.fromDate == "" && date.todate == "") GetUserActivityHistoryData(1, 25);
    }, [date.fromDate == "" && date.todate == ""]);

    const filteredActivityData = userActivityData.filter((item) => item.location.toLowerCase().includes(searchQuery.toLowerCase()) || item.act_ip.toLowerCase().includes(searchQuery.toLowerCase()));

    const GetUserActivityHistoryData = (page, rowPerPage) => {
        var queryString = `q={PageNo:${page},PageSize:${rowPerPage},SortColumn:"ent_date",SortDir:"desc",SearchValue:""}`;
        const originalDate = moment(date.fromDate);
        const todateDate = moment(date.todate);
        if (date.fromDate) queryString += `&fromDate=${originalDate.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')}`;
        if (date.todate) queryString += `&toDate=${todateDate.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')}`;
        const UserActivityHistoryData = new Promise((resolve) => {
            resolve(GetTokenApi(API_Path.getUserActivityHistory + queryString));
        });
        UserActivityHistoryData.then((res) => {
            if (res) {
                setUserActivityData(res.data[0]);
                setTotleRecode(res.data[1][0].total_count);
            } else {
                toast.error(res.statusText);
            }
            setLoading(false);
        });
    };

    const columns = [
        {
            name: "Created On",
            selector: (row) => row.ent_date,
            sortable: true,
            cell: (row) => <div>{moment(row.ent_date).format("DD/MM/YYYY h:mm:ss A")}</div>,
        },
        { name: "IP Address", selector: (row) => row.act_ip },
        { name: "Location", selector: (row) => row.location },
        { name: "Activity", selector: (row) => row.activity },
        { name: "Access Device", selector: (row) => row.user_agent },
    ];

    const handleSerach = () => GetUserActivityHistoryData(1, 25);
    const handleReset = () => setDate({ fromDate: "", todate: "" });

    const handleFromDateChange = (e) => setDate({ ...date, fromDate: e.target.value });
    const handleToDateChange = (e) => setDate({ ...date, todate: e.target.value });

    return (
        <React.Fragment>
            {loading && <Loading />}
            <div className={`${styles.personal_info_body}`}>
                <div className={`${styles.filter_flex} flex-wrap filter-head`}>
                    <div className={styles.title}>
                        <h6>User Activity</h6>
                    </div>
                    <div className="filter-main">
                        <ul className={`${styles.adv_filter} mb-0 pb-0`}>
                            <li className={styles.date_filter_main}>
                                <div className={styles.date_filter}>
                                    <label>From</label>
                                    <div className={styles.input_box}>
                                        <input
                                            type="date"
                                            className={`${styles.form_input} rounded-0`}
                                            value={date.fromDate}
                                            onChange={(e) => handleFromDateChange(e)}
                                            max={date.todate ? date.todate : maxDateString}
                                        />
                                    </div>
                                </div>
                            </li>
                            <li className={styles.date_filter_main}>
                                <div className={styles.date_filter}>
                                    <label>To</label>
                                    <div className={styles.input_box}>
                                        <input
                                            type="date"
                                            className={`${styles.form_input} rounded-0`}
                                            value={date.todate}
                                            onChange={(e) => handleToDateChange(e)}
                                            min={date.fromDate}
                                            max={maxDateString}
                                        />
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div className="form-group">
                            <div className={`${styles.personal_info_btn} text-center d-flex py-0 justify-content-end`}>
                                <button type="button" className={`${styles.button} primary_button me-3`} onClick={handleSerach} disabled={date.fromDate == "" && date.todate == ""}>
                                    Search
                                </button>
                                <button type="button" className={`${styles.button} primary_button`} onClick={handleReset}>
                                    Reset
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className={styles.icon_search_Section}>
                        <div className="col-lg-6 col-md-6 col-sm-6 mb-2">
                            <div className={styles.section_flex}>
                                <ul>
                                    <li className={`${styles.info_section_icon} ms-0`}>
                                        <button
                                            type="button"
                                            className={styles.btn_dt}
                                            disabled={filteredActivityData == 0}
                                            onClick={() => exportTableToPDF(filteredActivityData, columns, "user_activity_pdf", "Activity History")}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <path
                                                    d="M7.053 10.1751C7.053 9.79512 6.789 9.56812 6.3235 9.56812C6.1335 9.56812 6.005 9.58662 5.9375 9.60462V10.8251C6.0175 10.8431 6.1155 10.8496 6.25 10.8496C6.7465 10.8496 7.053 10.5981 7.053 10.1751ZM9.936 9.58012C9.7275 9.58012 9.5925 9.59862 9.5125 9.61712V12.3211C9.5925 12.3396 9.721 12.3396 9.8375 12.3396C10.6835 12.3456 11.2355 11.8796 11.2355 10.8926C11.242 10.0341 10.739 9.58012 9.936 9.58012Z"
                                                    fill="#404040"
                                                />
                                                <path
                                                    d="M15.459 7.99162H15.12V6.35612C15.1197 6.34572 15.1187 6.33537 15.117 6.32511C15.1175 6.25934 15.0943 6.19558 15.0515 6.14562L12.33 3.03762L12.328 3.03562C12.2934 2.99811 12.2494 2.97064 12.2005 2.95612L12.1855 2.95112C12.1647 2.94594 12.1434 2.94325 12.122 2.94312H5.4335C5.128 2.94312 4.88 3.19162 4.88 3.49662V7.99162H4.541C4.104 7.99162 3.75 8.34562 3.75 8.78262V12.8966C3.75 13.3331 4.1045 13.6876 4.541 13.6876H4.88V16.5041C4.88 16.8091 5.128 17.0576 5.4335 17.0576H14.5665C14.8715 17.0576 15.12 16.8091 15.12 16.5041V13.6876H15.459C15.896 13.6876 16.25 13.3331 16.25 12.8966V8.78262C16.25 8.34562 15.8955 7.99162 15.459 7.99162ZM5.4335 3.49662H11.845V6.32861C11.845 6.48162 11.969 6.60512 12.122 6.60512H14.567V7.99162H5.4335V3.49662ZM12.2285 10.8616C12.2285 11.6526 11.9405 12.1986 11.541 12.5356C11.106 12.8971 10.444 13.0691 9.6345 13.0691C9.15 13.0691 8.807 13.0381 8.5735 13.0081V8.94862C8.917 8.89362 9.3645 8.86312 9.837 8.86312C10.6215 8.86312 11.1305 9.00412 11.529 9.30462C11.9585 9.62312 12.2285 10.1321 12.2285 10.8616ZM5.0105 13.0266V8.94862C5.2985 8.89962 5.7035 8.86312 6.2735 8.86312C6.85 8.86312 7.261 8.97362 7.5365 9.19412C7.8 9.40262 7.9775 9.74612 7.9775 10.1506C7.9775 10.5556 7.843 10.8986 7.5975 11.1316C7.2785 11.4321 6.8065 11.5671 6.2545 11.5671C6.132 11.5671 6.0215 11.5611 5.936 11.5486V13.0266H5.0105ZM14.5665 16.3541H5.4335V13.6876H14.5665V16.3541ZM15.3685 9.66012H13.78V10.6046H15.264V11.3651H13.78V13.0266H12.8425V8.89362H15.3685V9.66012Z"
                                                    fill="#404040"
                                                />
                                            </svg>
                                        </button>
                                    </li>
                                    <li className={styles.info_section_icon}>
                                        <button
                                            type="button"
                                            className={styles.btn_dt}
                                            disabled={filteredActivityData == 0}
                                            onClick={() => exportTableToExcel(filteredActivityData, "user_activity_excel", columns)}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <path
                                                    d="M16.3106 10.5575H13.7091V9.25676H16.3106V10.5575ZM16.3106 11.3008H13.7091V12.6016H16.3106V11.3008ZM16.3106 5.16871H13.7091V6.46945H16.3106V5.16871ZM16.3106 7.21274H13.7091V8.51347H16.3106V7.21274ZM16.3106 13.3448H13.7091V14.6456H16.3106V13.3448V13.3448ZM18.1075 15.9092C18.0332 16.2957 17.5686 16.3049 17.2583 16.318H11.4793V17.9903H10.3253L1.82031 16.5038V3.4982L10.3755 2.00977H11.4793V3.49076H17.0595C17.3735 3.50376 17.7192 3.48146 17.9923 3.66913C18.1837 3.94414 18.1651 4.2935 18.1781 4.60938L18.1707 14.2832C18.1614 14.824 18.2209 15.3759 18.1075 15.9092ZM8.63434 12.9936C8.1215 11.9531 7.59935 10.9199 7.08833 9.87925C7.59376 8.86654 8.09178 7.85012 8.58792 6.83366C8.1661 6.85411 7.74427 6.88012 7.32434 6.90986C7.01028 7.67357 6.64421 8.41686 6.39894 9.2066C6.17038 8.46146 5.8675 7.74417 5.59063 7.01762C5.18181 7.03993 4.773 7.06408 4.36421 7.08823C4.7953 8.03967 5.25431 8.97801 5.67239 9.93501C5.17996 10.8641 4.71913 11.8063 4.24156 12.7409C4.64849 12.7576 5.05546 12.7744 5.46239 12.7799C5.75229 12.0404 6.11277 11.3287 6.36549 10.5742C6.5922 11.3844 6.97683 12.1333 7.29274 12.9081C7.74057 12.9398 8.18651 12.9676 8.63434 12.9936ZM17.2919 4.37328H11.4793V5.16871H12.9659V6.46945H11.4793V7.21274H12.9659V8.51347H11.4793V9.25676H12.9659V10.5575H11.4793V11.3008H12.9659V12.6016H11.4793V13.3448H12.9659V14.6456H11.4793V15.5049H17.2919V4.37328Z"
                                                    fill="#404040"
                                                />
                                            </svg>
                                        </button>
                                    </li>
                                    {/* <li className={styles.info_section_icon}>
                                        <button type="button" className={styles.btn_dt} disabled={filteredActivityData == 0} onClick={() => exportHandlePrint(filteredActivityData, columns)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <path
                                                    d="M14.9993 14.5833V15.625C14.9993 16.1223 14.8018 16.5992 14.4502 16.9508C14.0985 17.3025 13.6216 17.5 13.1243 17.5H6.87435C6.37707 17.5 5.90015 17.3025 5.54852 16.9508C5.19689 16.5992 4.99935 16.1223 4.99935 15.625V14.5825L3.54102 14.5833C3.04373 14.5833 2.56682 14.3858 2.21519 14.0342C1.86356 13.6825 1.66602 13.2056 1.66602 12.7083V7.71167C1.66602 6.99337 1.95136 6.3045 2.45927 5.79659C2.96718 5.28867 3.65605 5.00333 4.37435 5.00333L4.99852 5.0025L4.99935 4.375C4.99935 3.87772 5.19689 3.40081 5.54852 3.04917C5.90015 2.69754 6.37707 2.5 6.87435 2.5H13.126C13.6233 2.5 14.1002 2.69754 14.4518 3.04917C14.8035 3.40081 15.001 3.87772 15.001 4.375V5.0025H15.626C16.3443 5.00294 17.0331 5.28837 17.5412 5.79614C18.0493 6.30391 18.3351 6.99252 18.336 7.71083L18.3385 12.7083C18.3386 12.9545 18.2903 13.1982 18.1962 13.4256C18.1021 13.653 17.9641 13.8597 17.7902 14.0338C17.6162 14.2079 17.4097 14.3461 17.1823 14.4404C16.955 14.5346 16.7113 14.5832 16.4652 14.5833H14.9993ZM13.1243 11.25H6.87435C6.70859 11.25 6.54962 11.3158 6.43241 11.4331C6.3152 11.5503 6.24935 11.7092 6.24935 11.875V15.625C6.24935 15.97 6.52935 16.25 6.87435 16.25H13.1243C13.2901 16.25 13.4491 16.1842 13.5663 16.0669C13.6835 15.9497 13.7493 15.7908 13.7493 15.625V11.875C13.7493 11.7092 13.6835 11.5503 13.5663 11.4331C13.4491 11.3158 13.2901 11.25 13.1243 11.25ZM13.126 3.75H6.87435C6.70859 3.75 6.54962 3.81585 6.43241 3.93306C6.3152 4.05027 6.24935 4.20924 6.24935 4.375L6.24852 5.0025H13.751V4.375C13.751 4.20924 13.6852 4.05027 13.568 3.93306C13.4507 3.81585 13.2918 3.75 13.126 3.75Z"
                                                    fill="#404040"
                                                />
                                            </svg>
                                        </button>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className={styles.section_searchbar}>
                                <div className="form-group">
                                    <div className={`${styles.info_search} searchbar_style`}>
                                        <input type="search" placeholder="Search" className="form-control" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="data_table_body">
                        {filteredActivityData.length > 0 ? (
                            <DataTableComponents fetchData={GetUserActivityHistoryData} columns={columns} userdata={filteredActivityData} TotalRows={totleRecode} />
                        ) : (
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Created On</th>
                                        <th>IP Address</th>
                                        <th>Location</th>
                                        <th>Activity</th>
                                        <th>Access Device</th>
                                    </tr>
                                </thead>
                                <NoDataFoundImg />
                            </Table>
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
