import React from 'react';
import errorImg from '../assets/images/error_404.png';
import { Link } from 'react-router-dom';

export default function NotFound() {
    return (
		<React.Fragment>
			<div className='error_content'>
				{/* <h1>404</h1> */}
				<img src={errorImg} alt=''/>
				{/* <h6>Oops! Page not found</h6> */}
				<p>Sorry! The page you're looking for does not seem to exits. <Link to='/'>Go To Home Page</Link></p>
				{/* <button onClick={handleRedirect}>Go To Home Page</button> */}
			</div>
		</React.Fragment>
    );
}
