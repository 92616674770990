import React, { useEffect, useState } from "react";
import styles from "../styles/asset.module.css";

import overviewImg from "../assets/images/overview.png";
import kycImg from "../assets/images/profile/kyc.svg";
import paymentoptionImg from "../assets/images/profile/payment-option.svg";
import loginpasswordImg from "../assets/images/profile/login-password.svg";
import securityImg from "../assets/images/profile/security.svg";
import safetyImg from "../assets/images/transaction-history.png";

import { Link, useParams } from "react-router-dom";
import Layout from "../components/Layout/Layout";
import Loading from "../components/loader/Loading";
import AssetOverview from "../components/walletHistory/AssetOverview";
import AssetFunds from "../components/walletHistory/AssetFunds";
import AssetFundsHistory from "../components/walletHistory/AssetFundsHistory";
import AssetFunding from "../components/walletHistory/AssetFunding";
import { GetApi } from "../api/api-service";
import { API_Path } from "../const";
import NotFound from "./NotFound";

export default function AssetWallet() {
    const { subPage } = useParams();
    const [isOpen, setIsopen] = useState(false);
    const [walletName, seWalletName] = useState([]);

    useEffect(() => {
        getAllWalletAccount();
    }, []);

    const getAllWalletAccount = async () => {
        try {
            const response = await GetApi(API_Path.getAllAccount);
            if (response && response.data) {
                seWalletName(response.data); // Assume response.data has a property 'walletName'
            }
        } catch (error) {}
    };

    const ToggleSidebar = () => {
        isOpen === true ? setIsopen(false) : setIsopen(true);
    };

    return (
        <>
            {subPage === "asset-overview" || subPage === "spot" || subPage === "funding" || subPage === "funds-history-spot" ? (
                <Layout>
                    <section>
                        <div className={`${styles.profile_head} profile-head`}>
                            <h3>Profile</h3>
                            <div className="sidebar_icon">
                                <button className={`${styles.navbar_toggle} navbar-toggler`} type="button" onClick={ToggleSidebar}>
                                    <span className="toggler-icon">
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className={`sidebar-overlay ${isOpen == true ? "active" : ""}`} onClick={ToggleSidebar}></div>

                        <div className="d-flex">
                            <div className={`left-side ${isOpen === true ? "active" : ""}`}>
                                <div className={styles.sidebar_bg}>
                                    <div className="close-sidebar btn btn-primary" onClick={ToggleSidebar}>
                                        X
                                    </div>

                                    <div className={styles.sidebar_details}>
                                        <div className={styles.account_settings} onClick={ToggleSidebar}>
                                            <div className={styles.nav}>
                                                <Link to="/useraccount/asset-overview" className={`${styles.navlink} ${subPage === "asset-overview" ? styles.active : ""}`}>
                                                    {/* <img src={overviewImg} alt="" className="img-fluid" /> */}
                                                    <span>Overview</span>
                                                </Link>
                                            </div>
                                            {walletName &&
                                                walletName.map((item, i) => {
                                                    let name = item.WalletName.toLowerCase();
                                                    return (
                                                        <div className={styles.nav} key={i}>
                                                            <Link to={`/useraccount/${name}`} className={`${styles.navlink} ${subPage === name ? styles.active : ""}`}>
                                                                <span>{item.WalletName}</span>
                                                            </Link>
                                                        </div>
                                                    );
                                                })}

                                            <div className={styles.nav}>
                                                <Link to="/useraccount/funds-history-spot" className={`${styles.navlink} ${subPage === "funds-history-spot" ? styles.active : ""}`}>
                                                    {/* <img src={safetyImg} alt="" className="img-fluid" /> */}
                                                    <span>Funds History</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="right-side">
                                <div>
                                    {subPage === "asset-overview" && <AssetOverview walletName={walletName} />}
                                    {subPage === "spot" && <AssetFunds />}
                                    {subPage === "funding" && <AssetFunding />}
                                    {subPage === "funds-history-spot" && <AssetFundsHistory />}
                                </div>
                            </div>
                        </div>
                    </section>
                </Layout>
            ) : (
                <NotFound />
            )}
        </>
    );
}
