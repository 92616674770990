import React, { useState } from "react";
import styles from "../styles/aboutus.module.css";
import chooseusImg from "../assets/images/aboutus_img.svg";
import { Link } from "react-router-dom";
import aboutus_img1 from "../assets/images/aboutus_img1.svg";
import aboutus_img2 from "../assets/images/aboutus_img2.svg";
import aboutus_img3 from "../assets/images/aboutus_img3.svg";
import { baseName, companyName } from "../const";
import Layout from "../components/Layout/Layout";

export default function AboutUs() {
    const [isShowMore, setIsShowMore] = useState({
        chooseus: false,
        realtime: false,
        trand: false,
        secure: false,
        customer: false,
        trust: false,
        innovation: false,
        knowledge: false,
    });

    const handleShowText = (type) => {
        setIsShowMore((prevState) => ({
            ...prevState,
            [type]: !prevState[type],
        }));
    };

    return (
        <Layout>
            {/* ------------------------ BANNER SECTION -- START ---------------------------- */}
            <section className={styles.banner}>
                <div className="container">
                    <div className={styles.banner_body}>
                        <h2>About Us</h2>
                        <p>
                            {companyName} is a global cryptocurrency and digital assets trading platform. We have built our next generation trading platform which is trusted by traders from more than
                            150+ countries around the world.
                        </p>
                        <p className="mb-0 px-5">
                            The platform has been designed to enable realtime and effortless transactions for our users while trading various exchange listed cryptocurrencies and digital assets. At{" "}
                            {companyName.toLowerCase()}, you can seamlessly trade or exchange many popular cryptocurrencies and digital assets like Bitcoin (BTC), Ethereum (ETH), Bitcoin Cash (BCH),
                            Litecoin etc. We are focused on constantly expanding the scope of our products and services to enhance the customer experience.
                        </p>
                    </div>
                </div>
            </section>
            {/* ------------------------ BANNER SECTION -- END ------------------------------ */}
            <div>
                <div className="container">
                    {/* ------------------------ CHOOSE US SECTION -- START ------------------------- */}
                    <section className={styles.chooseus_section}>
                        <div className="row align-items-center">
                            <div className="col-lg-8 col-md-7">
                                <div className={styles.chooseus_section_body}>
                                    <h2>Why Choose Us ?</h2>
                                    <p>
                                        {companyName} platform is for you, no matter if you are a beginner or an experienced trader. It has been designed to offer a seamless experience to all. Whether
                                        you’re a rookie or an experienced professional trader, you’ll find that our cryptocurrency and digital assets exchange is amongst the most user friendly and
                                        intuitive platforms out there.
                                    </p>
                                    <p>
                                        Protecting your Cryptocurrency and Digital Assets is fundamental to our mission aimed at truly democratizing the investments and trading for everyone around the
                                        world as we understand and appreciate the importance of securing the digital assets of platform users.
                                    </p>
                                    <p>
                                        Our AI powered robust customer identity verification (KYC) systems ensure a seamless customer onboarding journey without compromising on security. You can{" "}
                                        <Link to="/register"> Register</Link> with {baseName.toLowerCase()} and start trading the most popular digital currencies in few minutes. Our International KYC
                                        process complies with CCPA, GDPR, SOC2 type II, and WCAG guidelines. All our processes are in compliance with the regulations including specific laws related to
                                        KYC, CFT and AML.
                                    </p>
                                    <p className="mb-0">
                                        With {baseName.toLowerCase()}, you can build a full-fledged Investment/Trading setup or develop and deploy your own trading bots. Our robust and secure trading
                                        APIs are designed to offer seamless access to our platform.
                                    </p>
                                    {/* isShowMore.chooseus */}
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-5">
                                <div className={styles.chooseus_img}>
                                    <img src={chooseusImg} alt="" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* ------------------------ CHOOSE US SECTION -- END --------------------------- */}
                    {/* ------------------------ OUR GLOBAL SECTION -- START ------------------------ */}
                    <section className={styles.global_section}>
                        <div className={styles.title}>
                            <h2>WHAT YOU GET AT {companyName.toUpperCase()}?</h2>
                            <p className="mb-0">As A Privileged User Of Our Global Platform, You Can Expect The Following:</p>
                        </div>
                        <div className={`${styles.global_section_box} row`}>
                            <div className={styles.global_section_width}>
                                <div className={`${styles.global_section_body} ${isShowMore.realtime ? "h-auto" : ""}`}>
                                    <h4>
                                        REALTIME <br /> CRYPTOCURRENCY TRADING
                                    </h4>
                                    <p className={isShowMore.realtime ? "" : styles.global_line_clamp}>
                                        We are firm believers and followers of ethical standards and accord high importance to trust and transparency in every sphere of life. It’s important
                                        {isShowMore.realtime
                                            ? " for us to preserve and promote these human values in all our interactions with customers, employees and other stakeholders including the public."
                                            : "..."}
                                    </p>
                                    <button onClick={() => handleShowText("realtime")}>{isShowMore.realtime ? "READ LESS" : "READ MORE"}</button>
                                </div>
                            </div>
                            <div className={styles.global_section_width}>
                                <div className={`${styles.global_section_body} ${isShowMore.trand ? "h-auto" : ""}`}>
                                    <h4>
                                        TRADE ANYTIME <br /> ANYWHERE
                                    </h4>
                                    <p className={isShowMore.trand ? "" : styles.global_line_clamp}>
                                        With {baseName.toUpperCase()}, you can trade ANYTIME AND ANYWHERE. We've created the mobile tools you need for trading on the go. You can use our web based
                                        platform
                                        {isShowMore.trand ? ", Android and iOS Apps for a seamless trading experience while trading cryptocurrency and digital assets." : "..."}
                                    </p>
                                    <button onClick={() => handleShowText("trand")}>{isShowMore.trand ? "READ LESS" : "READ MORE"}</button>
                                </div>
                            </div>
                            <div className={styles.global_section_width}>
                                <div className={`${styles.global_section_body} ${isShowMore.secure ? "h-auto" : ""}`}>
                                    <h4>
                                        HIGHLY SECURE <br /> PLATFORM
                                    </h4>
                                    <p className={isShowMore.secure ? "" : styles.global_line_clamp}>
                                        We have designed and operate our platform with a security first mindset. That’s the primary reason we store almost all the crypto and digital assets in
                                        {isShowMore.secure
                                            ? ` off-line storage every ${baseName.toLowerCase()} user can enable multiple security options including two-factor authentication and hardware security key to protect individual account and wallet. All the user personal and transaction data is protected with appropriate security measures. We maintain sufficient liquidity reserves so that you can always have access to funds, whenever needed.`
                                            : "..."}
                                    </p>
                                    <button onClick={() => handleShowText("secure")}>{isShowMore.secure ? "READ LESS" : "READ MORE"}</button>
                                </div>
                            </div>
                            <div className={styles.global_section_width}>
                                <div className={`${styles.global_section_body} ${isShowMore.customer ? "h-auto" : ""}`}>
                                    <h4>
                                        QUALITY CUSTOMER <br /> SUPPORT
                                    </h4>
                                    <p className={isShowMore.customer ? "" : styles.global_line_clamp}>
                                        At {baseName.toLowerCase()}, we have a dedicated squad of our customer support executives. You can contact us anytime from anywhere and we shall be happy to
                                        assist you to
                                        {isShowMore.customer
                                            ? " resolve your queries We routinely publish about our products and services in official blogs and news and announcements sections of our platform to share the latest developments with our users."
                                            : "..."}
                                    </p>
                                    <button onClick={() => handleShowText("customer")}>{isShowMore.customer ? "READ LESS" : "READ MORE"}</button>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* ------------------------ OUR GLOBAL SECTION -- END -------------------------- */}

                    <section className={styles.corevalues_section}>
                        <div className="row">
                            <div className="col-12">
                                <div className={styles.title}>
                                    <h2>CORE VALUES AT {companyName.toUpperCase()}</h2>
                                </div>
                                <div className={styles.corevalues_section_part}>
                                    <div className="col-lg-6 col-md-6">
                                        <div className={`${styles.corevalues_section_body} ${styles.corevalues_section_body_right}`}>
                                            <h4>TRUST & TRANSPARENCY</h4>
                                            <p>We are firm believers and followers of ethical standards and accord high importance to trust and transparency in every sphere of life.</p>
                                            <p>
                                                It’s important for us to preserve and promote these human values in all our interactions with customers, employees and other stakeholders including the
                                                public.
                                            </p>
                                            <p>
                                                By upholding these principles, we strive to foster meaningful relationships built on integrity and openness, paving the way for mutual respect and
                                                sustainable growth.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 text-end">
                                        <div className={styles.corevalues_section_img}>
                                            <img src={aboutus_img1} alt="" className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                                <div className={`${styles.corevalues_section_part} ${styles.flex_direction}`}>
                                    <div className="col-lg-6 col-md-6">
                                        <div className={styles.corevalues_section_img}>
                                            <img src={aboutus_img2} alt="" className="img-fluid" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className={`${styles.corevalues_section_body} ${styles.corevalues_section_body_left}`}>
                                            <h4>INNOVATION AND IDEAS</h4>
                                            <p>
                                                Constant exposure to Innovation and ideas is what really excites us at {companyName.toLowerCase()}. We are always striving for learning about the latest
                                                advancements in cryptocurrency and digital assets markets and related technology.
                                            </p>
                                            <p>
                                                We relentlessly try to understand how we can incorporate these advancements within our products and services while maintaining the security and
                                                stability of our platform. It’s important for us to preserve and promote these human values in all our interactions with customers, employees and other
                                                stakeholders including the public.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className={`${styles.corevalues_section_part} pb-0`}>
                                    <div className="col-lg-6 col-md-6">
                                        <div className={`${styles.corevalues_section_body} ${styles.corevalues_section_body_right}`}>
                                            <h4>FIRM BELIEF IN BLOCKCHAIN & POWER OF KNOWLEDGE</h4>
                                            <p>We are a team of passionate traders and firm believers of blockchain technology.</p>
                                            <p>
                                                We believe in sharing our knowledge and creating a shared pool where ideas can free flow between our entire team and other concerned stakeholders
                                                empowering us all with a collective understanding of blockchain’s potential, as we actively contribute to the ongoing evolution and innovation within
                                                the industry.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 text-end">
                                        <div className={`${styles.corevalues_section_img} mb-0`}>
                                            <img src={aboutus_img3} alt="" className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <section className={styles.trading_section}>
                <div className="container">
                    <div className={styles.trading_section_body}>
                        <h4>Do you want to have a seamless experience while trading cryptocurrency and digital assets markets?</h4>
                        <p>Start trading at {companyName.toLowerCase()}</p>
                        <Link to="/register">
                            <button className="button primary_button">JOIN US</button>
                        </Link>
                    </div>
                </div>
            </section>
        </Layout>
    );
}
