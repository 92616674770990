export const companyName = process.env.REACT_APP_COMPANY_NAME;
export const baseName = process.env.REACT_APP_BASE_NAME;
export const baseUrl = process.env.REACT_APP_BASE_URL;
export const supportUrl = process.env.REACT_APP_BASE_SUPPORT_URL;
export const socketUrl = process.env.REACT_APP_SOCKET_URL;
export const FunexAuthorizationData = localStorage.getItem("FunexAuthorizationData");
export const apiBaseUrlImg = "https://exchange.funexcoin.com/skins/client/img/flag/";
export const ApiBaseUrl = process.env.REACT_APP_LIVE_MAIN_URL;
export const  validNumberRegex = /^[0-9\b]+$/; // Regular expression for valid number

export const API_Path = {
    registerData: `${ApiBaseUrl}AuthAccount/UserRegister`,
    userLogin: `${ApiBaseUrl}AuthAccount/UserLogin`,
    userEmailVerify: `${ApiBaseUrl}AuthAccount/UserEmailVerify`,
    resendEmailVerify: `${ApiBaseUrl}AuthAccount/ResendUserEmailVerify`,
    getUserEmailCacheurl: `${ApiBaseUrl}AuthAccount/GetUserByCacheUrl`,
    userLoginAuthVerify: `${ApiBaseUrl}AuthAccount/UserLoginAuthVerify`,
    // userLoginVerify : `${ApiBaseUrl}AuthAccount/UserLoginVerify`,
    resendUserLoginVerify: `${ApiBaseUrl}authaccount/resendUserLoginVerify`,
    resetPasswordVerify: `${ApiBaseUrl}authaccount/ResetPasswordVerify`,
    loginVerifyGetUser: `${ApiBaseUrl}AuthAccount/getUserByEncryptedUrl?encryptedUrl=`,
    SendLoginVerifyMobileOtp: `${ApiBaseUrl}AuthAccount/SendLoginVerifyMobileOtp`,

    GetAllActiveBlogs: `${ApiBaseUrl}clientservices/GetAllActiveBlogs`,

    getCoinStatusData: `${ApiBaseUrl}clientservices/GetCoinStatusOnline`,
    // getMarketMainData: `${ApiBaseUrl}ExchangeService/GetMarketMainCoin`,

    getPairWiseTradingFees: `${ApiBaseUrl}clientservices/GetAllPairWiseTradingFees`,
    getActiveCryptoCurrency: `${ApiBaseUrl}clientservices/GetAllActiveCryptoCurrency`,

    getCurrentDetails: `${ApiBaseUrl}services/GetCurrentUserDetailEx`,
    getUserDetails: `${ApiBaseUrl}services/GetCurrentUserDetail`,
    getUserKycProfile: `${ApiBaseUrl}services/GetUserKycProfile`,
    getUserKyc: `${ApiBaseUrl}services/GetUserKyc`,
    getUserBeneficiary: `${ApiBaseUrl}services/GetUserBeneficiary`,

    userKYCProfileUpload: `${ApiBaseUrl}services/UserKycProfileUpload`,
    userBankAccountVerification: `${ApiBaseUrl}services/UserBankAccountVerification`,

    userPasswordRequest: `${ApiBaseUrl}services/SendUpdateUserPasswordRequest`,
    userPasswordVerify: `${ApiBaseUrl}services/UpdateUserPasswordVerify`,
    updateUserPassword: `${ApiBaseUrl}services/updateCurrentUserPassword`,

    getUserApi: `${ApiBaseUrl}services/getUserApis`,
    createUserApi: `${ApiBaseUrl}services/createUserApi`,
    removeUserApi: `${ApiBaseUrl}services/RemoveUserApi`,

    getUserLoginHistory: `${ApiBaseUrl}services/GetUserLoginHistory?`,
    getUserActivityHistory: `${ApiBaseUrl}services/GetUserActivityHistory?`,
    userLoginSafertCode: `${ApiBaseUrl}services/updateCurrentUserLoginSafetyCode`,
    userEmailSafertCode: `${ApiBaseUrl}services/UpdateCurrentUserEmailSafetyCode`,

    userAuthenticatorCode: `${ApiBaseUrl}services/GetCurrentUserAuthenticatorCode`,
    userGoogleAuthCode: `${ApiBaseUrl}services/UpdateAuthenticatorKey`,
    userMobileAuthCode: `${ApiBaseUrl}services/SendMobileAuthOtpCode`,
    userMobileAuthOTPCode: `${ApiBaseUrl}services/UpdateMobileAuthOtpVerify`,

    GetCurrentUserAllSupports: `${ApiBaseUrl}services/GetCurrentUserAllSupports`,
    GetCurrentUserSupportsByTicketNo: `${ApiBaseUrl}services/GetCurrentUserSupportsByTicketNo?ticketNo=%23`,

    GetCoinPairsForFilter: `${ApiBaseUrl}services/GetCoinPairsForFilter`,
    GetUserExchangeOrders: `${ApiBaseUrl}services/GetUserExchangeOrdersWithPaging?`,
    GetMarketMainCoin: `${ApiBaseUrl}ExchangeService/GetMarketMainCoin`,

    // Exchange's page API
    GetCurrentUserDetail: `${ApiBaseUrl}Services/GetCurrentUserDetail`,
    GetUserLoginDeviceDetail: `${ApiBaseUrl}Services/GetUserLoginDeviceDetail`,
    GetUserAllFavouritePairs: `${ApiBaseUrl}Services/GetUserAllFavouritePairs`,
    AddUserFavouritePairs: `${ApiBaseUrl}Services/AddUserFavouritePairs`,
    RemoveFavouritePairs: `${ApiBaseUrl}Services/removeFavouritePairs`,
    placeOrder: `${ApiBaseUrl}Services/maketrade`,

    // get start
    addSubscriber: `${ApiBaseUrl}ClientServices/AddSubscriber`,
    getcountrycodeIp: `https://ipapi.co/json/`,

    SendSupportMessageByUser: `${ApiBaseUrl}services/SendSupportMessageByUser`,

    SendAPIManagement: `${ApiBaseUrl}services/VerifyCreateUserApi`,
    userFreezeAccount: `${ApiBaseUrl}AuthAccount/UserFreezeAccount`,
    resendFreezeAccountUserVerify: `${ApiBaseUrl}AuthAccount/ResendFreezeAccountUserVerify`,
    getFreezeAccountUser: `${ApiBaseUrl}AuthAccount/GetFreezeAccountUser?encryptedUrl=`,

    // wallet
    getPairPrice: `${ApiBaseUrl}api/web/v1/public/coin/getpairprice`,
    getBaseConvertCoin: `${ApiBaseUrl}api/web/v1/public/setting/getbaseconvertcoin`,
    getAllCoin: `${ApiBaseUrl}api/web/v1/public/coin/getallcoin`,
    getEstimateCoin: `${ApiBaseUrl}api/web/v1/private/userwallet/config/getestimatecoin`,
    checkUserKycStatus: `${ApiBaseUrl}api/web/v1/private/userwallet/checkuserkycstatus`,
    getDailyWithdrawLimit: `${ApiBaseUrl}api/web/v1/public/setting/getdailywithdrawlimit`,

    getWithdrawConvertCoin: `${ApiBaseUrl}api/web/v1/public/setting/getwithdrawconvertcoin`,

    getCryptoCoinAll: `${ApiBaseUrl}api/web/v1/private/userwallet/getcryptocoinall`,
    getDailyWithDrawsum: `${ApiBaseUrl}api/web/v1/private/userwallet/getdailywithdrawsum`,
    getChain: `${ApiBaseUrl}api/web/v1/private/userwallet/config/getchain?coin=`,
    getAddress: `${ApiBaseUrl}api/web/v1/private/userwallet/deposit/getaddress`,
    GetNotify: `${ApiBaseUrl}api/web/v1/private/UserWallet/CoinTransaction/GetNotify`,
    sendWithdrawByAddressSendRequest: `${ApiBaseUrl}api/web/v1/private/UserWallet/WithdrawByAddress/SendRequest`,    

    getAllAccount: `${ApiBaseUrl}api/web/v1/public/UserWallet/Account/GetAll`,
    // GetWalletBalance: `${ApiBaseUrl}api/web/v1/public/UserWallet/Wallets/GetBalance`,
    GetWalletBalance: `${ApiBaseUrl}api/web/v1/private/UserWallet/Wallets/GetBalance`,

    getCoinFilter: `${ApiBaseUrl}api/web/v1/private/wallethistory/getcoinfilter`,
    getCoinType: `${ApiBaseUrl}api/web/v1/private/wallethistory/getcointype`,
    getBalance: `${ApiBaseUrl}api/web/v1/private/userwallet/spot/getbalance`,
    getTransactionType: `${ApiBaseUrl}api/web/v1/private/wallethistory/gettransactiontype`,
    getStatus: `${ApiBaseUrl}api/web/v1/private/wallethistory/transaction/getstatus?`,
    getCryptoHistoryDeposit: `${ApiBaseUrl}api/web/v1/private/wallethistory/deposit/viaaddress/getcryptohistory?`,
    getCryptoHistoryWithdraw: `${ApiBaseUrl}api/web/v1/private/wallethistory/withdraw/viaAddress/getcryptohistory?`,
    getCryptoWithdrawDetail: `${ApiBaseUrl}api/web/v1/private/wallethistory/withdraw/viaaddress/getcryptodetail?transactionId=`,
    getCryptoDepositDetail: `${ApiBaseUrl}api/web/v1/private/wallethistory/deposit/viaaddress/getcryptodetail?transactionId=`,
};
