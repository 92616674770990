import React from 'react';

export default function Loading() {
    return (
        <div id="divLoader" className="loading">
            <div></div>
            <div></div>
        </div>
    );
}
