import React, { useContext, useEffect, useState } from "react";
import styles from "../styles/trading_classic.module.css";
import DataNotFound from "../assets/images/data_not_found.svg";
import Layout from "../components/Layout/Layout";
import { Link, unstable_HistoryRouter, useNavigate, useParams } from "react-router-dom";
import { API_Path, ApiBaseUrl, FunexAuthorizationData, socketUrl } from "../const";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import io from "socket.io-client";
import useFetch from "../components/customHook/useFetch";
import { AuthContext } from "../context/AuthContext";
import { toast } from "react-toastify";
import { GetApi, PostTokenApi } from "../api/api-service";
import { errorContainer, expoStrToNumStr, parseFloatAmt } from "../components/common/CommonFun";
import emptyRecordImg from "../assets/images/empty_record.svg";
import NotFound from "./NotFound";
import Loader from "../components/loader/Loader";

const socket = io.connect(socketUrl);

let marketTabs = [];

let exOpen = [];
let exFill = [];
let exTradeHis = [];

var mintotal = 0,
    minsize = 0,
    maxsize = 0,
    minprice = 0,
    maxprice = 0;

var buysell = ""; // -- buy or sell
// var isLogin = false;
var isTrading = false;
var docVerify = false; // -- set comment
var is2FA = false; // -- set comment
var allOrders = [];

var isfreeze = false;

var size = 0;

var limitPrice = 0;

var price = 0;
var total = 0;

var Makettotalbuy = "";
var stopPrice = "";
var ExcoinBalance = "";
var Makettotalsell = "";
var ExcurrBalance = "";

var userWallets = [];

var currencyData = [];

export default function TradingClassic() {
    const { id } = useParams();
    const navigate = useNavigate();

    let initialized = false;

    const [loading, setLoading] = useState(false);
    const [loader, setLoader] = useState(false);

    const RunningPair = localStorage.getItem("RunningPair");
    const BaseCoin = localStorage.getItem("FirstCoin");
    const FirstCoin = localStorage.getItem("BaseCoin");
    const isLogin = localStorage.getItem("FunexAuthorizationData") ?? false;
    const { userCurrentData } = useContext(AuthContext);
    // var favouriteList = [];
    const [coinData, setCoinData] = useState({
        pair: RunningPair,
        firstCoin: FirstCoin,
        baseCoin: BaseCoin,
    });

    const [activeMarketTab, setActiveMarketTab] = useState("BTC");
    const [activeOrderTab, setActiveOrderTab] = useState("open");
    const [activeTab, setActiveTab] = useState("buy");
    const [activeLimitTab, setActiveLimitTab] = useState("limit");
    const [marketShow, setMarketShow] = useState(false);
    const [favouriteList, setFavouriteList] = useState(() => {
        const savedList = localStorage.getItem("favoritePairs");
        return savedList ? JSON.parse(savedList) : [];
    });
    const [receiveData, setReceiveData] = useState();
    const [currentUserData, setCurrentUserData] = useState({
        userID: 0,
        isTrading: false,
        isNYE: false,
        isfreeze: false,
    });
    const [currHisPrice, setCurrHisPrice] = useState();

    const [isUp, setIsUp] = useState(false);
    const [isDown, setIsDown] = useState(false);
    const [hisColor, setHisColor] = useState("white_color");
    const [issame, setIssame] = useState(true);

    const [listOfPairs, setListOfPairs] = useState([]);

    const [currentSellPrice, setCurrentSellPrice] = useState(0);
    const [currentBuyPrice, setCurrentBuyPrice] = useState(0);
    const [bookSell, setBookSell] = useState([]);
    const [bookBuy, setBookBuy] = useState([]);
    const [showLoaderBookSell, setShowLoaderBookSell] = useState(true);
    const [showLoaderBookBuy, setShowLoaderBookBuy] = useState(true);
    const [marketSearchQuery, setMarketSearchQuery] = useState("");

    const [FunexAuthorizationData, setFunexAuthorizationData] = useState(null);

    const [urlPairName, setUrlPairName] = useState("");
    const [checkUrlPairName, setCheckUrlPairName] = useState(true);

    const [priceDecimal, setPriceDecimal] = useState(0);
    const [volumeDecimal, setVolumeDecimal] = useState(0);

    const [priceBuySell, setPriceBuySell] = useState();
    const [sizeAmount, setSizeAmount] = useState();
    const [totalBuySell, setTotalBuySell] = useState();

    const [receiveMarket, setReceiveMarket] = useState([]);

    const [coinBalance, setCoinBalance] = useState([]);
    const [currBalance, setCurrBalance] = useState([]);
    const [makerFees, setMakerFees] = useState();
    const [takerFees, setTakerFees] = useState();

    const [markettotalbuy, setMakettotalbuy] = useState();
    const [makettotalsell, setMakettotalsell] = useState();

    const [trade, setTrade] = useState([]);

    const [showLoaderOpenOrder, setShowLoaderOpenOrder] = useState(true);
    const [showLoaderOrderHistory, setShowLoaderOrderHistory] = useState(true);

    const [isNotFound, setIsNotFound] = useState(false);
    const [isSocketCalled, setIsSocketCalled] = useState(false);

    const handleOrderTabClick = (tabKey) => setActiveOrderTab(tabKey);
    const handlelimitTabClick = (tabKey) => setActiveLimitTab(tabKey);
    const handleMarketTabClick = (tabKey) => setActiveMarketTab(tabKey);

    const [baseCurrency, quoteCurrency] = id.split("_");

    useEffect(() => {
        setCoinData({ firstCoin: baseCurrency, baseCoin: quoteCurrency, pair: baseCurrency + quoteCurrency });
        setFunexAuthorizationData(localStorage.getItem("FunexAuthorizationData"));
    }, []);

    useEffect(() => {
        if (id !== id.toUpperCase()) navigate(`/trading/classic/${id.toUpperCase().replace(/_/g, "_")}`);
    }, [id, navigate]);

    useEffect(() => {
        if (currencyData.length > 0) {
            const isValidId = currencyData.some((data) => data.PairSymbol === baseCurrency + quoteCurrency);
            if (!isValidId) {
                setIsNotFound(true);
            } else {
                setIsNotFound(false);
                localStorage.setItem("FirstCoin", baseCurrency.toUpperCase());
                localStorage.setItem("BaseCoin", quoteCurrency.toUpperCase());
                localStorage.setItem("RunningPair", `${baseCurrency.toUpperCase()}${quoteCurrency.toUpperCase()}`);
            }
        }
    }, [currencyData]);

    useEffect(() => {
        if (!initialized) {
            if (isLogin) {
                initialized = true;
                getCurrentUserDetail();
            } else {
                getMarketMainCoin();
            }
        }
    }, []);

    useEffect(() => {
        SendSocket();
    }, [baseCurrency, quoteCurrency]);

    useEffect(() => {
        setPairName(baseCurrency + "_" + quoteCurrency);
    }, [baseCurrency, quoteCurrency]);

    useEffect(() => {
        if (favouriteList) localStorage.setItem("favoritePairs", JSON.stringify(favouriteList));
    }, [favouriteList]);

    const handleLogIN = () => navigate("/login");

    const setPairName = (urlPairName) => {
        if (urlPairName && urlPairName !== "") {
            const temp = urlPairName.split("_");
            if (temp && temp.length === 2 && temp[0] !== "" && temp[1] !== "") {
                setUrlPairName(temp[0] + temp[1]);
                return;
            }
        }
        setUrlPairName("_");
        setCoinData({ pair: "", firstCoin: "", baseCoin: "" });
    };

    const handleClickCoin = (coin, baseName) => {
        setCoinData({ pair: coin + baseName, firstCoin: coin, baseCoin: baseName });
        localStorage.setItem("FirstCoin", coin);
        localStorage.setItem("BaseCoin", baseName);
        localStorage.setItem("RunningPair", `${coin}${baseName}`);
        navigate(`/trading/classic/${coin + "_" + baseName}`);
    };

    const setUserAllFavouritePairs = (first, last, flag) => {
        let data = { First: first, Last: last };
        const UserFavouritePairs = new Promise((resolve) => {
            resolve(PostTokenApi(flag === "addFavourite" ? API_Path.AddUserFavouritePairs : API_Path.RemoveFavouritePairs, data));
        });
        UserFavouritePairs.then((res) => {
            if (!res) toast.error("Something went wrong!");
        });
    };

    const handleFavoriteCoin = (id) => {
        const alreadyLiked = favouriteList.some((likedItem) => likedItem.id === id);
        if (!alreadyLiked) {
            const likedItem = receiveMarket.find((item) => item.PairSymbol === id);
            const favData = {
                Coin: likedItem.FirstSymbol,
                Currency: likedItem.LastSymbol,
                PairSymbol: likedItem.PairSymbol,
            };
            setFavouriteList([...favouriteList, favData]);
            if (localStorage.getItem("FunexAuthorizationData")) {
                setUserAllFavouritePairs(likedItem.FirstSymbol, likedItem.LastSymbol, "addFavourite");
            }
        }
    };

    const handleDisFavoriteCoin = (id) => {
        const newLikedCoins = favouriteList.filter((coin) => coin.PairSymbol !== id);
        setFavouriteList(newLikedCoins);
        if (localStorage.getItem("FunexAuthorizationData")) {
            const likedItem = favouriteList.find((item) => item.PairSymbol === id);
            setUserAllFavouritePairs(likedItem.FirstSymbol, likedItem.LastSymbol, "removeFavourite");
        }
    };

    const getCurrentUserDetail = () => {
        setCurrentUserData({
            userID: userCurrentData?.ID,
            isTrading: userCurrentData?.IS_TRADING,
            isNYE: userCurrentData?.USE_NYE_FEES,
            isfreeze: userCurrentData?.ISFREEZE,
        });
        if (userCurrentData?.ISFREEZE) {
            toast.error("Your account is FREEZED.");
            // userLogout();
        }
        // getUserLoginDeviceDetail();
        getMarketMainCoin();
    };

    const getMarketMainCoin = async () => {
        try {
            const response = await GetApi(API_Path.GetMarketMainCoin);

            if (response.data && response.data.length > 0) {
                setListOfPairs(response.data);

                // Clear the marketTabs array before populating it
                marketTabs.length = 0; // This clears the array without creating a new reference

                const pairLastNames = response.data.map((item) => item.LAST).filter((value, index, self) => self.indexOf(value) === index);

                let count = 0;

                pairLastNames.forEach((item) => {
                    const lastCoinImageUrl = response.data.filter((x) => x.LAST.toLowerCase() === item.toLowerCase()).map((x) => x.LastCoinImageUrl)[0];

                    marketTabs.push({
                        Id: count,
                        LastName: item,
                        TabTitle: item,
                        CoinImageUrl: lastCoinImageUrl,
                    });

                    const active = BaseCoin || "BTC";
                    if (item === active) {
                        setActiveMarketTab(item);
                    }
                    count++;
                });
                // Call socket here
                // SendSocket();

                // Manage socket connection
                setTimeout(() => {
                    manegesocket();
                }, 1000);

                // DrawTvChart("dark"); // Uncomment and define this if needed
            } else {
                toast.error("Something went wrong!");
            }
        } catch (error) {
            // console.error("Error fetching market main coin data:", error);
            // toast.error("Failed to fetch data from API.");
        }
    };

    const SendSocket = () => {
        receiveTradeHistroy();
        receiveOrderbook();
        // getReceiveData();
        Receiveallorders();
        ReceiveWalletData();
        if ((checkUrlPairName && urlPairName !== null) || urlPairName !== "") {
            if (urlPairName !== "_") {
                var pairname = urlPairName.split("_").join("");
                var list = listOfPairs.filter((x) => x.PAIRNAME.toLowerCase() === pairname.toLowerCase());
                if (list.length > 0) {
                    setCoinData({ pair: list[0].PAIRNAME, firstCoin: list[0].FIRST, baseCoin: list[0].LAST });
                    localStorage.setItem("RunningPair", list[0].PAIRNAME.toUpperCase());
                    localStorage.setItem("FirstCoin", list[0].FIRST.toUpperCase());
                    localStorage.setItem("BaseCoin", list[0].LAST.toUpperCase());
                } else {
                    // here set and redirect to base_url
                }
            } else {
                // here set and redirect to base_url
            }
        } else {
            // check current pairname for set active pairname
            setCoinData({ pair: baseCurrency + quoteCurrency, firstCoin: coinData.firstCoin, baseCoin: coinData.baseCoin });
            if (baseCurrency + quoteCurrency == "undefined" || baseCurrency + quoteCurrency == null || coinData.firstCoin == null || coinData.baseCoin == null) {
                const CurrentActive = listOfPairs.filter((p) => p.v_active === "1");
                setCoinData({ pair: CurrentActive[0].PAIRNAME, firstCoin: CurrentActive[0].FIRST, baseCoin: CurrentActive[0].LAST });
            } else {
                const arrPair = listOfPairs.filter((p) => p.PAIRNAME.toLowerCase() === baseCurrency + quoteCurrency.toLowerCase());
                if (arrPair.length === 0) {
                    const CurrentActive = listOfPairs.filter((p) => p.v_active === "1");
                    setCoinData({ pair: CurrentActive[0].PAIRNAME, firstCoin: CurrentActive[0].FIRST, baseCoin: CurrentActive[0].LAST });
                }
            }
            localStorage.setItem("RunningPair", baseCurrency + quoteCurrency.toUpperCase());
            localStorage.setItem("FirstCoin", baseCurrency.toUpperCase());
            localStorage.setItem("BaseCoin", quoteCurrency.toUpperCase());
            setCoinData({ pair: baseCurrency + quoteCurrency, firstCoin: baseCurrency, baseCoin: quoteCurrency });
            changeStateCoinPair(baseCurrency + "_" + quoteCurrency);
        }
        var CurrentActive = listOfPairs.filter((pair) => pair.PAIRNAME == baseCurrency + quoteCurrency);
        mintotal = CurrentActive[0]?.MIN_ORDER;
        minsize = CurrentActive[0]?.min_lot_size;
        maxsize = CurrentActive[0]?.max_lot_size;
        minprice = CurrentActive[0]?.min_lot_price;
        maxprice = CurrentActive[0]?.max_lot_price;
        setPriceDecimal(CurrentActive[0]?.PRICE_PRECISION);
        setVolumeDecimal(CurrentActive[0]?.VOLUME_PRECISION);
        localStorage.setItem("mintotal", mintotal);
        localStorage.setItem("minsize", minsize);
        localStorage.setItem("maxsize", maxsize);
        localStorage.setItem("minprice", minprice);
        localStorage.setItem("maxprice", maxprice);
        setCheckUrlPairName(false);
        socket.emit("SendTradeHistory", {
            PAIR: baseCurrency + quoteCurrency,
            priceDecimal: 0,
            volumeDecimal: 0,
        });
        socket.emit("SendOrderBook", {
            PAIR: baseCurrency + quoteCurrency,
            COUNT: 14,
            priceDecimal: 0,
            volumeDecimal: 0,
        });
        socket.emit("SendMarket");
        socket.emit("sendallorder", { PAIR: baseCurrency + quoteCurrency, USER_ID: currentUserData?.userID });
        socket.emit("sendWalletData", {
            USER_ID: currentUserData?.userID,
            FIRST: baseCurrency,
            LAST: quoteCurrency,
        });
        // DepthChart(); // Call Depth trading chart
    };

    useEffect(() => {
        socket.on("ReceiveMarket", getReceiveData);
        return () => {
            socket.off("ReceiveMarket", getReceiveData);
        };
    }, [activeMarketTab, RunningPair, favouriteList]);

    const getReceiveData = (data) => {
        try {
            let tempMarkets = [];
            let MarketData = [];
            let receiveMarketData = [];
            let list = [];
            tempMarkets =
                data &&
                data
                    .filter((marketItem) => baseCurrency.toUpperCase() === marketItem.First && quoteCurrency.toUpperCase() === marketItem.Last) // Filter based on condition
                    .map((marketItem) => ({
                        // Construct object for each item
                        ...marketItem,
                    }));
            setReceiveData(tempMarkets);
            receiveMarketData = data.map((marketItem) => ({
                PairSymbol: marketItem.PairName,
                ShowPairSymbol: `${marketItem.First}/${marketItem.Last}`,
                FirstSymbol: marketItem.First,
                LastSymbol: marketItem.Last,
                LastPrice: marketItem.Price,
                Change: marketItem.TwentyHRPriceChangePrice,
                ChangePercent: marketItem.TwentyHRPriceChangePer,
                Volume: parseFloat(marketItem.TwentyHRVol),
                priceDecimal: marketItem.PRICE_PRECISION,
                volumeDecimal: marketItem.VOLUME_PRECISION,
            }));
            MarketData.push(receiveMarketData);
            if (data) {
                currencyData = receiveMarketData;
            }
            const pairNames = data
                .filter((x) => x.Last.toLowerCase() === activeMarketTab.toLowerCase())
                .map((item) => item.PairName.toLowerCase())
                .filter((value, index, self) => self.indexOf(value) === index);
            list = receiveMarketData.filter((x) => {
                return pairNames.includes(x.PairSymbol.toLowerCase());
            });
            setLoading(false);
            if (activeMarketTab == "favorite") {
                const hotListSymbols = favouriteList.map((item) => item.PairSymbol);
                const filteredMarkets = receiveMarketData.filter((market) => hotListSymbols.includes(market.PairSymbol));
                const combinedMarkets = [...filteredMarkets];
                setReceiveMarket(combinedMarkets);
            } else {
                setReceiveMarket(list);
            }
        } catch (e) {
            // console.error(e);
        }
    };

    const filteredCoinData = receiveMarket.filter(
        (item) =>
            item.FirstSymbol.toLowerCase().includes(marketSearchQuery.toLowerCase()) ||
            item.LastSymbol.toLowerCase().includes(marketSearchQuery.toLowerCase()) ||
            item.PairSymbol.toLowerCase().includes(marketSearchQuery.toLowerCase())
    );

    // --------------------- Market Search Function -------------

    // ---------------------- BUY/SELL ---------------------------

    //  ---------------------- Place Order ------------------------

    const handlePlaceOrder = (e, type) => {
        const value = e.target.value;
        var regex;
        if (type === "price") {
            regex = new RegExp(`^\\d{0,9}(\\.\\d{0,${priceDecimal}})?$`);
        } else {
            regex = new RegExp(`^\\d{0,9}(\\.\\d{0,${volumeDecimal}})?$`);
        }
        if (regex.test(value)) {
            const parsedValue = parseFloat(value);
            if (!isNaN(parsedValue)) {
                if (type === "price") {
                    setPriceBuySell(value);
                } else {
                    setSizeAmount(value);
                }
            } else {
                if (type === "price") {
                    setPriceBuySell();
                } else {
                    setSizeAmount();
                }
            }
        }
    };

    const OpenMoalpopup = () => {
        if (!isLogin) {
            toast.error("set value user authentication or not");
            return;
        }
        if (allOrders.length > 0) {
            return;
        }
        // if (!docVerify)  toast.error('Please upload your valid kyc to get verified first.'); return;  //-- set comment
        if (!currentUserData.isTrading) {
            toast.error("Your can not trade temporarily.");
            return;
        }
        // if (!is2FA) toast.error('Please activate 2FA to secure your account and trade.'); return;  // -- set comment

        if (currentUserData.isfreeze) toast.error("Your account is FREEZED."); // call userLogout method in side if
        setSizeAmount(parseFloat(sizeAmount));
        if (activeTab == "buy") {
            setPriceBuySell(activeLimitTab == "market" ? currentBuyPrice : limitPrice);
            setTotalBuySell(activeLimitTab == "market" ? Makettotalbuy : totalBuySell);

            if (parseFloat(totalBuySell) < parseFloat(mintotal)) {
                toast.error("Total buy order must be >=" + parseFloat(mintotal));
                return;
            }

            if ((parseFloat(minprice) > parseFloat(priceBuySell) && parseFloat(minprice) > 0) || (parseFloat(maxprice) < parseFloat(priceBuySell) && parseFloat(maxprice) > 0)) {
                toast.error("Order price must be between " + parseFloat(minprice) + " and " + parseFloat(maxprice));
                return;
            }

            if ((parseFloat(minsize) > parseFloat(sizeAmount) && parseFloat(minsize) > 0) || (parseFloat(maxsize) < parseFloat(sizeAmount) && parseFloat(maxsize) > 0)) {
                toast.error("Order size must be between " + parseFloat(minsize) + " and " + parseFloat(maxsize));
                return;
            }

            if (parseFloat(totalBuySell) > parseFloat(ExcurrBalance)) {
                toast.error("In-sufficient funds.");
                return;
            }
        } else {
            setPriceBuySell(activeLimitTab == "market" ? currentSellPrice : limitPrice);
            setTotalBuySell(activeLimitTab == "market" ? Makettotalsell : totalBuySell);

            if (parseFloat(totalBuySell) < parseFloat(mintotal)) {
                toast.error("Total sell order must be >=" + parseFloat(mintotal).toFixed(8));
                return;
            }

            if ((parseFloat(minprice) > parseFloat(priceBuySell) && parseFloat(minprice) > 0) || (parseFloat(maxprice) < parseFloat(priceBuySell) && parseFloat(maxprice) > 0)) {
                toast.error("Order price must be between " + parseFloat(minprice) + " and " + parseFloat(maxprice));
                return;
            }

            if ((parseFloat(minsize) > parseFloat(sizeAmount) && parseFloat(minsize) > 0) || (parseFloat(maxsize) < parseFloat(sizeAmount) && parseFloat(maxsize) > 0)) {
                toast.error("Order size must be between " + parseFloat(minsize) + " and " + parseFloat(maxsize));
                return;
            }

            if (parseFloat(sizeAmount) > parseFloat(ExcoinBalance)) {
                toast.error("In-sufficient funds.");
                return;
            }
        }

        allOrders = [
            {
                buysell: activeTab,
                orderprice: priceBuySell,
                volume: sizeAmount,
                orderType: activeLimitTab,
                stopPrice: stopPrice,
            },
        ];
        setSubmitOrderData();
    };

    const setSubmitOrderData = () => {
        var data = {
            buy_sell: allOrders[0].buysell,
            order_price: allOrders[0].orderprice,
            order_size: allOrders[0].volume,
            stop_limit_price: allOrders[0].orderType === "limit" ? 0 : allOrders[0].stopPrice,
            order_type: allOrders[0].orderType,
            pair: baseCurrency + quoteCurrency,
        };
        let placeOrderDetail = new Promise((resolve) => {
            resolve(PostTokenApi(API_Path.placeOrder, data));
        });
        placeOrderDetail.then((res) => {
            if (res.data.Isvalid === true) {
                toast.success(`Order to ${allOrders[0].buysell} ${baseCurrency}/${quoteCurrency} Created/Executed at ${allOrders[0].orderprice}`);
            } else {
                toast.error(res.data.Errors[0]);
            }
            allOrders = [];
            setPriceBuySell(0);
            setSizeAmount(0);
        });
    };

    const handlePlaceOrderData = (data) => {
        setPriceBuySell(data.price);
        setSizeAmount(expoStrToNumStr(data.volume));
        setTotalBuySell(expoStrToNumStr(data.total));
    };

    useEffect(() => {
        if (activeLimitTab === "market") {
            if (activeTab === "sell") {
                if (!isNaN(parseFloat(currentSellPrice)) && !isNaN(parseFloat(sizeAmount))) {
                    setMakettotalsell(
                        isNaN(parseFloat(parseFloat(currentSellPrice) * parseFloat(sizeAmount)).toFixed(8)) ? 0 : parseFloat(parseFloat(currentSellPrice) * parseFloat(sizeAmount)).toFixed(8)
                    );
                } else {
                    setMakettotalsell(0);
                }
            } else {
                if (!isNaN(parseFloat(currentBuyPrice)) && !isNaN(parseFloat(sizeAmount))) {
                    setMakettotalbuy(
                        isNaN(parseFloat(parseFloat(currentBuyPrice) * parseFloat(sizeAmount)).toFixed(8)) ? 0 : parseFloat(parseFloat(currentBuyPrice) * parseFloat(sizeAmount)).toFixed(8)
                    );
                } else {
                    setMakettotalbuy(0);
                }
            }
        } else {
            if (!isNaN(parseFloat(sizeAmount)) && !isNaN(parseFloat(priceBuySell))) {
                setTotalBuySell(isNaN(parseFloat(sizeAmount) * parseFloat(priceBuySell)) ? 0 : parseFloat(parseFloat(sizeAmount) * parseFloat(priceBuySell)).toFixed(8));
                if (activeLimitTab === "Stop-limit" && activeTab === "sell") {
                    setTotalBuySell(isNaN(parseFloat(stopPrice) * parseFloat(priceBuySell)) ? 0 : parseFloat(parseFloat(stopPrice) * parseFloat(priceBuySell)).toFixed(8));
                }
            } else {
                setTotalBuySell(0);
            }
        }
    }, [priceBuySell, sizeAmount]);

    // -------------------- RECVEIVE ALL ORDERS ------------------

    const Receiveallorders = () => {
        socket.on("Receiveallorders", (data) => {
            try {
                if (data !== null) {
                    if (data.orders !== undefined && data.orders !== null) {
                        exOpen = data.orders.filter((x) => x.STATUS.toLowerCase() === "pending");
                        exFill = data.orders.filter((x) => x.STATUS.toLowerCase() === "executed" || x.STATUS.toLowerCase() === "cancelled");
                        exTradeHis = data.orders.filter((x) => x.STATUS.toLowerCase() === "executed");
                    } else {
                        exOpen = [];
                        exFill = [];
                        exTradeHis = [];
                    }
                }
                setShowLoaderOpenOrder(false);
                setShowLoaderOrderHistory(false);
            } catch (ex) {
                console.log("Exception:", ex.toString());
            }
        });
        return () => {
            // Cleanup function to close WebSocket connection
            socket.off("Receiveallorders");
        };
    };

    // -------------------- receive Trade Histroy -----------------

    const receiveTradeHistroy = () => {
        socket.on("ReceiveTradeHistory", (data) => {
            try {
                if (data !== null) {
                    if (data !== undefined && data.length > 0 && data[0].symbol === baseCurrency + quoteCurrency) {
                        setTrade(data);
                        setCurrHisPrice(data[0].price);
                        if (data.length >= 2) {
                            if (data[0].price > data[1].price) {
                                setIsUp(true);
                                setIsDown(false);
                                setHisColor("green_color");
                                setIssame(false);
                            } else if (data[0].price < data[1].price) {
                                setIsUp(false);
                                setIsDown(true);
                                setHisColor("red_color");
                                setIssame(false);
                            } else {
                                setIsUp(false);
                                setIsDown(false);
                                setHisColor("white_color");
                                setIssame(true);
                            }
                        } else {
                            setIsUp(false);
                            setIsDown(false);
                            setHisColor("white_color");
                            setIssame(true);
                        }
                    } else {
                        setTrade([]);
                        setCurrHisPrice(0);
                        setIsUp(false);
                        setIsDown(false);
                        setHisColor("white_color");
                        setIssame(false);
                    }
                }
            } catch (ex) {
                console.log("Exception:", ex.toString());
            }
        });
        return () => {
            // Cleanup function to close WebSocket connection
            socket.off("ReceiveTradeHistory");
        };
    };

    // -------------------- RECVEIVE ORDER BOOK ------------------
    const receiveOrderbook = () => {
        socket.on("ReceiveOrderBook", (data) => {
            try {
                setCurrentSellPrice(0);
                setCurrentBuyPrice(0);

                if (data.symbol === baseCurrency + quoteCurrency) {
                    if (data.aggAsks !== undefined && data.aggAsks.length > 12) {
                        setBookSell(data.aggAsks);
                        if (!isNaN(parseFloat(data.aggAsks[data.aggAsks.length - 1].price))) {
                            setCurrentBuyPrice(data.aggAsks[data.aggAsks.length - 1].price);
                        }
                    }

                    if (data.aggBids !== undefined && data.aggBids.length > 12) {
                        setBookBuy(data.aggBids);
                        if (!isNaN(parseFloat(data.aggBids[0].price))) {
                            setCurrentSellPrice(data.aggBids[0].price);
                        }
                    }
                    setShowLoaderBookSell(false);
                    setShowLoaderBookBuy(false);
                }
            } catch (ex) {
                console.log("Exception:", ex.toString());
            }
        });
        return () => {
            // Cleanup function to close WebSocket connection
            socket.off("ReceiveOrderBook");
        };
    };

    const order_data = [];

    const changeStateCoinPair = (value) => {
        const history = unstable_HistoryRouter();
        history.push(`/trading?coinpair=${value}`);
    };

    // ---------------------- Wallet Data -----------------
    const ReceiveWalletData = () => {
        socket.on("ReceiveWalletData", (data) => {
            try {
                if (data !== null) {
                    if (data !== undefined && data.wallet !== undefined && data.wallet.length > 0) {
                        userWallets = data.wallet;

                        const coinBalanceItem = data.wallet.find((x) => x.COIN.toLowerCase() === coinData.firstCoin.toLowerCase());
                        if (coinBalanceItem) {
                            setCoinBalance(coinBalanceItem.BALANCE);
                        }

                        const currbalanceItem = data.wallet.find((x) => x.COIN.toLowerCase() === coinData.baseCoin.toLowerCase());
                        if (currbalanceItem) {
                            setCurrBalance(currbalanceItem.BALANCE);
                        }
                    }

                    if (data !== undefined && data.fees !== undefined && data.fees.length > 0) {
                        setMakerFees(parseFloat(data.fees[0].maker_fees));
                        setTakerFees(parseFloat(data.fees[0].taker_fees));
                    }
                }
            } catch (ex) {
                console.log("Exception:", ex.toString());
            }
        });
        return () => {
            // Cleanup function to close WebSocket connection
            socket.off("ReceiveWalletData");
        };
    };

    const manegesocket = () => {
        var socketStatuss = socket.connected ? true : false;
        if (!socketStatuss) {
            setIsSocketCalled(true);
            socketConnect(false);
            socketConnect(true);
        } else {
            if (isSocketCalled) {
                setIsSocketCalled(false);
                setTimeout(() => {
                    // SendAllData();
                    SendSocket();
                }, 2000);
            }
        }
    };

    const socketConnect = (value) => {
        if (value) {
            socket = io(socketUrl, { autoConnect: false });
            socket.open();
        } else {
            if (socket) {
                socket.disconnect();
            }
        }
    };

    return (
        <>
            {isNotFound ? (
                <NotFound />
            ) : (
                <Layout>
                    {/* #region  trading */}
                    <div className={styles.trading}>
                        <div className={styles.trading_body}>
                            <div className={styles.pair_volume}>
                                {/* -- Start : VOLUME COUNT -- */}
                                {receiveData && (
                                    <div className={styles.volume}>
                                        <div className={styles.volume_cont}>
                                            <span>Last Price</span>
                                            <bdi className={hisColor}>{parseFloatAmt(receiveData[0]?.Price, receiveData[0]?.PRICE_PRECISION)}</bdi>
                                        </div>
                                        <div className={styles.volume_cont}>
                                            <span>24h Change</span>
                                            <bdi className={receiveData[0]?.TwentyHRPriceChangePrice > 0 ? "green_color" : "red_color"}>
                                                {parseFloatAmt(receiveData[0]?.TwentyHRPriceChangePrice, receiveData[0]?.PRICE_PRECISION)}
                                            </bdi>
                                        </div>
                                        <div className={styles.volume_cont}>
                                            <span>24h Vol.</span>
                                            <bdi className="ng-binding">{receiveData[0]?.TwentHRHigh ?? 0}</bdi>
                                        </div>
                                        <div className={styles.volume_cont}>
                                            <span>24h High</span>
                                            <bdi className="ng-binding">{receiveData[0]?.TwentHRLow ?? 0}</bdi>
                                        </div>
                                        <div className={styles.volume_cont}>
                                            <span>24h Low</span>
                                            <bdi className="ng-binding">{(receiveData[0]?.Last === "USDT" ? receiveData[0]?.USD_VOL : receiveData[0]?.BTC_VOL) + " " + receiveData[0]?.Last}</bdi>
                                        </div>
                                    </div>
                                )}
                                {/* -- End : VOLUME COUNT -- */}
                            </div>
                            <div className={styles.trading_page}>
                                <div className="row">
                                    <div className={`${styles.border_top_ex} p-0`}>
                                        <div className="d-flex">
                                            {/* -- Start : Order Book -- */}
                                            <div className={`${styles.trading_order} ${styles.col_lg_3} col_padd_none chart_col`}>
                                                <div className={styles.buy_sell_orderbook}>
                                                    <div className={styles.order_book_title}>
                                                        <h6 className={`${styles.title} mb-0 px-0`}>Order Book</h6>
                                                        <bdi>
                                                            <Link to="/orderbook">More</Link>
                                                        </bdi>
                                                    </div>
                                                    <div className={styles.trading_tbl_head}>
                                                        <ul className="d-flex align-items-center justify-content-between">
                                                            <li>Amount({baseCurrency})</li>
                                                            <li>Price({quoteCurrency})</li>
                                                            <li className="text-start">Total({baseCurrency})</li>
                                                        </ul>
                                                    </div>
                                                    <div className={showLoaderBookBuy && showLoaderBookSell ? styles.display_style : ""}>
                                                        {showLoaderBookBuy && showLoaderBookSell ? (
                                                            <Loader />
                                                        ) : (
                                                            <>
                                                                <div className={styles.trading_tbl_body}>
                                                                    <ul className={styles.orderbook_list}>
                                                                        {bookSell &&
                                                                            bookSell.length > 0 &&
                                                                            bookSell.map((items, i) => {
                                                                                return (
                                                                                    <li className="cursor-pointer" key={i} onClick={() => handlePlaceOrderData(items)}>
                                                                                        <p className="text-left">{expoStrToNumStr(items.volume)}</p>
                                                                                        <p className="red_color ps-0 text-center">{items.price}</p>
                                                                                        <p className="text-end">{expoStrToNumStr(items.totalBase)}</p>
                                                                                    </li>
                                                                                );
                                                                            })}
                                                                    </ul>
                                                                </div>
                                                                <div className={styles.mix_price}>
                                                                    {isUp && (
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="16"
                                                                            height="16"
                                                                            fill="#01BC2A"
                                                                            className="bi bi-arrow-up-short"
                                                                            viewBox="0 0 16 16"
                                                                        >
                                                                            <path
                                                                                fillRule="evenodd"
                                                                                d="M8 12a.5.5 0 0 0 .5-.5V5.707l2.146 2.147a.5.5 0 0 0 .708-.708l-3-3a.5.5 0 0 0-.708 0l-3 3a.5.5 0 1 0 .708.708L7.5 5.707V11.5a.5.5 0 0 0 .5.5"
                                                                            />
                                                                        </svg>
                                                                    )}
                                                                    {isDown && (
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="16"
                                                                            height="16"
                                                                            fill="#dc3545"
                                                                            className="bi bi-arrow-down-short"
                                                                            viewBox="0 0 16 16"
                                                                        >
                                                                            <path
                                                                                fillRule="evenodd"
                                                                                d="M8 4a.5.5 0 0 1 .5.5v5.793l2.146-2.147a.5.5 0 0 1 .708.708l-3 3a.5.5 0 0 1-.708 0l-3-3a.5.5 0 1 1 .708-.708L7.5 10.293V4.5A.5.5 0 0 1 8 4"
                                                                            />
                                                                        </svg>
                                                                    )}
                                                                    <b className={hisColor}>{currHisPrice}</b>
                                                                </div>
                                                                <div className={styles.trading_tbl_body}>
                                                                    <ul className={styles.orderbook_list}>
                                                                        {bookBuy.map((items, i) => {
                                                                            return (
                                                                                <li key={i} className="cursor-pointer" onClick={() => handlePlaceOrderData(items)}>
                                                                                    <p className="">{expoStrToNumStr(items.volume)}</p>
                                                                                    <p className="green_color ps-0 text-center">{items.price}</p>
                                                                                    <p className="text-end">{expoStrToNumStr(items.totalBase)}</p>
                                                                                </li>
                                                                            );
                                                                        })}
                                                                    </ul>
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* -- End : Order Book -- */}

                                            <div className={`${styles.trading_border} ${styles.col_lg_8}  col_padd_none chart_col border-bottom-0 p-0 border-top-0`}>
                                                <div>
                                                    <iframe width="100%" height="480" src={`${ApiBaseUrl}tradingview/${id}`} allowFullScreen></iframe>
                                                </div>

                                                {/* -- Start : Spot -- */}
                                                <div className={styles.spot}>
                                                    <div className={styles.type_coin_tab}>
                                                        <ul className={`${styles.tab} ${styles.navtabs}`}>
                                                            <li className={styles.navitem}>
                                                                <button
                                                                    className={`${styles.navlink} ${activeLimitTab === "limit" && styles.active} ms-0`}
                                                                    aria-current="page"
                                                                    onClick={() => handlelimitTabClick("limit")}
                                                                >
                                                                    Limit
                                                                </button>
                                                            </li>
                                                            <li className={styles.navitem}>
                                                                <button
                                                                    className={`${styles.navlink} ${activeLimitTab === "market" && styles.active} ms-0`}
                                                                    aria-current="page"
                                                                    onClick={() => handlelimitTabClick("market")}
                                                                    disabled
                                                                >
                                                                    Market
                                                                </button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-lg-6 col-md-6">
                                                            <div className={styles.spot_section}>
                                                                <div className={styles.price_area}>
                                                                    <div className={styles.price_form_area}>
                                                                        <Formik
                                                                            initialValues={{
                                                                                price: priceBuySell,
                                                                                amount: sizeAmount,
                                                                            }}
                                                                            validationSchema={Yup.object().shape({
                                                                                amount: Yup.number().required("Amount is required."),
                                                                                price: Yup.number().required("Price is required."),
                                                                            })}
                                                                            onSubmit={(values, { resetForm }) => {
                                                                                OpenMoalpopup(values, resetForm);
                                                                            }}
                                                                        >
                                                                            {(runform) => (
                                                                                <Form onSubmit={runform.handleSubmit}>
                                                                                    <div className={styles.from_field}>
                                                                                        <label>Price</label>
                                                                                        <div className={styles.input_field}>
                                                                                            <input
                                                                                                type="number"
                                                                                                placeholder=""
                                                                                                max="10"
                                                                                                name="price"
                                                                                                value={activeTab === "buy" && priceBuySell}
                                                                                                onChange={(e) => {
                                                                                                    handlePlaceOrder(e, "price");
                                                                                                    setActiveTab("buy");
                                                                                                    const parsedValue = parseFloat(e.target.value);
                                                                                                    if (!isNaN(parsedValue)) {
                                                                                                        runform.setFieldValue("price", parsedValue);
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                            <span>{coinData.firstCoin}</span>
                                                                                        </div>
                                                                                        {errorContainer(runform, "price")}
                                                                                    </div>
                                                                                    <div className={styles.from_field}>
                                                                                        <label>Amount</label>
                                                                                        <div className={styles.input_field}>
                                                                                            <input
                                                                                                type="number"
                                                                                                placeholder=""
                                                                                                value={activeTab === "buy" && sizeAmount}
                                                                                                name="amount"
                                                                                                onChange={(e) => {
                                                                                                    handlePlaceOrder(e, "amount", "buy");
                                                                                                    setActiveTab("buy");
                                                                                                    const parsedValue = parseFloat(e.target.value);
                                                                                                    if (!isNaN(parsedValue)) {
                                                                                                        runform.setFieldValue("amount", parsedValue);
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                            <span>{coinData.firstCoin}</span>
                                                                                        </div>
                                                                                        {errorContainer(runform, "amount")}
                                                                                    </div>
                                                                                    {/* <div className={styles.box_field}>
                                                                                <div className={styles.box_row}>
                                                                                    <div className={styles.box_content}>
                                                                                        <h2>25%</h2>
                                                                                    </div>
                                                                                    <div className={styles.box_content}>
                                                                                        <span>50%</span>
                                                                                    </div>
                                                                                    <div className={styles.box_content}>
                                                                                        <span>75%</span>
                                                                                    </div>
                                                                                    <div className={styles.box_content}>
                                                                                        <span>100%</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div> */}
                                                                                    <div className={styles.from_field}>
                                                                                        <label>Total</label>
                                                                                        <div className={styles.input_field}>
                                                                                            <input
                                                                                                type="number"
                                                                                                placeholder=""
                                                                                                style={{ cursor: "no-drop" }}
                                                                                                value={activeTab === "buy" && (activeLimitTab === "market" ? markettotalbuy : totalBuySell)}
                                                                                                name="total"
                                                                                                readOnly
                                                                                            />
                                                                                            <span>{coinData.baseCoin}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className={styles.spot_section_tabs}>
                                                                                        <ul className={`${styles.tab} ${styles.navtabs}`}>
                                                                                            <li className={styles.navitem}>
                                                                                                {FunexAuthorizationData !== null ? (
                                                                                                    <button type="submit" className={styles.navlinktab} onClick={() => setActiveTab("buy")}>
                                                                                                        BUY
                                                                                                    </button>
                                                                                                ) : (
                                                                                                    <button type="button" className={styles.navlinktab} onClick={handleLogIN}>
                                                                                                        Log In
                                                                                                    </button>
                                                                                                )}
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </Form>
                                                                            )}
                                                                        </Formik>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6">
                                                            <div className={styles.spot_section}>
                                                                <div className={styles.price_area}>
                                                                    <div className={styles.price_form_area}>
                                                                        <Formik
                                                                            initialValues={{
                                                                                price: priceBuySell,
                                                                                amount: sizeAmount,
                                                                            }}
                                                                            validationSchema={Yup.object().shape({
                                                                                amount: Yup.number().required("Amount is required."),
                                                                                price: Yup.number().required("Price is required."),
                                                                            })}
                                                                            onSubmit={(values, { resetForm }) => {
                                                                                OpenMoalpopup(values, resetForm);
                                                                            }}
                                                                        >
                                                                            {(runform) => (
                                                                                <Form onSubmit={runform.handleSubmit}>
                                                                                    <div className={styles.from_field}>
                                                                                        <label>Price</label>
                                                                                        <div className={styles.input_field}>
                                                                                            <input
                                                                                                type="number"
                                                                                                placeholder=""
                                                                                                max="10"
                                                                                                value={activeTab === "sell" && priceBuySell}
                                                                                                name="price"
                                                                                                onChange={(e) => {
                                                                                                    handlePlaceOrder(e, "price", "sell");
                                                                                                    setActiveTab("sell");
                                                                                                    const parsedValue = parseFloat(e.target.value);
                                                                                                    if (!isNaN(parsedValue)) {
                                                                                                        runform.setFieldValue("price", parsedValue);
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                            <span>{coinData.firstCoin}</span>
                                                                                        </div>
                                                                                        {errorContainer(runform, "price")}
                                                                                    </div>
                                                                                    <div className={styles.from_field}>
                                                                                        <label>Amount</label>
                                                                                        <div className={styles.input_field}>
                                                                                            <input
                                                                                                type="number"
                                                                                                placeholder=""
                                                                                                maxLength={10}
                                                                                                value={activeTab === "sell" && sizeAmount}
                                                                                                name="amount"
                                                                                                onChange={(e) => {
                                                                                                    handlePlaceOrder(e, "amount", "sell");
                                                                                                    setActiveTab("sell");
                                                                                                    const parsedValue = parseFloat(e.target.value);
                                                                                                    if (!isNaN(parsedValue)) {
                                                                                                        runform.setFieldValue("amount", parsedValue);
                                                                                                    }
                                                                                                }}
                                                                                            />
                                                                                            <span>{coinData.baseCoin}</span>
                                                                                        </div>
                                                                                        {errorContainer(runform, "amount")}
                                                                                    </div>
                                                                                    {/* <div className={styles.box_field}>
                                                                                <div className={styles.box_row}>
                                                                                    <div className={styles.box_content}>
                                                                                        <h2>25%</h2>
                                                                                    </div>
                                                                                    <div className={styles.box_content}>
                                                                                        <span>50%</span>
                                                                                    </div>
                                                                                    <div className={styles.box_content}>
                                                                                        <span>75%</span>
                                                                                    </div>
                                                                                    <div className={styles.box_content}>
                                                                                        <span>100%</span>
                                                                                    </div>
                                                                                </div>
                                                                            </div> */}
                                                                                    <div className={styles.from_field}>
                                                                                        <label>Total</label>
                                                                                        <div className={styles.input_field}>
                                                                                            <input
                                                                                                type="number"
                                                                                                placeholder=""
                                                                                                style={{ cursor: "no-drop" }}
                                                                                                value={activeTab === "sell" && (activeLimitTab === "market" ? makettotalsell : totalBuySell)}
                                                                                                name="total"
                                                                                                readOnly
                                                                                            />
                                                                                            <span>{coinData.baseCoin}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className={styles.spot_section_tabs}>
                                                                                        <ul className={`${styles.tab} ${styles.navtabs}`}>
                                                                                            <li className={styles.navitem}>
                                                                                                {FunexAuthorizationData !== null ? (
                                                                                                    <button type="submit" className={styles.navlink} onClick={() => setActiveTab("sell")}>
                                                                                                        SELL
                                                                                                    </button>
                                                                                                ) : (
                                                                                                    <button type="button" className={styles.navlink} onClick={handleLogIN}>
                                                                                                        Log In
                                                                                                    </button>
                                                                                                )}
                                                                                            </li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </Form>
                                                                            )}
                                                                        </Formik>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                {/*  -- End : Spot --*/}
                                            </div>

                                            <div className={`${styles.border_left} ${styles.col_lg_3}  col_padd_none chart_col`}>
                                                {/* -- Start : Market Trades -- */}
                                                {/* market trade */}

                                                <div className={`markeT_coin_hov position-relative`}>
                                                    <div className={styles.fav_search}>
                                                        <input
                                                            type="search"
                                                            id="MarketSearch"
                                                            name="MarketSearch"
                                                            placeholder="Search"
                                                            ng-model="marketSearch"
                                                            onChange={(e) => setMarketSearchQuery(e.target.value)}
                                                        />
                                                        <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M12.475 12.2499L9.93018 9.7124" stroke="#404040" strokeLinecap="round" strokeLinejoin="round" />
                                                            <path
                                                                d="M6.62441 11.0833C9.20915 11.0833 11.3045 8.994 11.3045 6.41667C11.3045 3.83934 9.20915 1.75 6.62441 1.75C4.03968 1.75 1.94434 3.83934 1.94434 6.41667C1.94434 8.994 4.03968 11.0833 6.62441 11.0833Z"
                                                                stroke="#404040"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            />
                                                        </svg>
                                                    </div>
                                                    <div className={`${styles.order_coin_tab} ${styles.market_coin_tab}`}>
                                                        <ul className={`${styles.tab} ${styles.navtabs}`}>
                                                            {marketTabs &&
                                                                marketTabs.map((item, i) => (
                                                                    <li className={styles.navitem} key={i}>
                                                                        <button
                                                                            className={`${styles.navlink} ${activeMarketTab == item.TabTitle && styles.active} ms-0`}
                                                                            aria-current="page"
                                                                            onClick={() => {
                                                                                handleMarketTabClick(item.TabTitle);
                                                                                setLoading(true);
                                                                            }}
                                                                        >
                                                                            {item.TabTitle}
                                                                        </button>
                                                                    </li>
                                                                ))}
                                                            <li className={styles.navitem}>
                                                                <button
                                                                    className={`${styles.navlink} ${activeMarketTab === "favorite" && styles.active} ms-0`}
                                                                    aria-current="page"
                                                                    onClick={(e) => {
                                                                        handleMarketTabClick("favorite");
                                                                        setLoading(true);
                                                                        e.stopPropagation();
                                                                    }}
                                                                >
                                                                    Hot List
                                                                </button>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <div className={`${styles.trading_tabel_head} ${styles.market_tbe}`}>
                                                        <ul className="d-flex align-items-center">
                                                            <li>Pair</li>
                                                            <li>Last Price</li>
                                                            <li>Change</li>
                                                            {/* <li className="text-start">24h Vol</li> */}
                                                        </ul>
                                                    </div>

                                                    <div className={styles.trading_tabel_body}>
                                                        <ul className={`${styles.order_book_style} ${loading ? "d-flex justify-content-center" : ""}`}>
                                                            {loading ? (
                                                                <Loader />
                                                            ) : receiveMarket && receiveMarket.length > 0 && filteredCoinData.length > 0 ? (
                                                                filteredCoinData.map((item, i) => (
                                                                    <li key={i} className="cursor-pointer" onClick={() => handleClickCoin(item.FirstSymbol, item.LastSymbol)}>
                                                                        <div className={styles.market_tab_body}>
                                                                            <div className="px-0 d-flex align-items-center">
                                                                                {favouriteList.some((likedItem) => likedItem.PairSymbol === item.PairSymbol) ? (
                                                                                    <span
                                                                                        className={styles.dislike}
                                                                                        onClick={(e) => {
                                                                                            handleDisFavoriteCoin(item.PairSymbol);
                                                                                            e.stopPropagation();
                                                                                        }}
                                                                                    >
                                                                                        ★
                                                                                    </span>
                                                                                ) : (
                                                                                    <span
                                                                                        className={styles.like}
                                                                                        onClick={(e) => {
                                                                                            handleFavoriteCoin(item.PairSymbol);
                                                                                            e.stopPropagation();
                                                                                        }}
                                                                                    >
                                                                                        ☆
                                                                                    </span>
                                                                                )}
                                                                                {item.ShowPairSymbol}
                                                                            </div>
                                                                            <div className="px-0">{parseFloatAmt(item.LastPrice, item.priceDecimal)}</div>
                                                                            <div className={`${item.Change >= 0 ? "green_color" : "red_color"} px-0"`}>{item.ChangePercent}%</div>
                                                                            {/* <div>{parseFloatAmt(item.Volume, item.priceDecimal)}</div> */}
                                                                        </div>
                                                                    </li>
                                                                ))
                                                            ) : (
                                                                <div className={`${styles.empty_record} ${styles.empty_record_exchange} py-3`}>
                                                                    <img src={emptyRecordImg} alt="" />
                                                                    <span>No records found</span>
                                                                </div>
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>

                                                <div className={styles.buy_market_trades}>
                                                    <div className={styles.market_trade_tile}>
                                                        <h6 className={`${styles.title} mb-0 p-0`}>Market Trades</h6>
                                                    </div>
                                                    <div className={styles.trading_tbl_head}>
                                                        <ul className="d-flex align-items-center">
                                                            <li>Price({quoteCurrency})</li>
                                                            <li>Amount({baseCurrency})</li>
                                                            <li className="text-end">Time(UTC)</li>
                                                        </ul>
                                                    </div>
                                                    <div className={`${styles.trading_tbl_body} ${styles.auto_height} ${trade.length < 0 ? styles.display_style : ""}`}>
                                                        <ul>
                                                            {trade && trade.length > 0 ? (
                                                                trade.map((Trd, i) => (
                                                                    <li key={i}>
                                                                        <p className={Trd.side == "buy" ? "green_color" : "red_color"}>{expoStrToNumStr(Trd.price)}</p>
                                                                        <p className="">{expoStrToNumStr(Trd.volume)}</p>
                                                                        <p className="text-end">{Trd.time}</p>
                                                                    </li>
                                                                ))
                                                            ) : (
                                                                <div className="p-3">
                                                                    <div className={`${styles.empty_record} ${styles.empty_record_exchange}`}>
                                                                        <img src={DataNotFound} alt="" />
                                                                        <span>No records found</span>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </ul>
                                                    </div>
                                                </div>
                                                {/* -- End : Market Trades -- */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* -- Start: Order -- */}
                    <div className={styles.tbl_fav}>
                        <div className={`${styles.order_coin_tab} pt-0 pb-0`}>
                            <ul className={`${styles.tab} ${styles.navtabs}`}>
                                <li className={styles.navitem}>
                                    <button className={`${styles.navlink} ${activeOrderTab === "open" && styles.active} ms-0`} aria-current="page" onClick={() => handleOrderTabClick("open")}>
                                        Open Order
                                    </button>
                                </li>
                                <li className={styles.navitem}>
                                    <button className={`${styles.navlink} ${activeOrderTab === "complete" && styles.active} ms-0`} aria-current="page" onClick={() => handleOrderTabClick("complete")}>
                                        Completed Order
                                    </button>
                                </li>
                                <li className={styles.navitem}>
                                    <button className={`${styles.navlink} ${activeOrderTab === "trad" && styles.active} ms-0`} aria-current="page" onClick={() => handleOrderTabClick("trad")}>
                                        Trade History
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div className={`${styles.buy_market_trades} border-0`}>
                            <div className={styles.trading_tbal_head}>
                                <ul className="d-flex align-items-center">
                                    <li>Date</li>
                                    <li>pair</li>
                                    <li>Type</li>
                                    <li>Price</li>
                                    <li>Amount</li>
                                    <li>Total</li>
                                    {activeOrderTab != "open" && <li>Deal Price</li>}
                                    {activeOrderTab != "open" && <li>Deal Amount</li>}
                                    {activeOrderTab != "open" && <li>Fees</li>}
                                    {activeOrderTab === "complete" && <li>Status</li>}
                                    {activeOrderTab != "open" && <li>Paid/Get Paid</li>}
                                    {activeOrderTab === "open" && <li>Action</li>}
                                </ul>
                            </div>
                            <div className={styles.trading_tbal_body}>
                                {activeOrderTab === "open" && (
                                    <ul>
                                        {exOpen.length < 0 ? (
                                            exOpen.map((items, i) => (
                                                <li key={i}>
                                                    <p className="red_color">{items.ENT_DATE}</p>
                                                    <p>{baseCurrency + quoteCurrency}</p>
                                                    <p>{items.ORDER_TYPE}</p>
                                                    <p className="red_color">{items.ORDER_PRICE}</p>
                                                    <p>{items.ORDER_SIZE}</p>
                                                    <p>{items.TOTAL_PRICE}</p>
                                                </li>
                                            ))
                                        ) : (
                                            <div className={styles.data_not_found}>
                                                <img src={emptyRecordImg} alt="" className={`${styles.img_fluid} img-fluid`} />
                                                <span className="my-2">No records found</span>
                                            </div>
                                        )}
                                    </ul>
                                )}
                                {activeOrderTab === "complete" && (
                                    <ul>
                                        {exFill.length < 0 ? (
                                            exFill.map((items, i) => (
                                                <li key={i}>
                                                    <p>{items.UPD_DATE}</p>
                                                    <p>{items.ORDER_TYPE}</p>
                                                    <p className="red_color">{items.ORDER_PRICE}</p>
                                                    <p>{items.ORDER_SIZE}</p>
                                                    <p>{items.TOTAL_PRICE}</p>
                                                    <p>{items.EXECUTED_PRICE}</p>
                                                    <p>{items.DEAL_AMOUNT}</p>
                                                    <p>{items.FEES}</p>
                                                    <p>{items.FINAL_TOTAL}</p>
                                                    <p>{items.STATUS == "executed" ? "Executed" : "Cancelled"}</p>
                                                    <p>{items.FINAL_TOTAL}</p>
                                                </li>
                                            ))
                                        ) : (
                                            <div className={styles.data_not_found}>
                                                <img src={emptyRecordImg} alt="" className={`${styles.img_fluid} img-fluid`} />
                                                <span className="my-2">No records found</span>
                                            </div>
                                        )}
                                    </ul>
                                )}
                                {activeOrderTab === "trad" && (
                                    <ul>
                                        {activeOrderTab === "" && exTradeHis.length < 0 ? (
                                            exTradeHis.map((items, i) => (
                                                <li key={i}>
                                                    <p>{items.UPD_DATE}</p>
                                                    <p>{items.ORDER_TYPE}</p>
                                                    <p className="red_color">{items.ORDER_PRICE}</p>
                                                    <p>{items.ORDER_SIZE}</p>
                                                    <p>{items.TOTAL_PRICE}</p>
                                                    <p>{items.EXECUTED_PRICE}</p>
                                                    <p>{items.DEAL_AMOUNT}</p>
                                                    <p>{items.FEES}</p>
                                                    <p>{items.FINAL_TOTAL}</p>
                                                </li>
                                            ))
                                        ) : (
                                            <div className={styles.data_not_found}>
                                                <img src={emptyRecordImg} alt="" className={`${styles.img_fluid} img-fluid`} />
                                                <span className="my-2">No records found</span>
                                            </div>
                                        )}
                                    </ul>
                                )}
                            </div>
                        </div>
                    </div>
                    {/* -- End: order --  */}

                    {/* #region  trading */}
                </Layout>
            )}
        </>
    );
}
