import React from 'react';
import styles from '../styles/apitermsofservice.module.css';
import { Link } from 'react-router-dom';
import { baseName } from '../const';
import Layout from '../components/Layout/Layout';

export default function ApitermsOfService() {
    return (
        <Layout>
            <section className={styles.section}>
                <div className='container'>
                    <div className={styles.section_body}>
                        <div className='title_heading d-flex'>
                            {/* <p>Explore /</p> */}
                            <h4 className='ps-0'>API Terms Of Service</h4>
                        </div>
                        <div className={styles.section_content}>
                            <p>The subsequent terms and conditions given here govern your usage or access of our “{baseName.toUpperCase()} API Services”. Our APIs can be accessed easily from <Link to="https://exchange-api.funexcoin.com/" target='_blank'>https://exchange-api.funexcoin.com</Link> </p>
                            <div className={styles.pere_content_box}>
                                <h2>1. {baseName.toUpperCase()} API DOCUMENTATION & USAGE</h2>
                                <b>How To Get Access To {baseName} APIs ?</b>
                                <p>To access or start the usage of our {baseName.toLowerCase()} APIs, it is mandatory to create or register for an account on {baseName.toLowerCase()} platform. Additionally, you shall require API Key or an authentication key to get access to our platform via APIs. For security reasons, NEVER SHARE your API Key or other account related information with anyone. {baseName.toLowerCase()} reserves the right to block or disable API access to any user account without prior notice.</p>
                                <b>API Usage License</b>
                                <p>These terms of service hereby give you revocable, non-transferable and non-exclusive license for using {baseName.toLowerCase()} APIs for personal, non-commercial use. Software and application developers and other commercial users shall be required to sign a separate agreement for using {baseName.toLowerCase()} APIs.</p>
                                <b>API Limitations</b>
                                <p>{baseName} reserve its rights to restrict the number of API calls that can be served to a user at any point.</p>
                                <b>APIs Upgradation & Changes</b>
                                <p>{baseName} reserve its rights over making certain changes in our {baseName.toLowerCase()} APIs and related documentation on a regular basis without any prior notice. As we review and update our platform periodically, it might require us to implement changes in APIs, too.</p>
                            </div>
                            <div className={styles.pere_content_box}>
                                <h2>2. {baseName.toUpperCase()} API USAGE CONSTRAINTS</h2>
                                <p>The usage or access to our {baseName.toLowerCase()} API services is restricted to the purpose specified in these ‘Terms of Service’. Hence, you are not allowed to :</p>
                                <p>Develop any particular application that is an exact replica, duplicitous copy of our apps or platforms, trespasses any of the third party’s intellectual property rights, contains a virus or malicious code, offensive material or violate relevant laws.</p>
                                <p>Reverse engineer, decompile or modify the functionalities of our {baseName.toLowerCase()} APIs.</p>
                                <p>Use our {baseName.toLowerCase()} APIs in a manner that can affect stability, security and functionality of our APIs and platform.</p>
                            </div>
                            <div className={styles.pere_content_box}>
                                <h2>3. API DATA</h2>
                                <p>As a user you are granted limited, non-exclusive, non-transferable access to our APIs for personal, non-commercial use. Hence you agree to use the data received or transmitted during {baseName.toLowerCase()} API usage as follows :</p>
                                <p>You shall not use any {baseName.toLowerCase()} data that contains private or personal information.</p>
                                <p>You shall not use our APIs to aggregate, collect and re-distribute the data.</p>
                                <p>You shall delete all the {baseName.toLowerCase()} data that is no longer needed by you.</p>
                            </div>
                            <div className={styles.pere_content_box}>
                                <h2>4. MONITORING API USAGE</h2>
                                <p>You understand and acknowledge that we are authorized to track and monitor your API usage.</p>
                            </div>
                            <div className={styles.pere_content_box}>
                                <h2>5. CONFIDENTIALITY OF COMMUNICATION</h2>
                                <p>All communication made by you during the usage of our {baseName.toLowerCase()} API transmits certain personal data or information over the internet, including some open, public networks. While using our APIs, we do not explicitly guarantee or provide assurance of keeping your communication private, secure or safe.</p>
                            </div>
                            <div className={styles.pere_content_box}>
                                <h2>6. OWNERSHIP RIGHTS</h2>
                                <p>All the {baseName.toLowerCase()} API data, material or documentation received by you hereunder are part of a limited, non-exclusive, non-transferable, revocable license and not sold to you in any manner. All the rights that are explicitly granted to you for the usage of our APIs are principally owned by {baseName.toLowerCase()}.</p>
                            </div>
                            <div className={styles.pere_content_box}>
                                <h2>7. INDEMNITIES & WARRANTIES</h2>
                                <b>Transferred Data Representation</b>
                                <p>You hereby acknowledge that all the transmitted data via our APIs from your end will contain information which is collected post getting consent or permission from concerned third-parties.</p>
                                <b>Indemnification</b>
                                <p>YOU HEREBY AGREE TO INDEMNIFY AND HOLD {baseName.toUpperCase()}, ITS OFFICERS, EMPLOYEES, AGENTS AND AFFILIATES HARMLESS INCLUDING FROM COSTS AND ATTORNEY'S FEES, FROM ANY CLAIM OR DEMAND MADE BY YOU OR ANY THIRD PARTY DUE TO OR ARISING OUT OF (A) YOUR USE OF THE SERVICES (B) YOUR VIOLATION OF THIS AGREEMENT (C) YOUR VIOLATION OF APPLICABLE LAWS AND REGULATIONS (D) THIRD PARTY SERVICES.</p>
                            </div>
                            <div className={styles.pere_content_box}>
                                <h2>8. MISCELLANEOUS</h2>
                                <p>The "API - Terms of Service" given here together with our "Terms Of Use" and our "Privacy Policy" comprises an entire, valid agreement between both parties in relation to the use of {baseName.toLowerCase()} services. This Agreement supercedes all previous ones entered between the concerned parties. No other document signed between the parties can modify, supplement or alter the terms which are part of this Agreement.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}
