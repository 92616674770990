import React, { useEffect, useState } from "react";
import styles from "../../styles/profile.module.css";
import { GetTokenApi } from "../../api/api-service";
import { API_Path } from "../../const";
import { toast } from "react-toastify";
import DataTableComponents from "../data_Table/DataTableComponents";
import moment from "moment";
import {  exportTableToExcel, exportTableToPDF, maxDateString } from "../common/CommonFun";
import { Table } from "react-bootstrap";
import NoDataFoundImg from "../nodatafound/NoDataFoundImg";
import Loading from "../loader/Loading";

export default function LoginHistory() {
    const [loading, setLoading] = useState(false);
    const [userLoginData, setUserLoginData] = useState([]);
    const [totleRecode, setTotleRecode] = useState(25);
    const [date, setDate] = useState({ fromDate: "", toDate: "" });
    const [searchQuery, setSearchQuery] = useState("");

    const filteredLoginHistory = userLoginData?.filter((item) => String(item.location).toLowerCase().includes(searchQuery.toLowerCase()) || item.ip.toLowerCase().includes(searchQuery.toLowerCase()));

    useEffect(() => {
        setLoading(true);
        if (date.fromDate && date.toDate) GetUserLoginHistory(1, 25);
    }, []);

    useEffect(() => {
        if (date.fromDate == "" && date.toDate == "") GetUserLoginHistory(1, 25);
    }, [date.fromDate == "" && date.toDate == ""]);

    const handleSerach = () => GetUserLoginHistory(1, 25);
    const handleReset = () => setDate({ fromDate: "", toDate: "" });

    const GetUserLoginHistory = (page, rowPerPage) => {
        var queryString = `q={PageNo:${page},PageSize:${rowPerPage},SortColumn:"login_date",SortDir:"desc",SearchValue:''}`;
        if (date.fromDate) queryString += `&fromDate=${date.fromDate}`;
        if (date.toDate) queryString += `&toDate=${date.toDate}`;
        const UserLoginHistoryData = new Promise((resolve) => {
            resolve(GetTokenApi(API_Path.getUserLoginHistory + queryString));
        });
        UserLoginHistoryData.then((res) => {
            if (res) {
                setUserLoginData(res.data[0]);
                setTotleRecode(res.data[1][0].total_count);
            } else {
                toast.error(res.statusText);
            }
            setLoading(false);
        });
    };

    const columns = [
        {
            name: "Login On",
            selector: (row) => moment(row.login_date).format("DD/MM/YYYY h:mm:ss A"),
            sortable: true,
            cell: (row) => <div>{moment(row.login_date).format("DD/MM/YYYY h:mm:ss A")}</div>,
        },
        { name: "IP Address", selector: (row) => row.ip },
        { name: "Location", selector: (row) => row.location },
        { name: "Platform", selector: (row) => row.platform },
        { name: "Browser", selector: (row) => row.browser },
    ];

    return (
        <React.Fragment>
            {loading && <Loading />}
            <div className={`${styles.personal_info_body}`}>
                <div className={`${styles.filter_flex} mb-3`}>
                    <div className={styles.title}>
                        <h6>Login History</h6>
                    </div>
                    <div className="filter-main">
                        <ul className={`${styles.adv_filter} mb-0 pb-0`}>
                            <li className={styles.date_filter_main}>
                                <div className={styles.date_filter}>
                                    <label>From</label>
                                    <div className={styles.input_box}>
                                        <input
                                            type="date"
                                            className={`${styles.form_input} rounded-0`}
                                            value={date.fromDate ?? ""}
                                            max={date.toDate ? date.toDate : maxDateString}
                                            onChange={(e) => setDate({ ...date, fromDate: e.target.value })}
                                        />
                                    </div>
                                </div>
                            </li>
                            <li className={styles.date_filter_main}>
                                <div className={styles.date_filter}>
                                    <label>To</label>
                                    <div className={styles.input_box}>
                                        <input
                                            type="date"
                                            className={`${styles.form_input} rounded-0`}
                                            value={date.toDate ?? ""}
                                            min={date.fromDate}
                                            max={maxDateString}
                                            onChange={(e) => setDate({ ...date, toDate: e.target.value })}
                                        />
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div className={`${styles.btn_flex} form-group`}>
                            <div className={`${styles.personal_info_btn} text-center d-flex py-0 justify-content-end`}>
                                <button type="button" className={`${styles.button} primary_button me-3`} onClick={handleSerach} disabled={date.fromDate == "" && date.toDate == ""}>
                                    Search
                                </button>
                                <button type="submit" className={`${styles.button} primary_button`} onClick={handleReset}>
                                    Reset
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={styles.icon_search_Section}>
                        <div className="col-lg-6 col-md-6 col-sm-6">
                            <div className={styles.section_flex}>
                                <ul>
                                    <li className={`${styles.info_section_icon} ms-0`}>
                                        <button
                                            type="button"
                                            className={styles.btn_dt}
                                            disabled={filteredLoginHistory == 0}
                                            onClick={() => exportTableToPDF(filteredLoginHistory, columns, "user_login_pdf", "User Login Details")}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <path
                                                    d="M7.053 10.1751C7.053 9.79512 6.789 9.56812 6.3235 9.56812C6.1335 9.56812 6.005 9.58662 5.9375 9.60462V10.8251C6.0175 10.8431 6.1155 10.8496 6.25 10.8496C6.7465 10.8496 7.053 10.5981 7.053 10.1751ZM9.936 9.58012C9.7275 9.58012 9.5925 9.59862 9.5125 9.61712V12.3211C9.5925 12.3396 9.721 12.3396 9.8375 12.3396C10.6835 12.3456 11.2355 11.8796 11.2355 10.8926C11.242 10.0341 10.739 9.58012 9.936 9.58012Z"
                                                    fill="#404040"
                                                />
                                                <path
                                                    d="M15.459 7.99162H15.12V6.35612C15.1197 6.34572 15.1187 6.33537 15.117 6.32511C15.1175 6.25934 15.0943 6.19558 15.0515 6.14562L12.33 3.03762L12.328 3.03562C12.2934 2.99811 12.2494 2.97064 12.2005 2.95612L12.1855 2.95112C12.1647 2.94594 12.1434 2.94325 12.122 2.94312H5.4335C5.128 2.94312 4.88 3.19162 4.88 3.49662V7.99162H4.541C4.104 7.99162 3.75 8.34562 3.75 8.78262V12.8966C3.75 13.3331 4.1045 13.6876 4.541 13.6876H4.88V16.5041C4.88 16.8091 5.128 17.0576 5.4335 17.0576H14.5665C14.8715 17.0576 15.12 16.8091 15.12 16.5041V13.6876H15.459C15.896 13.6876 16.25 13.3331 16.25 12.8966V8.78262C16.25 8.34562 15.8955 7.99162 15.459 7.99162ZM5.4335 3.49662H11.845V6.32861C11.845 6.48162 11.969 6.60512 12.122 6.60512H14.567V7.99162H5.4335V3.49662ZM12.2285 10.8616C12.2285 11.6526 11.9405 12.1986 11.541 12.5356C11.106 12.8971 10.444 13.0691 9.6345 13.0691C9.15 13.0691 8.807 13.0381 8.5735 13.0081V8.94862C8.917 8.89362 9.3645 8.86312 9.837 8.86312C10.6215 8.86312 11.1305 9.00412 11.529 9.30462C11.9585 9.62312 12.2285 10.1321 12.2285 10.8616ZM5.0105 13.0266V8.94862C5.2985 8.89962 5.7035 8.86312 6.2735 8.86312C6.85 8.86312 7.261 8.97362 7.5365 9.19412C7.8 9.40262 7.9775 9.74612 7.9775 10.1506C7.9775 10.5556 7.843 10.8986 7.5975 11.1316C7.2785 11.4321 6.8065 11.5671 6.2545 11.5671C6.132 11.5671 6.0215 11.5611 5.936 11.5486V13.0266H5.0105ZM14.5665 16.3541H5.4335V13.6876H14.5665V16.3541ZM15.3685 9.66012H13.78V10.6046H15.264V11.3651H13.78V13.0266H12.8425V8.89362H15.3685V9.66012Z"
                                                    fill="#404040"
                                                />
                                            </svg>
                                        </button>
                                    </li>
                                    <li className={styles.info_section_icon}>
                                        <button
                                            type="button"
                                            className={styles.btn_dt}
                                            disabled={filteredLoginHistory == 0}
                                            onClick={() => exportTableToExcel(filteredLoginHistory, "user_login_excel", columns)}
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                <path
                                                    d="M16.3106 10.5575H13.7091V9.25676H16.3106V10.5575ZM16.3106 11.3008H13.7091V12.6016H16.3106V11.3008ZM16.3106 5.16871H13.7091V6.46945H16.3106V5.16871ZM16.3106 7.21274H13.7091V8.51347H16.3106V7.21274ZM16.3106 13.3448H13.7091V14.6456H16.3106V13.3448V13.3448ZM18.1075 15.9092C18.0332 16.2957 17.5686 16.3049 17.2583 16.318H11.4793V17.9903H10.3253L1.82031 16.5038V3.4982L10.3755 2.00977H11.4793V3.49076H17.0595C17.3735 3.50376 17.7192 3.48146 17.9923 3.66913C18.1837 3.94414 18.1651 4.2935 18.1781 4.60938L18.1707 14.2832C18.1614 14.824 18.2209 15.3759 18.1075 15.9092ZM8.63434 12.9936C8.1215 11.9531 7.59935 10.9199 7.08833 9.87925C7.59376 8.86654 8.09178 7.85012 8.58792 6.83366C8.1661 6.85411 7.74427 6.88012 7.32434 6.90986C7.01028 7.67357 6.64421 8.41686 6.39894 9.2066C6.17038 8.46146 5.8675 7.74417 5.59063 7.01762C5.18181 7.03993 4.773 7.06408 4.36421 7.08823C4.7953 8.03967 5.25431 8.97801 5.67239 9.93501C5.17996 10.8641 4.71913 11.8063 4.24156 12.7409C4.64849 12.7576 5.05546 12.7744 5.46239 12.7799C5.75229 12.0404 6.11277 11.3287 6.36549 10.5742C6.5922 11.3844 6.97683 12.1333 7.29274 12.9081C7.74057 12.9398 8.18651 12.9676 8.63434 12.9936ZM17.2919 4.37328H11.4793V5.16871H12.9659V6.46945H11.4793V7.21274H12.9659V8.51347H11.4793V9.25676H12.9659V10.5575H11.4793V11.3008H12.9659V12.6016H11.4793V13.3448H12.9659V14.6456H11.4793V15.5049H17.2919V4.37328Z"
                                                    fill="#404040"
                                                />
                                            </svg>
                                        </button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-6 text-end">
                            <div className={styles.section_searchbar}>
                                <div className="form-group">
                                    <div className="searchbar_style">
                                        <input type="search" placeholder="Search" className="form-control" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="data_table_body">
                        {filteredLoginHistory.length > 0 ? (
                            <DataTableComponents fetchData={GetUserLoginHistory} columns={columns} userdata={filteredLoginHistory} TotalRows={totleRecode} loading={loading} />
                        ) : (
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Created On</th>
                                        <th>IP Address</th>
                                        <th>Location</th>
                                        <th>Activity</th>
                                        <th>Access Device</th>
                                    </tr>
                                </thead>
                                <NoDataFoundImg />
                            </Table>
                        )}
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}
