import React from "react";
import styles from "../styles/privacypolicy.module.css";
import { Link } from "react-router-dom";
import { baseName, supportUrl } from "../const";
import Layout from "../components/Layout/Layout";

export default function PrivacyPolicy() {
    return (
        <Layout>
            <section className={styles.section}>
                <div className="container">
                    <div className={styles.section_body}>
                        <div className="title_heading d-flex">
                            {/* <p>Explore /</p> */}
                            <h4 className="ps-0">Privacy Policy</h4>
                        </div>
                        <div className={styles.section_content}>
                            <p>
                                This document describes the way {baseName.toLowerCase()}, its partners and affiliates may gather, process and use users’ information provided on the{" "}
                                <Link to="/" target="_blank">
                                    {baseName}
                                </Link>
                               {" "}platform. The words “we”,“our” and “us” in the policy description represent {baseName.toLowerCase()}, its partners and its affiliates.
                            </p>
                            <p>
                                {baseName} asks for personal information that is solicited by law or needed for the specific objective. Any additional information provided voluntarily would not
                                signify the permission to use or access all our services, whether opted by you or not.
                            </p>
                            <p>
                                By registering with {baseName.toLowerCase()} and accessing our services, the user consents to understand and accept the terms specified in our “Privacy Policy”. The terms contained
                                herein can be revised from time to time to adhere to regulatory norms, to suit the way how we collect and use personal information.
                            </p>
                            <p>
                                All material amendments concerning the “Privacy Policy” shall be posted and declared on the website. Your continued usage of our platform and services means your
                                acceptance of our revised terms.
                            </p>
                            <div className={styles.pere_content_box}>
                                <h2>INFORMATION {baseName.toUpperCase()} COLLECTS</h2>
                                <p>We collect personally identifiable details during the user registration and subsequently when the user uses our {baseName.toLowerCase()} services.</p>
                                <h6>(A) Information We Collect During Registration</h6>
                                <p>
                                    When a user signs up and creates a {baseName.toLowerCase()} account, we may require a user to share personal information such as Name, Contact Details including Address, Mobile
                                    No. and e-mail Id. Additionally, the user can opt to use applications such as Google Authenticator or SMS OTP for 2-FA authentication to increase security of user
                                    account.
                                </p>
                                <h6>(B) Information Collected During User KYC Verification</h6>
                                <p>
                                    To adhere to the global regulatory standards which include Know-Your-Customer (KYC), Anti-Money Laundering (AML) and Combating the Financing of Terrorism (CTF)
                                    guidelines, we perform user KYC verification either by using inhouse technologies or by using reputed third party services. The user shall be required to submit
                                    relevant identification documents, proof of funds, bank details and a selfie with identification document at the time of KYC verification. KYC and AML third party
                                    services used by {baseName.toLowerCase()} are in compliance with various regulatory guidelines such as GDPR, CCPA and SOC 2 type II.
                                </p>
                                <h6>(C) Information We Collect When A User Uses Our Services</h6>
                                <b>Service Usage Information</b>
                                <p>
                                    We collect the service usage information such as device identification, access date and time, operating system, IP address and browser type through {baseName.toLowerCase()}
                                    platform and store it to ensure that our platform is seamlessly accessible to users with different devices and operating systems. We use this information to support
                                    the users and law-enforcement authorities during the times of fraud or criminal investigations.
                                </p>
                                <b>Transaction Information</b>
                                <p>
                                    We collect and process transaction information such as {baseName.toLowerCase()} account balances, withdrawals, deposits, trade history, transaction history and order activity for
                                    all user accounts to track suspicious transactions for the safety of our platform and its users from fraud. We are permitted to submit this information to concerned
                                    law-enforcement authorities during the fraud or criminal investigations.
                                </p>
                            </div>

                            <div className={styles.pere_content_box}>
                                <h2>WHY {baseName.toUpperCase()} COLLECTS THIS INFORMATION ?</h2>
                                <h6>(A) To Offer Our Services</h6>
                                <p>
                                    The information gathered is used for the verification of user identity and to offer {baseName.toLowerCase()} services. The IP address and unique identifiers saved in your
                                    device’s cookies are used by us to verify your identity and offer our services. We need users’ data like contact information, transaction-related information to
                                    comply with legal and compliance requirements.
                                </p>
                                <h6>(B) To Contact The User</h6>
                                <p>
                                    {baseName} uses personal information such as an e-mail address or Mobile No. to contact the user directly. We use the information to provide instant customer care
                                    support to our users, to keep them updated about the transaction and informed on account logins and security.
                                </p>
                                <p>
                                    We can answer your queries and requests and confirm each communication only after processing and verifying your personal information. All the communication with a
                                    user is stored confidentially and regularly audited, internally.
                                </p>
                                <h6>(C) For The Safety Of Our Users</h6>
                                <p>
                                    Information gathered by us is used for the safety of our users’ accounts and protection of our platform. {baseName} uses IP addresses and cookie data to shield the
                                    user's account from the computerized abuse and attacks like Spam, Distributed Denial of Service (DDoS) attacks and Phishing.
                                </p>
                                <p>
                                    We monitor the trading data and transactions information to identify suspicious acts at the initial level so that anticipated losses and potential frauds can be
                                    prevented.
                                </p>
                                <h6>(D) To Adhere To Statutory And Regulatory Needs</h6>
                                <p>
                                    We aim to adhere to global Know Your Customer (KYC), Anti-Money Laundering (AML) and Combating the Financing of Terrorism (CFT) regulations. We shall share the user
                                    personal or financial information with global law-enforcement agencies or their authorized officials during the fraud or criminal investigations. Except during such
                                    times, any information stored with {baseName.toLowerCase()} shall not be revealed to a third party without the prior permission from the user.
                                </p>
                                <h6>(E) To Review Platform Performance</h6>
                                <p>
                                    Our technology team regularly evaluates and examines the data collected to discern how the services of {baseName.toLowerCase()} are used so that we can enhance the performance of
                                    our platform and elevate user experience resulting in user satisfaction with our services.
                                </p>
                                <h6>(F) Miscellaneous</h6>
                                <p>
                                    It is indeed imperative for us and our users that we constantly check, investigate and curb the potential illegal or prohibited activities which violate our “Terms
                                    of Use”.
                                </p>
                                <p>
                                    We closely monitor user behavior at our platform and co-ordinate with our partners to ensure that every user in compliance with our “Terms of Use” at all times.
                                    Suspected activities are flagged by our risk monitoring systems. {baseName} reserves the right to restrict the provision of service for any user caught involved in
                                    activities that violate our “Terms of Use”.
                                </p>
                            </div>

                            <div className={styles.pere_content_box}>
                                <h2>DATA SECURITY AT {baseName.toUpperCase()} ?</h2>
                                <p>
                                    To protect every user’s personally identifiable information, we ensure that KYC verification and AML third party services used by {baseName.toLowerCase()} are in compliance with
                                    regulatory guidelines such as GDPR, CCPA and SOC 2 type II.
                                </p>
                                <p>
                                    All our servers are located in highly secure, world-class data centers spread across various geographies. The data centers have military grade security and the
                                    servers have 2-FA controlled access. We use measures like Secure Sockets Layer encryption technology and perform stringent control on access data and it contributes
                                    to highest data security at {baseName.toLowerCase()}.
                                </p>
                                <p>These and other security measures by {baseName.toLowerCase()} ensures that information saved with us is not prone to alteration, loss or false usage.</p>
                                <p>
                                    However, we expect our clients to take security precautions to safeguard their personal information and contact {baseName.toLowerCase()} customer service, when needed. Customers
                                    can “FREEZE” their accounts incase they suspect any security-issue with their account.
                                </p>
                            </div>

                            <div className={styles.pere_content_box}>
                                <h2>CONTACT US</h2>
                                <p>
                                    We always strive to uphold the users’ rights and their trust in our platform and associated services. For any query or clarification concerning our Privacy Policy,
                                    you can check our{" "}
                                    <Link to={`${supportUrl}/support`} target="_blank">
                                        Knowledge Base
                                    </Link>{" "}
                                    or raise a ticket by contacting{" "}
                                    <Link to={`${supportUrl}/`} target="_blank">
                                        {baseName} Support.
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}
