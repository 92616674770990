import axios from "axios";

export function GetApi(path) {
    const GetApiData = axios
        .get(path)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.error(error);
            return error.response;
        });

    return GetApiData;
}
export function GetTokenApi(path) {
    let token = "Bearer " + localStorage.getItem("FunexAuthorizationData");
    let headers = { Authorization: token };
    const GetApiData = axios
        .get(path, { headers: headers })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            localStorage.removeItem("FunexAuthorizationData");
            if (error.message === "Request failed with status code 401") {
                window.location.href = "/";
            }
            return error.response;
        });

    return GetApiData;
}

export function PostApi(path, body) {
    const PostApiData = axios
        .post(path, body)
        .then((response) => {
            return response;
        })
        .catch((error) => {
            console.log(error);
        });

    return PostApiData;
}

export function PostTokenApi(path, body) {
    let tokenData;
    if (localStorage.getItem("FunexAuthorizationData")) {
        tokenData = "Bearer " + localStorage.getItem("FunexAuthorizationData");
    } else {
        tokenData = "";
    }
    let headers = { Authorization: tokenData };
    const PostApiData = axios
        .post(path, body, { headers: headers })
        .then((response) => {
            return response;
        })
        .catch((error) => {
            if (error.message === "Request failed with status code 401") {
                localStorage.removeItem("FunexAuthorizationData");
                window.location.href = "/";
            }
            return error.response;
        });

    return PostApiData;
}
