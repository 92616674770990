import React from 'react';
import styles from '../../styles/register.module.css';
import { useNavigate } from 'react-router';
import SideImg from "../../assets/images/register.png";
import Logo from './Logo';

export default function RegistrationCompletedModal() {
    const navigate = useNavigate();

    const handleRedirect = (event) =>{
        event.preventDefault();
        navigate("/login");
    }
    return (
        <React.Fragment>
            <div className="container-fluid">
                <div className="row">
                    <div className={`${styles.col_width_left} ${styles.col_width_flex}`}>
                        <div className={styles.main_box}>
                            <div className={styles.lr_logo}>
                                <Logo />
                            </div>
                            <div className="mb-4">
                                <h3>Registration Completed Successfully</h3>
                            </div>

                            <div className={styles.verify_mail_body}>
                                <p className='text-center mb-4'>Please Login</p>
                                <div className={styles.loginBtn}>
                                    <button type='submit' className='button primary_button w-100 m-0' onClick={(e) => handleRedirect(e)}>Login</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.col_width_right} p-0 d-lg-block d-none`}>
                        <div className={styles.side_img}>
                        <img src={SideImg} alt="" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}